import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Notification,
  Modal,
  Checkbox,
  Grid,
  Card,
  Typography,
  Button,
  Tabs,
  Divider,
  Space,
  Skeleton,
  Avatar,
  Badge,
  List,
  Spin,
  Tag,
  Result,
  Empty,
  Alert,
  Radio,
  Input,
  Table,
  Tooltip,
} from "@arco-design/web-react";
import {
  IconCloseCircle,
  IconStarFill,
  IconMenu,
  IconClose,
  IconPlus,
  IconDelete,
  IconEdit,
  IconMinusCircleFill,
  IconRight,
  IconFaceFrownFill,
  IconLoading,
  IconClockCircle,
  IconCheckCircleFill,
  IconExclamationCircleFill,
  IconDown,
  IconUp,
  IconSearch,
  IconApps,
  IconSettings,
} from "@arco-design/web-react/icon";
import icon1 from "asserts/Intelligentwriting_icon_big.svg";
import icon2 from "asserts/Intelligentgraphic_icon_big.svg";
import icon3 from "asserts/Smartdocument_icon_big.svg";
import icon4 from "asserts/Intelligentcreation_icon_big.svg";
import icon5 from "asserts/knowledge_graph_big.svg";
import Emptydata_img from "asserts/Emptydata_img.svg";
import ai_ppt from "asserts/ai_ppt.png";
import ai_resume from "asserts/ai_resume.png";
import ai_marketing from "asserts/ai_marketing.png";
import ai_pact from "asserts/ai_pact.png";
import alertimg from "asserts/app_Empty.png";
import {
  getappList,
  deleteapp,
  getmodelList,
  putapp,
  getofficialappList,
  getcreateappList,
  newGetappList,
  newDeleteapp
} from "api/apis";
import Masonry from "masonry-layout"; // 瀑布流
import InfiniteScroll from "react-infinite-scroller"; // 下拉加载
import OffModel from "components/OffModel/index";
// import imagesLoaded from 'imagesloaded' // 图片加载
import history from "common/utils/history";
import { getCurrentDate, Imageinit } from "common/utils";
import JQ from "jquery";
import "./myapp.scss";
const Row = Grid.Row;
const Col = Grid.Col;

// 封装组件
class Myappindex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      all_count: 0,
      current: 1,
      Code: "",
      loadingbody: true,
      fail: false,
      loading: false,
      swiperloading: false,
      myappList: [],
      scrollLoading: <Spin loading={true} dot />,
      footpagination: {
        total: 0,
        pageSize: 10,
        current: 1,
      },
      swiperList: [],
      pagination: {
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 10,
        current: 1,
        pageSizeChangeResetCurrent: false,
        showJumper: false,
      },
      keyword: "",
      item: null,
      openFoot: false,
      Selectoptions: JSON.parse(localStorage.getItem("Selectoptions")),
      no_data: false,
      group_name: "全部",
      positionList: [
        "全部",
        // "智能写作",
        // "智能图文",
        // "智能文档",
        // "智能创作",
        // "知识图谱",
        "智能PPT(API版)",
        "智能PPT(SaaS版)",
        // "智能简历(SaaS版)",
        "智能简历",
        "智能营销",
        "智能合同"
      ],
      deployment: true,
      selectedRowKeys: [],
      selectkeys: [],
      type: "checkbox",
      checkbox: false,
      checkAll: false,
      createTypeList:[
        {
          type:"ai_ppt",
          createType:1,
          label:"智能PPT(API版)",
          title:'介绍介绍',
          desc:"轻松制作专业级PPT，智能布局与设计，助您一键生成高质量演示文稿。",
          model_icon:ai_ppt,
          backgroundColor:'#FFEADE'
        },
        {
          type:"saas_ppt",
          createType:1,
          label:"智能PPT(SaaS版)",
          title:'介绍介绍',
          desc:"轻松制作专业级PPT，智能布局与设计，助您一键生成高质量演示文稿。",
          model_icon:ai_ppt,
          backgroundColor:'#FFEADE'
        },
        {
          type:"saas_resume",
          name:"智能简历(SaaS版)",
          label:"智能简历(SaaS版)",
          model_desc:"智能匹配职位需求，一键生成个性化简历，让您在求职路上脱颖而出。",
          desc:"智能匹配职位需求，一键生成个性化简历，让您在求职路上脱颖而出。",
          model_icon:ai_resume,
          backgroundColor:'#D3EEFF'
        },
        {
          type:"ai_resume",
          name:"智能简历",
          label:"智能简历",
          model_desc:"智能匹配职位需求，一键生成个性化简历，让您在求职路上脱颖而出。",
          desc:"智能匹配职位需求，一键生成个性化简历，让您在求职路上脱颖而出。",
          model_icon:ai_resume,
          backgroundColor:'#D3EEFF'
        },
        {
          type:"ai_marketing",
          name:"智能营销",
          label:"智能营销",
          model_desc:"涵盖多种文档类型，智能编辑与排版，让文档处理更高效、更便捷。",
          desc:"涵盖多种文档类型，智能编辑与排版，让文档处理更高效、更便捷。",
          model_icon:ai_marketing,
          backgroundColor:'#FAEDFF'
        },
        {
          type:"ai_pact",
          name:"智能合同",
          label:"智能合同",
          model_desc:"涵盖多种文档类型，智能编辑与排版，让文档处理更高效、更便捷。",
          desc:"涵盖多种文档类型，智能编辑与排版，让文档处理更高效、更便捷。",
          model_icon:ai_pact,
          backgroundColor:'#ECE8FF'
        }
      ],
      columns: [
        {
          title: "应用名称",
          dataIndex: "name",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },

        {
          title: "服务类型",
          dataIndex: "type",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
        // {
        //   title: "服务选项",
        //   dataIndex: "model_capability_name",
        //   align: "center",
        //   headerCellStyle: {
        //     textAlign: "center",
        //   },
        //   filters: this.filtersTable(),
        //   onFilter: (value, row) =>
        //     row.model_capability_name.indexOf(value) > -1,
        //   filterMultiple: false,
        // },
        // {
        //   title: "模型名称",
        //   dataIndex: "model_name",
        //   width: 220,
        //   align: "center",
        //   headerCellStyle: {
        //     textAlign: "center",
        //   },
        //   render: (col, record, index) => (
        //     <>
        //       {col ? (
        //         <span className="modelv_name">
        //           {col}
        //           {record.version_num ? " V" + record.version_num : ""}
        //         </span>
        //       ) : null}
        //       {/* {record.model_type === 9 ? (
        //         <span className="model_tag">官方模型</span>
        //       ) : null} */}
        //     </>
        //   ),
        // },
        {
          title: "创建时间",
          dataIndex: "created_at",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          // render: (col, record, index) => (
          //   <span>{getCurrentDate("-", ":", col)}</span>
          // ),
        },
        {
          title: "创建者",
          dataIndex: "user_nickname",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
        },
      ],
      hasMore: true,
      hoverindex: "",
      isShow: false,
      paginationSwitch: false,
      indeterminate: false,
      SelectcheckAll: false,
      visible: false,
      confirmLoading: false,
      editTypography: "",
      editTypographydesc: "",
      isSuspension: false,
      // isSuspension: JSON.parse(localStorage.getItem("isSuspension")),
      visible2: false,
      listoff: JSON.parse(localStorage.getItem("Listoff")),
      // listoff: [
      //   {
      //     title: "智能写作",
      //     icon: icon1,
      //   },
      //   {
      //     title: "智能图文",
      //     icon: icon2,
      //   },
      //   {
      //     title: "智能文档",
      //     icon: icon3,
      //   },
      //   {
      //     title: "知识图谱",
      //     icon: icon5,
      //   },
      // ],
      group_nameTab: "",
      sceneKey: "",
      TablLoading: false,
      TabscrollLoading: false,
      activeTab: "0",
      officialList: [],
      officialpagination: {
        total: 0,
        pageSize: 9,
        current: 1,
      },
    };
    this.statusLineRef = React.createRef();
  }
  godebug() {
    history.push("/smarttext/modeltextgeneration/debugging");
    window.location.reload();
  }

  componentDidMount() {
    if (
      JSON.parse(localStorage.getItem("userinfo")) &&
      JSON.parse(localStorage.getItem("userinfo")).group_permission
    ) {
      let identity = JSON.parse(
        localStorage.getItem("userinfo")
      ).group_permission;
      if (identity && identity !== "PERSONAL") {
        let columns = this.state.columns;
        // let json = {
        //   title: "创建者",
        //   dataIndex: "nickname",
        //   align: "center",
        //   headerCellStyle: {
        //     textAlign: "center",
        //   },
        //   render: (col, record, index) => (
        //     <span
        //       style={{ color: record.is_deleted !== 0 ? "#8d93a1" : "#0c0c0f" }}
        //     >
        //       {col}
        //     </span>
        //   ),
        // };
        // this.setState({
        //   columns: columns.concat(json),
        // });
      }
    }

    this.getlist();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    let record = JSON.parse(localStorage.getItem("record"));
    if (record) {
      if (record.deployment === false) {
        setTimeout(() => {
          this.onApps();
        }, 100);
      }
    }
  }
  UNSAFE_componentWillMount() {
    localStorage.removeItem("ChatContent");
  }
  componentWillUnmount() {
    localStorage.removeItem("record");
    window.removeEventListener("resize", this.handleResize);
  }
  handleResize = () => {
    var winHeight = 0;
    if (window.innerHeight) {
      winHeight = window.innerHeight;
    } else if (document.body && document.body.clientHeight) {
      winHeight = document.body.clientHeight;
    }
    if (document.documentElement && document.documentElement.clientHeight) {
      winHeight = document.documentElement.clientHeight;
    }
    let layoutHeaders_h = document.getElementById("layoutHeaders").clientHeight;
    if (layoutHeaders_h > winHeight) {
      layoutHeaders_h = 64;
    }
    let height =
      winHeight -
      layoutHeaders_h -
      document.getElementById("renderHeaders").clientHeight;
    if (document.getElementById("result")) {
      if (document.getElementById("result_title")) {
        height = height - document.getElementById("result_title").clientHeight;
      }
      document.getElementById("result").style.height = height - 50 + "px";
    }
  };
  filtersTable() {
    let Selectoptions = JSON.parse(localStorage.getItem("Selectoptions"));
    let productsOptions = [];
    Object.keys(Selectoptions ? Selectoptions : []).map((item, i) => {
      let Option = {
        text: Selectoptions[item].label,
        value: Selectoptions[item].label,
      };
      if (Selectoptions[item].status !== 2) {
        productsOptions.push(Option);
      }
    });
    return productsOptions;
  }
  onCloseSusp(e) {
    e.stopPropagation();
    localStorage.setItem("isSuspension", false);
    this.setState({
      isSuspension: false,
    });
  }
  openOfficiaModel() {
    this.setVisible2(true);
  }
  setVisible2(value) {
    let pagination = this.state.officialpagination;
    pagination.current = 1;
    let currentTab = this.state.listoff[0];
    this.setState({
      visible2: value,
      group_name: value ? currentTab.title : "",
      sceneKey: value ? currentTab.link_key : "",
      // group_nameTab: value ? "智能写作" : "",
      activeTab: "0",
      officialList: [],
    });
    setTimeout(() => {
      this.getofficialappList();
    }, 100);
  }
  onClickTab(value) {
    let tablevalue = this.state.listoff[value];
    let pagination = this.state.officialpagination;
    pagination.current = 1;
    this.setState({
      activeTab: value,
      group_name: tablevalue ? tablevalue.title : "",
      officialList: [],
      officialpagination: pagination,
    });
    this.state.sceneKey = tablevalue ? tablevalue.link_key : "";
    this.state.group_name = tablevalue ? tablevalue.title : "";
    this.getofficialappList();
  }
  fetchData = (currentPage) => {
    let pagination = this.state.officialpagination;
    if (pagination.total === this.state.officialList.length) {
      this.setState({
        TabscrollLoading: "没有更多",
        TablLoading: false,
      });
    } else {
      let pagination = this.state.officialpagination;
      pagination.current = currentPage;
      this.getofficialappList();
      // fetch('https://randomuser.me/api/?results=10')
      //   .then((res) => res.json())
      //   .then((data) => {
      //     setMockData((mockData) => mockData.concat(...data.results));
      //   })
      //   .catch((error) => console.error(error));
    }
  };

  getofficialappList() {
    let page = {
      per_page: this.state.officialpagination.pageSize,
      page: this.state.officialpagination.current,
      scene: this.state.sceneKey,
    };
    // if (this.state.group_nameTab) {
    //   page.group_name = this.state.group_nameTab;
    // }
    this.setState({
      TablLoading: true,
    });
    getofficialappList(page).then((res) => {
      if (res.code === 200) {
        let no_data = false;
        let list = res.data;
        let pagination = this.state.officialpagination;
        pagination.total = list.count;
        let officialList = this.state.officialList.concat(list.data);
        this.setState({
          officialList: officialList,
          officialpagination: pagination,
          TablLoading: false,
        });
        if (officialList.length > 0 && !localStorage.getItem("isSuspension")) {
          localStorage.setItem("isSuspension", true);
        }
        if (officialList.length === 0) {
          localStorage.setItem("isSuspension", false);
        }
      } else {
        this.setState({
          TablLoading: false,
        });
        localStorage.setItem("isSuspension", false);
      }
    });
  }
  goOffdetails(record) {
    if (record.template_name === "COMMON") {
      history.push("/myapp/globalModle");
      localStorage.setItem("record", JSON.stringify(record));
      localStorage.setItem("props", JSON.stringify(this.state));
      window.location.reload();
      return;
    }
    if (record.model_capability === "Interaction") {
      history.push("/myapp/question");
    } else if (record.model_capability === "Continuation") {
      history.push("/myapp/productExperience");
    } else if (record.model_capability === "SemanticPrototype") {
      history.push("/myapp/semanticprototype");
    } else if (record.model_capability === "Ner") {
      history.push("/myapp/entityExtraction");
    } else if (record.model_capability === "SmartResume") {
      history.push("/myapp/resumeDocument");
    } else if (record.model_capability === "textPolish") {
      history.push("/myapp/embroidery");
    } else if (
      record.model_capability === "writing" ||
      record.model_capability === "textsummarization"
    ) {
      history.push("/myapp/writing");
    } else if (record.model_capability === "SemanticWordCloud") {
      history.push("/myapp/wordCloud");
    } else if (record.model_capability === "TextWithPictures") {
      history.push("/myapp/image2text");
    } else if (record.model_capability === "ResumeEditor") {
      history.push("/myapp/smarteditor");
    } else if (record.model_capability === "KnowledgeJd") {
      history.push("/myapp/knowledge");
    } else if (record.model_capability === "Classification") {
      history.push("/myapp/wordClass");
    } else if (record.model_capability === "Correction") {
      history.push("/myapp/wordErrorReset");
    } else if (record.model_capability === "SmartContract") {
      history.push("/myapp/smartContract");
    } else if (record.model_capability === "SentimentAnalysis") {
      history.push("/myapp/emotionClass");
    } else if (record.model_capability === "SensitiveWords") {
      history.push("/myapp/sensitiveWords");
    } else if (record.model_capability === "Similarity") {
      history.push("/myapp/similarity");
    } else if (record.model_capability === "KnowledgeReasoning") {
      history.push("/myapp/knowledgereasoning");
    }

    localStorage.setItem("record", JSON.stringify(record));
    localStorage.setItem("props", JSON.stringify(this.state));
    window.location.reload();
  }
  // imagesOnload = () => {
  //   // 初始化你要监听哪个节点下的图片
  //   const elLoad = imagesLoaded('.content')
  //   // always 页面图片全部加载完 不管有没有加载失败的图片
  //   elLoad.on('always', (instance, image) => {
  //     // 图片加载后执行的方法
  //     // 拿第一次的数据
  //     this.advanceWidth() // 初始化瀑布流
  //   })
  // }
  advanceWidth = () => {
    // new Masonry(节点, 配置)
    new Masonry(document.querySelector(".content"), {
      itemSelector: ".grid-item", // 要布局的网格元素
      columnWidth: ".grid-item", // 获取节点 可以自动计算每列的宽度
      fitWidth: true, // 设置网格容器宽度等于网格宽度
      gutter: 28,
      originLeft: true,
      // columnWidth: 200,
      // itemSelector: '.grid-item' // 要布局的网格元素
      // gutter: 10 // 网格间水平方向边距，垂直方向边距使用css的margin-bottom设置
      // percentPosition: true // 使用columnWidth对应元素的百分比尺寸
      // stamp:'.grid-stamp' // 网格中的固定元素，不会因重新布局改变位置，移动元素填充到固定元素下方
      // fitWidth: true // 设置网格容器宽度等于网格宽度，这样配合css的auto margin实现居中显示
      // originLeft: true // 默认true网格左对齐，设为false变为右对齐
      // originTop: true // 默认true网格对齐顶部，设为false对齐底部
      // containerStyle: { position: 'relative' } // 设置容器样式
      // transitionDuration: '0.8s' // 改变位置或变为显示后，重布局变换的持续时间，时间格式为css的时间格式
      // stagger: '0.03s' // 重布局时网格并不是一起变换的，排在后面的网格比前一个延迟开始，该项设置延迟时间
      // resize:  false // 改变窗口大小将不会影响布局
      // initLayout: true // 初始化布局，设未true可手动初试化布局
    });
  };
  // 加载更多数据
  loadMoreData = (current = 1) => {
    // page 当前滚动到了第几页
    const { myappList, pagination } = this.state;
    if (myappList.length === pagination.total && current !== 1) {
      return false;
    }
    // if (pagination.total && current > Math.ceil(pagination.total / pagination.pageSize)) return false
    let page = {
      per_page: 16,
      page: this.state.pagination.current,
      group_name: this.state.group_name === "全部" ? "" : this.state.group_name,
      keyword: this.state.keyword,
    };
    // getcreateappList  getappList
    getcreateappList(page).then((res) => {
      if (res.code === 200) {
        let no_data = false;
        let list = res.data;
        let data = list.data;
        let newsdata = [];
        let pagination = this.state.pagination;
        pagination.total = list.count;

        if (list.count === 0) {
          no_data = true;
        } else {
          no_data = false;
          pagination.current = pagination.current + 1;
        }
        if (current === 1) {
          newsdata = data;
        } else {
          newsdata = myappList.concat(data);
        }
        this.setState({
          no_data: no_data,
          myappList: newsdata,
          pagination: pagination,
          loading: false,
          scrollLoading: false,
          openFoot: no_data,
          all_count: list.count,
        });
        //console.log(list, "初始化瀑布流");
        this.advanceWidth(); // 初始化瀑布流
      } else {
        this.setState({
          loading: false,
          scrollLoading: false,
        });
      }
    });
  };
  getlist() {
    let page = {
      per_page: this.state.pagination.pageSize,
      page: this.state.pagination.current,
      type: this.state.group_name === "全部" ? "" : this.state.group_name,
      keywords: this.state.keyword,
    };
    // getcreateappList  getappList
    getcreateappList(page).then((res) => {
      if (res.code === 200) {
        let no_data = false;
        let list = res.data;
        let pagination = this.state.pagination;
        pagination.total = list.count;
        if (list.count === 0) {
          no_data = true;
        } else {
          no_data = false;
        }
        // list.data[0].delete = true;
        this.setState({
          no_data: no_data,
          myappList: list.data,
          pagination: pagination,
          loading: false,
          scrollLoading: false,
          openFoot: no_data,
          loadingbody: false,
          all_count: list.count,
        });
        //console.log(list, "list");
      } else {
        this.setState({
          loading: false,
          scrollLoading: false,
          loadingbody: false,
        });
      }
    });
  }
  onChangeTable(pagination) {
    const { current, pageSize } = pagination;
    this.setState({
      loading: false,
    });
    let setPagination = this.state.pagination;
    setPagination.current = current;
    setPagination.pageSize = pageSize;
    this.setState({
      pagination: setPagination,
    });
    this.getlist();
  }
  onswiperloading() {
    // this.setState({
    //     swiperloading: true
    // })
    // let pagination = this.state.myapppagination
    // pagination.pageSize = 1
    // pagination.current = pagination.current + 1
    setTimeout(() => {
      // this.getlist()
    }, 1000);
  }
  onAgain() {
    this.setState({
      fail: false,
      scrollLoading: <Spin loading={true} dot />,
    });
    this.getlist();
  }
  onTouchStart(item) {
    //console.log(item, "render");
    this.setState({
      item: item,
    });
  }
  onApps() {
    let pagination = this.state.pagination;
    pagination.pageSize = 10;
    pagination.current = 1;
    if (this.state.deployment) {
      pagination.pageSize = 12;
      this.setState({
        pagination: pagination,
      });
      setTimeout(() => {
        this.handleResize();
      }, 100);
      this.loadMoreData(1);
    }
    this.setState({
      deployment: !this.state.deployment,
      pagination: pagination,
    });
  }
  setSelectedRowKeys(selectedRowKeys) {
    let selectkeys = [];
    this.state.myappList.forEach((i, index) => {
      if (i.delete) {
        selectkeys.push(i.show_id);
      }
    });
    // console.log("onChange:",selectedRowKeys,selectkeys);
    this.setState({
      selectedRowKeys: selectedRowKeys,
      SelectcheckAll:
      selectedRowKeys.length === selectkeys.length ? true : false,
    });
  }
  onChangeAll(checked) {
    let selectkeys = [];
    if (checked) {
      this.state.myappList.forEach((i, index) => {
        if (i.delete) {
          selectkeys.push(i.show_id);
        }
      });
      this.setState({
        indeterminate: false,
        SelectcheckAll: true,
        selectedRowKeys: selectkeys,
      });
    } else {
      this.setState({
        indeterminate: false,
        SelectcheckAll: false,
        selectedRowKeys: selectkeys,
      });
    }
    // console.log(selectkeys, "selectedRowKeys");
  }
  setVisible(value) {
    this.setState({
      visible: value,
    });
  }
  onlidel(key, e) {
    e.stopPropagation();
    let selectedRowKeys = [key];
    this.state.selectedRowKeys = selectedRowKeys;
    //console.log(selectedRowKeys, this.state.selectedRowKeys);
    this.setState({
      selectedRowKeys: selectedRowKeys,
      selectkeys: selectedRowKeys,
    });
    this.setVisible(true);
  }
  deleteapp() {
    this.setState({
      confirmLoading: true,
    });
    let datas = {
      ids: 
        this.state.selectkeys.length > 0
          ? this.state.selectkeys
          : this.state.selectedRowKeys
     
    };
    newDeleteapp(datas).then((res) => {
      if (res.code === 200) {
        let setPagination = this.state.pagination;
        setPagination.current = 1;
        this.setState({
          selectkeys: [],
          selectedRowKeys: [],
          SelectcheckAll: false,
          confirmLoading: false,
          pagination: setPagination,
        });
        this.setVisible(false);
        this.getlist();
      } else {
        this.setState({
          confirmLoading: false,
        });
        Notification.error({ title: "Error", content: res.msg });
      }
    });
  }
  onSettings() {
    this.setState({
      checkbox: true,
    });
  }
  onClose() {
    this.setState({
      checkbox: false,
      SelectcheckAll: false,
      selectedRowKeys: [],
    });
  }
  goModelapp() {
    history.push("/myapp/addmyapp");
    localStorage.setItem("props", JSON.stringify(this.state));
    window.location.reload();
  }
  onClickBtn() {
    document.getElementById("addapp").click();
  }
  onDownUp() {
    this.setState({
      openFoot: !this.state.openFoot,
    });
  }
  onsetPosition(value) {
    //console.log(value, "value");
    this.setState({
      group_name: value,
      paginationSwitch: value === "全部" ? false : true,
    });
    this.state.group_name = value;
    let pagination = this.state.pagination;
    pagination.current = 1;
    this.setState({
      pagination: pagination,
    });
    if (this.state.deployment) {
      this.getlist();
    } else {
      this.loadMoreData(1);
    }
  }
  onPressEnter(value) {
    //console.log(value, "value");
    let pagination = this.state.pagination;
    pagination.current = 1;
    this.state.group_name = "全部";
    this.setState({
      pagination: pagination,
      keyword: value,
      paginationSwitch: true,
      group_name: "全部",
    });
    this.state.keyword = value;
    this.setState({
      loading: true,
    });
    setTimeout(() => {
      if (this.state.deployment) {
        this.getlist();
      } else {
        this.loadMoreData(1);
      }
    }, 500);
  }
  //检查智能简历应用中是否有选中体验页面的api功能
  checkResum(apilist){
    const key = ["AI简历生成","简历润色","简历续写","简历扩写","短词成文","简历精炼"];
    return apilist.every(item => !key.some(k => item.name.includes(k)));
  }
  checkResumExperience(apilist){
    const key = ["AI人岗匹配","AI岗位探测","AI简历分析"];
    const resumUrl = {
      'AI人岗匹配':'/myapp/positionDetection',
      'AI岗位探测':'/myapp/postMatching',
      'AI简历分析':'/myapp/resumeAnalyze',
    }
    let resumeType = ''
    apilist.forEach(item =>{
      if(key.includes(item.name)){
        resumeType = item.name
      }
    })
    return resumUrl[resumeType]
    // return apilist.every(item => key.some(k => item.name.includes(k)));
  }
  godetails(record, e) {
    e.stopPropagation();
    const { Selectoptions } = this.state;
    // return
    let model = {
      '智能PPT(API版)':'ai_ppt',
      '智能PPT(SaaS版)':'saas_ppt',
      '智能简历(SaaS版)':'saas_resume',
      'SAAS简历':'saas_resume',
      '智能简历':'ai_resume',
      '智能营销':'ai_marketing',
      '智能合同':'ai_pact',
    }
    const saasresume = {
      'SAAS简历探测':'detect',
      'SAAS人岗匹配':'match',
      'SAAS-简历分析':'analyse',
    }
    if(record.type === "智能简历(SaaS版)"){
      record.type = 'SAAS简历'
    }
    record['model_capability'] = model[record.type];
    if(record.type === "智能PPT(SaaS版)"){
      localStorage.setItem("props", JSON.stringify(this.state));
      localStorage.setItem("record", JSON.stringify(record));
      history.push("/myapp/aigcExperience");
      window.location.reload();
      return
    } else if(record.type === "SAAS简历" && record.api_list.length > 0){
      const type = saasresume[record.api_list[0].name]
      localStorage.setItem("props", JSON.stringify(this.state));
      localStorage.setItem("record", JSON.stringify(record));
      history.push("/myapp/saasResume?type="+type);
      window.location.reload();
      return
    } else if(record.type === "智能简历" && this.checkResumExperience(record.api_list)){
      localStorage.setItem("props", JSON.stringify(this.state));
      localStorage.setItem("record", JSON.stringify(record));
      history.push(this.checkResumExperience(record.api_list));
      window.location.reload();
      return
    }
    newGetappList({id:record.show_id}).then((res) => {
      if(res.code !== 200) return
      let identity = JSON.parse(localStorage.getItem("userinfo")).group_permission;
      if(res.data.api_list.length > 0){
        // if(identity === "PERSONAL"){
          switch (record.type){
            case '智能PPT(API版)':
              history.push("/myapp/interfacetest")
              break
            case '智能简历':
                  //检查智能简历应用中是否有选中体验页面的api功能
              if(identity === "PERSONAL"){
                history.push("/myapp/resumeDocument")
              } else {
                if(this.checkResum(res.data.api_list)){
                  history.push("/myapp/interfacetest")
                } else {
                  history.push("/myapp/resumeDocument")
                }
              }
              break
              case '智能营销':
                history.push("/myapp/enteringGinseng")
                break
            case '智能合同':
              history.push("/myapp/smartContract")
              break
            default:
              history.push("/")
          }
        // } else {
        //   history.push("/myapp/interfacetest")
        // }

      } else {
        history.push("/myapp/addmyapp")
        this.setState({
          current:2,
          model_capability:model[record.type]
        })
        let Selectoptions = {}
         this.state.createTypeList.map((item,index)=>{
          Selectoptions[item.type] = item
        })
        localStorage.setItem("Selectoptions", JSON.stringify(Selectoptions));
      }
      localStorage.setItem("props", JSON.stringify(this.state));
      localStorage.setItem("record", JSON.stringify(record));
      window.location.reload();
    })
    // return
    // if (
    //   Selectoptions[record.model_capability].type === 3 &&
    //   record.model_capability === "ResumeEditor"
    // ) {
    //   history.push("/myapp/smarteditor");
    // } else {
    //   if (record.train_status === "训练成功") {
    //     if (record.template_name === "COMMON") {
    //       history.push("/myapp/globalModle");
    //       localStorage.setItem("record", JSON.stringify(record));
    //       localStorage.setItem("props", JSON.stringify(this.state));
    //       window.location.reload();
    //       return;
    //     }
    //     if (record.model_capability === "Interaction") {
    //       history.push("/myapp/question");
    //     } else if (record.model_capability === "Continuation") {
    //       history.push("/myapp/productExperience");
    //     } else if (record.model_capability === "SemanticPrototype") {
    //       history.push("/myapp/semanticprototype");
    //     } else if (record.model_capability === "Ner") {
    //       history.push("/myapp/entityExtraction");
    //     } else if (record.model_capability === "SmartResume") {
    //       history.push("/myapp/resumeDocument");
    //     } else if (record.model_capability === "textPolish") {
    //       history.push("/myapp/embroidery");
    //     } else if (
    //       record.model_capability === "writing" ||
    //       record.model_capability === "textsummarization"
    //     ) {
    //       let moduleIdType = JSON.parse(localStorage.getItem("moduleIdType"));
    //       let arr = moduleIdType["writing"];
    //       if (arr.indexOf(record.model_id) !== -1) {
    //         history.push("/myapp/writingcurrency");
    //       } else {
    //         history.push("/myapp/writing");
    //       }
    //     } else if (record.model_capability === "SemanticWordCloud") {
    //       history.push("/myapp/wordCloud");
    //     } else if (record.model_capability === "TextWithPictures") {
    //       history.push("/myapp/image2text");
    //     } else if (record.model_capability === "KnowledgeJd") {
    //       history.push("/myapp/knowledge");
    //     } else if (record.model_capability === "Classification") {
    //       history.push("/myapp/wordClass");
    //     } else if (record.model_capability === "Correction") {
    //       history.push("/myapp/wordErrorReset");
    //     } else if (record.model_capability === "SmartContract") {
    //       history.push("/myapp/smartContract");
    //     } else if (record.model_capability === "SentimentAnalysis") {
    //       history.push("/myapp/emotionClass");
    //     } else if (record.model_capability === "SensitiveWords") {
    //       history.push("/myapp/sensitiveWords");
    //     } else if (record.model_capability === "Similarity") {
    //       history.push("/myapp/similarity");
    //     } else if (record.model_capability === "KnowledgeReasoning") {
    //       history.push("/myapp/knowledgereasoning");
    //     } else if (record.model_capability === "AIGC") {
    //       history.push("/myapp/aigcExperience");
    //     }
    //     else if (record.model_capability === "CopydoneArticle") {
    //       history.push("/myapp/copydoneArticle");
    //     }
    //   } else {
    //     history.push("/myapp/addmyapp");
    //     record.train_details = "详情";
    //   }
    // }
    // localStorage.setItem("record", JSON.stringify(record));
    // localStorage.setItem("props", JSON.stringify(this.state));
    // window.location.reload();
  }
  onTouchStart(evt) {}
  onTouchMove(evt) {}
  onMouseEnter(index) {
    this.setState({ hoverindex: index });
    //console.log(index);
  }
  onMouseLeave(index) {
    this.setState({
      editTypography: "",
      editTypographydesc: "",
      hoverindex: "",
    });
  }
  isputapp = (name, desc, id) => {
    this.putapp(name, desc, id);
  };
  putapp(name, desc, id) {
    let params = {
      id: id,
    };
    if (desc) {
      params.desc = desc;
    } else if (name) {
      params.name = name;
    }
    putapp(params).then((res) => {
      if (res.code === 200) {
        const tmpData = this.state.myappList;
        if (desc) {
          for (var i = 0; i < tmpData.length; i++) {
            if (id === tmpData[i].id) {
              tmpData[i].desc = desc;
              this.setState({
                myappList: tmpData,
              });
              return;
            }
          }
        } else if (name) {
          for (var i = 0; i < tmpData.length; i++) {
            if (id === tmpData[i].id) {
              tmpData[i].name = name;
              this.setState({
                myappList: tmpData,
              });
              return;
            }
          }
        }
      } else {
        Notification.error({ content: res.msg });
      }
    });
  }
  onChangetitle(type, id, value) {
    //console.log(type, id, value, "修改?");
    if (
      (value.length > 20 && type === "name") ||
      (value.length > 56 && type === "desc")
    ) {
      Notification.warning({ content: "修改内容超过32个字符！" });
      return;
    } else if (value === "") {
      Notification.warning({ content: "修改内容不能为空！" });
      return;
    }
    if (type === "desc") {
      this.isputapp(false, value, id);
    } else if (type === "name") {
      this.isputapp(value, false, id);
    }
  }
  onseTypographyn(item, type, e) {
    e.stopPropagation();
    this.setState({
      editTypography: type === 1 ? item.id : "",
      editTypographydesc: type === 2 ? item.id : "",
    });
    //console.log(item.id, "onseTypographyn");
    setTimeout(() => {
      if (this.state.editTypographydesc) {
        //TextArea光标位置不准确问题
        this.getFocusDom(e, item);
      } else {
        document.getElementById(item.id).focus();
      }
    }, 100);
  }
  getFocusDom = (e, record) => {
    var fDOM = document.getElementById(record.id);
    var len = record.desc.length;
    this.setSelectionRange(fDOM, len, len);
  };
  setSelectionRange = (input, selectionStart, selectionEnd) => {
    if (input.setSelectionRange) {
      input.focus();
      input.setSelectionRange(selectionStart, selectionEnd);
    } else if (input.createTextRange) {
      var range = input.createTextRange();
      range.collapse(true);
      range.moveEnd("character", selectionEnd);
      range.moveStart("character", selectionStart);
      range.select();
    }
  };

  onblur(id, e) {
    //console.log(e.target.value.length, "onblur");
    let value = e.target.value;
    this.onChangetitle(this.state.editTypography ? "name" : "desc", id, value);
    this.setState({
      editTypography: "",
      editTypographydesc: "",
    });
  }
  EnterTypography(id, e) {
    let value = e.target.value;
    this.onChangetitle(this.state.editTypography ? "name" : "desc", id, value);
    this.setState({
      editTypography: "",
      editTypographydesc: "",
    });
  }
  renderContainer = () => {
    const {
      editTypographydesc,
      editTypography,
      Selectoptions,
      myappList,
      hoverindex,
      ellipsis,
      checkbox,
    } = this.state;
    return myappList.map((item, index) => {
      let background =
        item.group_name === "智能写作"
          ? "#00D097"
          : item.group_name === "智能创作"
          ? "#935AFE"
          : item.group_name === "智能文档"
          ? "#2A7DFF"
          : item.group_name === "知识图谱"
          ? "#15C5D3"
          : item.group_name === "智能图文"
          ? "#ff9654"
          : "#2A7DFF";
      return (
        <div
          className="ApplicationList-item grid-item"
          key={index}
          onClick={
            checkbox || editTypography || editTypographydesc
              ? null
              : this.godetails.bind(this, item)
          }
          onMouseLeave={checkbox ? null : this.onMouseLeave.bind(this, index)}
          onMouseEnter={checkbox ? null : this.onMouseEnter.bind(this, index)}
        >
          <Checkbox
            key={index}
            value={item.id}
            className={`${checkbox ? `ischeckbox` : ``}`}
            disabled={item.delete && checkbox}
          >
            <div className="grid-header">
              <div className="grid-top">
                <div className="swiper-fix">
                  <Space>
                    {/* <img
                      src={Selectoptions[item.model_capability].label_icon}
                    /> */}
                    <span className="group-label">
                      {item.type}
                      {/* {Selectoptions[item.model_capability].label} */}
                    </span>
                  </Space>
                  <span>
                    {hoverindex === index ? (
                      <IconDelete
                        className={`delcircle ${
                          item.delete ? "" : "disabled"
                        }`}
                        onClick={this.onlidel.bind(this, item.show_id)}
                      />
                    ) : (
                      <div className="state-icon">
                        {item.train_status === "训练失败" ||
                        item.train_status === "待训练" ||
                        item.train_status === "" ? (
                          <Tag
                            color="arcoblue"
                            icon={
                              <span
                                className="icon-icon_peizhizhong"
                                style={{ borderRadius: 7, color: "#165DFF" }}
                              />
                            }
                          >
                            应用配置
                          </Tag>
                        ) : item.train_status === "训练成功" ? (
                          <Tag
                            color="green"
                            icon={
                              <IconCheckCircleFill
                                style={{ borderRadius: 7, color: "#00B42A" }}
                              />
                            }
                          >
                            应用开启
                          </Tag>
                        ) : item.train_status === "训练中" ? (
                          <Tag
                            color="arcoblue"
                            icon={<IconLoading style={{ color: "#165dff" }} />}
                          >
                            模型训练
                          </Tag>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </span>
                </div>
                <Space className="grid-top-title">
                  <div className={`${item.avatar ? "rowSpaceBetween" : ""}`}>
                    {editTypography === item.id ? (
                      <Input
                        className="editable-title"
                        id={item.id}
                        defaultValue={item.name}
                        maxLength={10}
                        onPressEnter={this.EnterTypography.bind(this, item.id)}
                        onBlur={this.onblur.bind(this, item.id)}
                      />
                    ) : (
                      <>
                        <Typography.Title
                          heading={5}
                          className="swiper-Title"
                          ellipsis={{
                            rows: 1,
                            showTooltip: true,
                            expandable: true,
                          }}
                        >
                          <span className="hover-title">
                            <span className="label-name AlibabaPuHuiTi-Medium">
                              {item.name}
                            </span>
                            {checkbox ? (
                              ""
                            ) : (
                              <Tooltip
                                color={"#20242f"}
                                content="编辑"
                                getPopupContainer={(triggerNode) =>
                                  triggerNode.parentNode
                                }
                              >
                                <IconEdit
                                  onClick={
                                    checkbox
                                      ? null
                                      : this.onseTypographyn.bind(this, item, 1)
                                  }
                                />
                              </Tooltip>
                            )}
                          </span>
                        </Typography.Title>
                        {item.avatar ? (
                          <div
                            className="applistAvatar"
                            id={item.id + "avatardom"}
                            style={{ backgroundColor: "transparent" }}
                          >
                            <img
                              alt="avatar"
                              src={item.avatar}
                              className="founder"
                              id={item.id + "avatar"}
                            />
                            {this.Imageinit(
                              item.avatar,
                              item.id + "avatar",
                              item.id + "avatardom"
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                </Space>
              </div>
            </div>
            <div className="grid-bottom">
              {hoverindex === index ? (
                <div
                  style={{ background: background }}
                  className="editable-box"
                >
                  {editTypographydesc === item.id ? (
                    <Input.TextArea
                      className="editable-Paragraph-Input"
                      id={item.id}
                      defaultValue={item.desc}
                      maxLength={32}
                      onPressEnter={this.EnterTypography.bind(this, item.id)}
                      onBlur={this.onblur.bind(this, item.id)}
                    />
                  ) : (
                    <Typography.Text
                      className="editable-Paragraph ellipasedom"
                      ellipsis={{
                        rows: 2,
                        showTooltip: true,
                        expandable: true,
                      }}
                    >
                      <span className="hover-desc">
                        {item.desc}
                        <Tooltip
                          color={"#20242f"}
                          content="编辑"
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                        >
                          <IconEdit
                            onClick={
                              checkbox
                                ? null
                                : this.onseTypographyn.bind(this, item, 2)
                            }
                          />
                        </Tooltip>
                      </span>
                    </Typography.Text>
                  )}
                </div>
              ) : (
                <div className="nohover-bottom rowSpaceBetween">
                  {/* <Space>
                    <span className="dispose_icon"></span>
                    <span>模型：{item.model_name}</span>
                  </Space> */}
                  <div className="Clock-time">
                    <IconClockCircle
                      className="IconClockCircle"
                      style={{ verticalAlign: "-2" }}
                    />
                    {item.created_at}
                  </div>
                </div>
              )}
            </div>
          </Checkbox>
        </div>
      );
    });
  };
  Imageinit(avatar, avatarid, domid) {
    if (avatar) {
      Imageinit(avatar, JQ("#" + avatarid), JQ("#" + domid), 2);
    }
  }
  // 选中button，多选

  handleSelect = (itemChild) => {
    const { myappList } = this.state;
    // 如果点击的在数组中已经存在，则从数组删除，否则，添加到数组
    // if (myappList.indexOf(itemChild) > -1) {
    //   myappList.splice(myappList.indexOf(itemChild), 1);
    // } else {
    //   myappList.push(itemChild);
    // }
    // const newBtnClassNameArry = myappList;
    // // 改变state状态值
    // this.setState({
    //   selectedRowKeys: newBtnClassNameArry,
    // });
    itemChild.checked = !itemChild.checked;
    if (itemChild.checked) {
      this.state.myappList.forEach((i, index) => (i.delete = true));
    } else {
    }
    // //除了第一个其他都勾选的话，第一个就全选
    // let res = this.state.checklist.every(i => {
    //   if (i.name !== '全选' && !i.checked) {
    //     return false;
    //   }
    //   return true;
    // })

    // if (res) {
    //   this.changeFirstItem(true);
    // } else {
    //   this.changeFirstItem(false);
    // }

    //每点击一次state更新一次
    this.setState({
      selectedRowKeys: this.state.selectedRowKeys,
    });
  };

  render() {
    const {
      all_count,
      visible2,
      isSuspension,
      confirmLoading,
      visible,
      loadingbody,
      paginationSwitch,
      isShow,
      indeterminate,
      SelectcheckAll,
      checkbox,
      checkAll,
      type,
      selectedRowKeys,
      columns,
      positionList,
      group_name,
      deployment,
      no_data,
      footpagination,
      swiperList,
      pagination,
      loading,
      myappList,
      scrollLoading,
      fail,
      Selectoptions,
      swiperloading,
      openFoot,
    } = this.state;
    return (
      <div className="myapplist">
        <div className="Header-init" id="renderHeaders">
          <div className="Header-title">我的应用</div>
        </div>
        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <>
            {all_count === 0 && !paginationSwitch ? (
              <Alert
                showIcon={false}
                closable
                type="info"
                content={<img src={alertimg} className="alertimg" />}
                onClick={this.openOfficiaModel.bind(this)}
                className="custom-alert"
                style={{ marginTop: 22 }}
              />
            ) : null}

            <div
              className="myapp-demo"
              style={{ marginBottom: 16 }}
              gutter={10}
            >
              {all_count > 0 || paginationSwitch ? (
                <div className="GroupTable-fixe" id="result_title">
                  {checkbox ? (
                    <>
                      <div className="custom-checkAll">
                        <Checkbox
                          onChange={this.onChangeAll.bind(this)}
                          checked={SelectcheckAll}
                          indeterminate={indeterminate}
                        >
                          {SelectcheckAll ? "取消" : "全选"}
                        </Checkbox>
                      </div>
                      <Space size={"medium"}>
                        <Button
                          shape="circle"
                          className="custom-White-button"
                          disabled={selectedRowKeys.length === 0}
                          icon={<IconDelete />}
                          onClick={() => this.setVisible(true)}
                        />
                        <Button
                          shape="circle"
                          className="custom-White-button"
                          icon={<IconClose />}
                          onClick={this.onClose.bind(this)}
                        />
                      </Space>
                    </>
                  ) : (
                    <>
                      <Radio.Group
                        type="button"
                        name="direction"
                        value={group_name}
                        onChange={this.onsetPosition.bind(this)}
                        options={positionList}
                        className="GroupTable"
                      ></Radio.Group>
                      <div>
                        <Space size={"medium"}>
                          <Input.Search
                            style={{ width: 250 }}
                            // prefix={<IconSearch />}
                            placeholder="快速搜索你的应用"
                            className="GroupTable-Search"
                            // onPressEnter={this.onPressEnter.bind(this)}
                            onSearch={this.onPressEnter.bind(this)}
                          />

                          <Button
                            shape="circle"
                            className="custom-White-button"
                            icon={deployment ? <IconApps /> : <IconMenu />}
                            onClick={this.onApps.bind(this)}
                          />
                          <Button
                            shape="circle"
                            className="custom-White-button"
                            icon={<IconSettings />}
                            disabled={pagination.total === 0}
                            onClick={this.onSettings.bind(this)}
                          />
                        </Space>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                ""
              )}
              {fail ? (
                <Result
                  style={{ marginTop: 119 }}
                  status="error"
                  icon={<IconFaceFrownFill />}
                  title="加载失败"
                  subTitle="出了点问题。请再试一次。"
                  extra={
                    <Button type="primary" onClick={this.onAgain.bind(this)}>
                      再一次
                    </Button>
                  }
                ></Result>
              ) : all_count === 0 && !loading ? (
                <Empty
                  style={{ marginTop: 93 }}
                  imgSrc={Emptydata_img}
                  description={
                    <div className="Emptydata">
                      <Typography>暂时没有应用</Typography>
                      <Typography>
                        请点击按钮选择你想要创建的应用类型
                      </Typography>
                      <Button
                        type="primary"
                        id="addapp"
                        onClick={this.onClickBtn.bind(this)}
                        style={{
                          width: 180,
                          borderRadius: 14,
                          height: 48,
                          margin: "34px 0px",
                        }}
                      >
                        <IconPlus />
                        创建应用
                      </Button>
                    </div>
                  }
                />
              ) : (
                <div>
                  {loading ? (
                    <Spin dot className="table-spin-loading" />
                  ) : (
                    <>
                      {deployment ? (
                        <>
                          {pagination.total === 0 && this.state.keyword ? (
                            <Empty
                              imgSrc={Emptydata_img}
                              description={"暂无相关应用"}
                              style={{ marginTop: 200 }}
                            />
                          ) : (
                            <Card
                              style={{ width: "100%", marginTop: 20 }}
                              bordered={false}
                              className="tableCard"
                            >
                              <Table
                                style={{ marginTop: 10 }}
                                virtualized
                                rowKey="show_id"
                                no_data={no_data}
                                border={{ wrapper: false, cell: false }}
                                noDataElement={
                                  <Empty
                                    imgSrc={Emptydata_img}
                                    description={"暂无相关应用"}
                                  />
                                }
                                loading={loading}
                                columns={columns.concat({
                                  title: "操作",
                                  dataIndex: "operation",
                                  render: (col, record, index) => (
                                    <Button
                                      type="outline"
                                      className="outlineTable"
                                      onClick={this.godetails.bind(
                                        this,
                                        record
                                      )}
                                    >
                                      {record.api_list.length > 0 || record.type === "智能PPT(SaaS版)"
                                        ? "立即使用"
                                        : "继续创建"}
                                    </Button>
                                  ),
                                  fixed: "right",
                                  width: 150,
                                  align: "center",
                                })}
                                data={myappList}
                                pagination={
                                  pagination.total === 0 ? false : pagination
                                }
                                onChange={this.onChangeTable.bind(this)}
                                renderPagination={(paginationNode) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      marginTop: 10,
                                    }}
                                  >
                                    {paginationNode}
                                  </div>
                                )}
                                rowSelection={
                                  checkbox && {
                                    type,
                                    selectedRowKeys,
                                    checkAll: checkAll,
                                    onChange: (
                                      selectedRowKeys,
                                      selectedRows
                                    ) => {
                                      // console.log(selectedRowKeys);
                                      this.setSelectedRowKeys(selectedRowKeys);
                                    },
                                    onSelect: (
                                      selected,
                                      record,
                                      selectedRows
                                    ) => {
                                      // this.setSelectedRowKeys(record.show_id);
                                    },
                                    onSelectAll: (
                                      selected,
                                      record,
                                      selectedRows
                                    ) => {},
                                    checkboxProps: (record) => {
                                      return {
                                        // disabled: !record.enable_del,
                                        disabled: !record.delete,
                                      };
                                    },
                                  }
                                }
                              />
                            </Card>
                          )}
                        </>
                      ) : (
                        <>
                          {pagination.total === 0 ? (
                            <Empty
                              imgSrc={Emptydata_img}
                              description={"暂无相关应用"}
                              style={{ marginTop: 200 }}
                            />
                          ) : (
                            <>
                              <div className="ApplicationList-Swiper">
                                <div
                                  className="ApplicationList-wrap"
                                  id="result"
                                >
                                  <InfiniteScroll
                                    initialLoad={false} // 不让它进入直接加载
                                    pageStart={1} // 设置初始化请求的页数
                                    loadMore={this.loadMoreData} // 监听的ajax请求
                                    hasMore={this.state.hasMore} // 是否继续监听滚动事件 true 监听 | false 不再监听
                                    useWindow={false} // 不监听 window 滚动条 如果你要监听 window 外层不能有任何节点
                                  >
                                    {/* 瀑布流 */}
                                    {pagination.total < 4 ? (
                                      <div className="content-left">
                                        <Checkbox.Group
                                          onChange={this.setSelectedRowKeys.bind(
                                            this
                                          )}
                                          value={selectedRowKeys}
                                        >
                                          {this.renderContainer()}
                                        </Checkbox.Group>
                                      </div>
                                    ) : (
                                      <div className="content">
                                        <Checkbox.Group
                                          onChange={this.setSelectedRowKeys.bind(
                                            this
                                          )}
                                          value={selectedRowKeys}
                                        >
                                          {this.renderContainer()}
                                        </Checkbox.Group>
                                      </div>
                                    )}
                                  </InfiniteScroll>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </>
        )}
        {/* {isSuspension ? (
          <div
            className="suspension"
            onClick={this.openOfficiaModel.bind(this)}
          >
            体<br />验<br />官<br />方<br />应<br />用<br />
            <IconStarFill
              style={{ marginTop: 2, color: "#FFC231", fontSize: 14 }}
            />
            <IconCloseCircle
              className="IconCloseCircle"
              onClick={this.onCloseSusp.bind(this)}
            />
          </div>
        ) : (
          ""
        )} */}
        <OffModel
          Selectoptions={Selectoptions}
          visible={visible2}
          TablLoading={this.state.TablLoading}
          TabscrollLoading={this.state.TabscrollLoading}
          activeTab={this.state.activeTab}
          listoff={this.state.listoff}
          officialList={this.state.officialList}
          setVisible={() => {
            this.setVisible2(false);
          }}
          onClickTab={this.onClickTab.bind(this)}
          fetchData={(currentPage) => this.fetchData(currentPage)}
          goOffdetails={(item) => this.goOffdetails(item)}
        ></OffModel>
        <Modal
          style={{
            width: 472,
          }}
          className="deleteModal"
          title="设置提示"
          visible={visible}
          okText="确认"
          cancelText="取消"
          confirmLoading={confirmLoading}
          onOk={this.deleteapp.bind(this)}
          onCancel={() => this.setVisible(false)}
        >
          <p>是否确认删除选中内容？</p>
        </Modal>
      </div>
    );
  }
}
export default Myappindex;
