import React from 'react'
import ReactDOM from 'react-dom'
// import registerServiceWorker from './registerServiceWorker';//提高代码实行速度
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './index'
import history from './common/utils/history'
// import { setEnabled } from 'react-error-overlay'
const debounce = (fn, delay = 200) => {
  let timer = null
  return (...args) => {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => fn(...args), delay)
  }
}

// 重写全局 ResizeObserver 构造函数
const OriginalResizeObserver = window.ResizeObserver
window.ResizeObserver = class extends OriginalResizeObserver {
  constructor(callback) {
    super(debounce(callback)) // 所有实例默认防抖
  }
}
ReactDOM.render(
  <BrowserRouter history={history}>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
)
// registerServiceWorker();
// setEnabled(process.env.NODE_ENV === 'development')
// setEnabled(false)
