import React, { Component, useEffect } from 'react'
import {
  Table,
  InputTag,
  Empty,
  Card,
  Spin,
  Button,
  DatePicker,
  Select,
  Dropdown,
  Menu,
  Typography,
  Modal,
  Radio,
  Notification
} from '@arco-design/web-react'
import { getCurrentDate } from 'common/utils'
import Emptydata_img from 'asserts/Emptydata_img.svg'
import { getbalance, getApiDetail, getAllClass, getAllapiList } from 'api/apis'
import {
  IconDownload,
  IconCalendar,
  IconDown,
  IconSync
} from '@arco-design/web-react/icon'
import './index.scss'
import Search from '@arco-design/web-react/es/Input/search'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

const RangePicker = DatePicker
const RadioGroup = Radio.Group

// 创建一个高阶组件来传递 location 属性
function withRouter(Component) {
  return function WrappedComponent(props) {
    const location = useLocation()
    return <Component {...props} location={location} />
  }
}

// 封装组件
class Paymentdetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loadingbody: true,
      data: [],

      pagination: {
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 10,
        current: 1,
        pageSizeChangeResetCurrent: true,
        showJumper: false
      },
      loading: false,
      no_data: false,
      keyword: '',
      checkbox: false,
      selectedRowKeys: [],
      rangeValue: null,
      rangeValue2: ['', ''],
      ishowdata: false,
      type: '',
      checkAll: false,
      indeterminate: false,
      SelectcheckAll: false,
      downVisible: false,
      timeRadio: 'a',
      deriveValue: ['', ''],
      postrangeValue: [],
      trading: '全部',
      apply: '全部',
      class: '全部',
      showName: '', //接口的展示名称
      allApiList: [], //所有接口列表
      defaultDayValue: [], //默认时间（依据今日消费、本月消费）
      menuItems: [
        { key: '1', label: '全部', value: '全部' },
        { key: '2', label: '智能PPT', value: '智能PPT' },
        { key: '3', label: '智能PPT(SaaS版)', value: '智能PPT(SaaS版)' },
        { key: '4', label: '智能简历', value: '智能简历' },
        { key: '5', label: '智能简历(SaaS版)', value: '智能简历(SaaS版)' },
        { key: '6', label: '智能营销', value: '智能营销' },
        { key: '7', label: '智能合同', value: '智能合同' }
      ],
      menuType: [
        { key: '1', label: '充值', value: 'TOP_UP' },
        { key: '2', label: '消费', value: 'REAL-TIME' },
        { key: '3', label: '提现', value: 'REFUND' }
      ],
      columns: [
        {
          title: '时间',
          dataIndex: 'created_at',
          align: 'center',
          headerCellStyle: {
            textAlign: 'center',
            height: 68
          }
        },
        {
          title: '类型',
          dataIndex: 'type',
          align: 'center',
          headerCellStyle: {
            textAlign: 'center'
          },
          render: (col, record) => (
            <span>
              {col === 'REAL-TIME'
                ? '消费'
                : col === 'REFUND'
                ? '提现'
                : col === 'TOP_UP'
                ? '充值'
                : ''}
            </span>
          )
        },
        {
          title: '接口',
          dataIndex: 'api_name',
          align: 'center',
          headerCellStyle: {
            textAlign: 'center'
          }
          // render: (col, record) => this.mapApiList(col)
        },
        {
          title: '金额',
          dataIndex: 'price',
          align: 'center',
          headerCellStyle: {
            textAlign: 'center'
          },
          render: (text, record) => {
            // 根据类型字段判断
            if (record.type === 'REAL-TIME') {
              return <span style={{ color: 'red' }}>-{text}</span>
            } else if (record.type === 'TOP_UP') {
              return <span style={{ color: 'green' }}>+{text}</span>
            }
            // 提现保持原样
            return <span>{text}</span>
          }
        },
        // {
        //   title: '可用余额',
        //   dataIndex: 'amount',
        //   align: 'center',
        //   headerCellStyle: {
        //     textAlign: 'center'
        //   }
        // },
        // {
        //   title: '状态（已到账、成功）',
        //   dataIndex: 'status',
        //   align: 'center',
        //   headerCellStyle: {
        //     textAlign: 'center'
        //   }
        // },
        {
          title: '编号',
          dataIndex: 'rid',
          align: 'center',
          headerCellStyle: {
            textAlign: 'center'
          }
        }
      ],
      param: 0
    }
    // this.getAllapiList()
  }

  componentDidMount() {
    this.getAllClass()
    // 确保 location 已被正确传递
    if (this.props.location) {
      const queryParams = new URLSearchParams(this.props.location.search)
      const myParam = queryParams.get('id')
      // console.log('aram', myParam) // 输出查询参数的值
      this.handleMyParam(myParam)
    } else {
      console.error('Location is undefined')
    }
    // this.getbalance()
    // this.getAllapiList()
    document.addEventListener('click', this.hideAllMenu)
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.hideAllMenu)
  }
  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.allApiList !== this.state.allApiList) {
  //     this.initColumns()
  //   }
  // }
  // initColumns = () => {
  //   const columns = [
  //     {
  //       title: '时间',
  //       dataIndex: 'created_at',
  //       align: 'center',
  //       headerCellStyle: {
  //         textAlign: 'center',
  //         height: 68
  //       }
  //     },
  //     {
  //       title: '类型',
  //       dataIndex: 'type',
  //       align: 'center',
  //       headerCellStyle: {
  //         textAlign: 'center'
  //       },
  //       render: (col, record) => (
  //         <span>
  //           {col === 'REAL-TIME'
  //             ? '消费'
  //             : col === 'REFUND'
  //             ? '提现'
  //             : col === 'TOP_UP'
  //             ? '充值'
  //             : ''}
  //         </span>
  //       )
  //     },
  //     {
  //       title: '接口',
  //       dataIndex: 'api_name',
  //       align: 'center',
  //       headerCellStyle: {
  //         textAlign: 'center'
  //       }
  //       // render: (col, record) => this.mapApiList(col)
  //     },
  //     {
  //       title: '金额',
  //       dataIndex: 'price',
  //       align: 'center',
  //       headerCellStyle: {
  //         textAlign: 'center'
  //       }
  //     },
  //     // {
  //     //   title: '可用余额',
  //     //   dataIndex: 'amount',
  //     //   align: 'center',
  //     //   headerCellStyle: {
  //     //     textAlign: 'center'
  //     //   }
  //     // },
  //     {
  //       title: '状态（已到账、成功）',
  //       dataIndex: 'status',
  //       align: 'center',
  //       headerCellStyle: {
  //         textAlign: 'center'
  //       }
  //     },
  //     {
  //       title: '编号',
  //       dataIndex: 'rid',
  //       align: 'center',
  //       headerCellStyle: {
  //         textAlign: 'center'
  //       }
  //     }
  //   ]
  //   this.setState({ columns })
  // }

  // getAllapiList(value) {
  //   getAllapiList().then((res) => {
  //     if (res.code === 200) {
  //       this.setState({
  //         allApiList: res.data
  //       })
  //     }
  //   })
  // }
  // //将数据从二维变成一维数组并查找有没有对应的服务
  // mapApiList(value) {
  //   const { allApiList } = this.state

  //   // 使用双问号语法处理可能的空值情况
  //   const categories = Object.values(allApiList ?? {})
  //   // console.log('categories:', categories)

  //   // 使用数组的 flatMap + find 实现高效查找
  //   const targetItem = categories
  //     .flatMap((arr) => arr)
  //     .find((item) => item.flag === value)
  //   // 使用可选链操作符避免未找到时的报错
  //   return targetItem?.name || '未知接口'
  // }

  getbalance() {
    let page = {
      limit: this.state.pagination.pageSize,
      page: this.state.pagination.current,
      start_date: this.state.postrangeValue[0],
      end_date: this.state.postrangeValue[1],
      type: this.state.type,
      class: this.state.class === '全部' ? '' : this.state.class,
      model_capability: this.state.apply === '全部' ? '' : this.state.apply
    }
    if (this.state.postrangeValue.length < 2) {
      delete page.start_date
      delete page.end_date
    }
    this.setState({
      loading: true
    })
    getApiDetail(page).then((res) => {
      if (res.code === 200) {
        let list = res.data
        let pagination = this.state.pagination
        pagination.total = list.count
        this.setState({
          pagination: pagination,
          data: list.data ? list.data : [],
          loading: false,
          loadingbody: false,
          no_data: list.all_count > 0 ? false : true
        })
      } else {
        let pagination = this.state.pagination
        // pagination.total = 0
        this.setState({
          pagination: pagination,
          loading: false,
          data: [],
          no_data: true,
          loadingbody: false
        })
      }
    })
  }

  handleMyParam(myParam) {
    // console.log('myParam:', myParam)
    let startDate, endDate
    if (myParam === '1') {
      // 当前日期
      startDate = new Date()
      endDate = new Date()
      this.setState({
        defaultDayValue: [this.formatDate(startDate), this.formatDate(endDate)]
      })
    } else if (myParam === '2') {
      // 上个月第一天到最后一天
      const currentDate = new Date()
      // 上个月第一天 00:00:00
      startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        1,
        0,
        0,
        0
      )
      // 上个月最后一天 23:59:59.999
      endDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        0,
        23,
        59,
        59,
        999
      )
      this.setState({
        defaultDayValue: [this.formatDate(startDate), this.formatDate(endDate)]
      })
    } else {
      console.warn('Invalid myParam value:', myParam)
      this.getbalance()
      return
    }

    // 格式化日期为 YYYY-MM-DD 字符串
    const formatDate = (date) => {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    }

    const dateString = [formatDate(startDate), formatDate(endDate)]

    // 更新状态并调用 getbalance
    this.setState(
      {
        postrangeValue: dateString,
        value: '',
        ishowdata: !this.state.ishowdata
      },
      () => {
        // console.log('dateString:', this.state.defaultDayValue)
        // console.log('postrangeValue:', this.state.postrangeValue)
        this.getbalance()
        // window.location.href = '/payment/breakdown'
      }
    )
  }
  formatDate(date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  getAllClass() {
    const newMenu = [{ key: '99', label: '全部', value: '全部' }]
    getAllClass().then((res) => {
      if (res.code === 200) {
        const list = res.data
        list.forEach((item, index) => {
          newMenu.push({ key: index, label: item, value: item })
        })
        this.setState({
          menuItems: newMenu
        })
      } else {
        this.setState({
          menuItems: newMenu
        })
        Notification.error({ content: res.msg })
      }
    })
  }
  onChangeTable(pagination) {
    const { current, pageSize } = pagination
    let setPagination = this.state.pagination
    setPagination.current = current
    setPagination.pageSize = pageSize
    this.setState({
      loading: false,
      pagination: setPagination
    })
    this.getbalance()
  }
  hideAllMenu = () => {
    if (this.state.ishowdata) {
      this.setState({
        ishowdata: !this.state.ishowdata
      })
    }
  }
  openDate(event) {
    this.stopPropagation(event)
    this.setState({
      ishowdata: !this.state.ishowdata
    })
  }
  onClickdate(event) {
    this.stopPropagation(event)
  }
  stopPropagation(e) {
    e.nativeEvent.stopImmediatePropagation()
  }
  setDates2(dateString) {
    this.setState({
      rangeValue2: dateString
    })
  }
  onChange2(dateString) {
    this.setState({
      rangeValue2: dateString,
      value: ''
    })
  }
  setDates(dateString) {
    this.setState({
      defaultDayValue: dateString
    })
  }
  onChange(dateString) {
    this.setState({
      rangeValue: dateString,
      value: ''
    })
  }
  onOk(dateString, date) {
    // console.log(dateString, 'event')
    // // this.state.rangeValue = dateString ? dateString : ["", ""];
    // this.state.value = ''
    let packagePagination = this.state.pagination
    packagePagination.current = 1
    this.setState(
      {
        postrangeValue: dateString ? dateString : ['', ''],
        value: '',
        ishowdata: !this.state.ishowdata,
        pagination: packagePagination
      },
      () => {
        this.getbalance()
      }
    )
    // this.getpackage();
  }
  clearTimes() {
    this.setState({ postrangeValue: [] }, () => {
      this.getbalance()
    })
  }

  typeChange(value) {
    this.setState({ class: value }, () => {
      this.getbalance()
    })
  }
  modelChange(value) {
    this.setState({ type: value }, () => {
      // window.location.href = '/payment/breakdown'
      this.getbalance()
    })
  }
  convertToCSV(data) {
    if (!data.length) {
      // Missage.error('导出数据为空')
      return ''
    }
    const headers = Object.keys(data[0]).join(',') + '\r\n'
    const rows = data
      .map((row) =>
        Object.values(row)
          .map(
            (field) => `"${String(field).replace(/"/g, '""')}"` // 处理双引号
          )
          .join(',')
      )
      .join('\r\n')
    return '\uFEFF' + headers + rows // 添加BOM头解决Excel乱码
  }
  handleExport(data) {
    const csvContent = this.convertToCSV(data)
    if (!csvContent) return
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = 'data.csv'
    link.click()
  }
  render() {
    const {
      loadingbody,
      loading,
      columns,
      data,
      pagination,
      no_data,
      rangeValue,
      rangeValue2,
      ishowdata,
      trading,
      apply,
      downVisible,
      timeRadio,
      deriveValue,
      menuItems,
      menuType,
      defaultDayValue
    } = this.state
    return (
      <div className="payment-details">
        <div className="Header-init">
          <div className="Header-title">消费明细</div>
        </div>
        <div>
          <div className="over-header">
            <div className="over-header-left">
              <div
                className="DatePicker-RangePicker"
                onClick={this.onClickdate.bind(this)}
              >
                <DatePicker.RangePicker
                  showTime
                  prefix={<IconCalendar />}
                  className="RangePicker-box"
                  style={{ width: 240 }}
                  format="YYYY-MM-DD"
                  value={rangeValue || defaultDayValue} // 优先显示选择值，无选择时显示默认值
                  onSelect={(valueString, value) => {
                    this.setDates(value)
                  }}
                  onClear={() => {
                    this.clearTimes()
                    this.setDates(null) // 确保清空后显示默认值
                  }}
                  onChange={(v) => this.onChange(v)}
                  onOk={this.onOk.bind(this)}
                  disabledDate={(current) => {
                    if (!current || current > moment().endOf('day')) return true

                    // 确保 rangeValue 的元素是有效的 Moment 对象
                    const [start, end] = (rangeValue || []).map((date) =>
                      moment.isMoment(date) ? date : moment(date)
                    )
                    const maxRange = 27
                    // 类型安全校验
                    const isStartValid = moment.isMoment(start)
                    const isEndValid = moment.isMoment(end)
                    // 当 start 或 end 无效时，仅禁用未来日期
                    if (!isStartValid && !isEndValid) return false
                    // 动态范围限制（带类型保护）
                    if (isStartValid && !isEndValid) {
                      return (
                        current.isBefore(start.startOf('day')) ||
                        current.isAfter(
                          start.clone().add(maxRange, 'days').endOf('day')
                        )
                      )
                    }
                    if (isEndValid && !isStartValid) {
                      return (
                        current.isAfter(end.endOf('day')) ||
                        current.isBefore(
                          end.clone().subtract(maxRange, 'days').startOf('day')
                        )
                      )
                    }
                    // 当两者都有效时的交叉校验
                    const tooLate = current.diff(start, 'days') > maxRange
                    const tooEarly = end.diff(current, 'days') > maxRange
                    return tooLate || tooEarly
                  }}
                />
              </div>
              {/* <div className="consumption-select">
                <Select
                  defaultValue=""
                  showSearch
                  addBefore="类型"
                  allowClear
                  onClear={() => this.modelChange()}
                >
                  {menuType.map((item) => (
                    <Select.Option
                      key={item.key}
                      onClick={() => this.modelChange(item.value)}
                    >
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </div> */}
              {/* <div className="consumption-select">
                <Select
                  defaultValue=""
                  showSearch
                  addBefore="应用"
                  allowClear
                  // onBlur
                >
                  {menuItems.map((item) => (
                    <Select.Option
                      key={item.key}
                      onClick={() => this.typeChange(item.value)}
                    >
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </div> */}
            </div>
            {/* <Dropdown
                  triggerProps={{ autoAlignPopupWidth: true }}
                  trigger="click"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  droplist={
                    <Menu className="header-menu" style={{maxHeight: 300}}>
                        {menuItems.map(item => (
                          <Menu.Item
                            key={item.key}
                            onClick={() => this.typeChange(item.value)}
                          >
                            {item.label}
                          </Menu.Item>
                        ))}
                    </Menu>
                  }
                  position="br"
                >
                  <Button
                    className="custom-select"
                    id="infoselect"
                    style={{ height: 43, padding: '0 13px', borderRadius: 14 }}
                  >
                    <div className="rowCenter">
                      <span className="custom-type">应用类型</span>
                      <span className="custom-shile"></span>
                      <Typography.Text ellipsis className="username-ellipsis">
                        {apply}
                      </Typography.Text>
                    </div>

                    <IconDown style={{ marginLeft: 20 }} />
                  </Button>
                </Dropdown> */}
            <div>
              <Button
                className="custom-White-button"
                // disabled
                icon={<IconDownload />}
                onClick={() => this.handleExport(data)}
              >
                导出文件
              </Button>
              <Button
                className="custom-White-button"
                // disabled
                onClick={() => this.getbalance()}
                icon={<IconSync />}
              >
                刷新
              </Button>
            </div>
          </div>
        </div>
        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <Card
            bordered={false}
            hoverable
            style={{ borderRadius: 20 }}
            className="Table-card"
          >
            <Table
              style={{ marginTop: 30 }}
              virtualized
              noDataElement={
                <Empty imgSrc={Emptydata_img} description={'暂无调用记录'} />
              }
              no_data={no_data}
              border={{ wrapper: false, cell: false }}
              loading={loading}
              columns={columns}
              data={data}
              pagination={pagination.total === 0 ? false : pagination}
              onChange={this.onChangeTable.bind(this)}
              renderPagination={(paginationNode) => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: 30
                  }}
                >
                  {paginationNode}
                </div>
              )}
              rowKey="id"
            />
          </Card>
        )}
        <Modal
          title={<div style={{ textAlign: 'left' }}>导出文件</div>}
          visible={downVisible}
          onCancel={() => {
            this.setState({ downVisible: false })
          }}
          onOk={() => {
            this.handleExport(data)
          }}
        >
          <div
            style={{
              fontSize: '16px',
              lineHeight: '22px',
              marginBottom: '22.5px'
            }}
          >
            文件类型：CSV
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
            <div>时间范围：</div>
            <RadioGroup defaultValue="a">
              <Radio
                value="a"
                className="time-radio"
                onChange={(value) => {
                  this.setState({ timeRadio: 'a' })
                  const endDate = new Date()
                  const startDate = new Date(
                    endDate.getFullYear(),
                    endDate.getMonth(),
                    1
                  )
                  const dateString = [
                    this.formatDate(startDate),
                    this.formatDate(endDate)
                  ]
                  this.setState(
                    {
                      deriveValue: dateString
                    },
                    () => {
                      this.getbalance()
                    }
                  )
                }}
              >
                按月
              </Radio>
              <Radio
                value="b"
                className="time-radio"
                onChange={(value) => {
                  this.setState({ timeRadio: 'b' })
                }}
              >
                自定义
              </Radio>
            </RadioGroup>
            {timeRadio === 'b' ? (
              <DatePicker.RangePicker
                // triggerElement={null}

                showTime
                prefix={<IconCalendar />}
                format="YYYY-MM-DD"
                value={rangeValue2}
                onSelect={(valueString, value) => {
                  this.setDates2(value)
                }}
                onChange={(v) => this.onChange2(v)}
                onOk={this.onOk.bind(this)}
                disabledDate={(current) => {
                  if (!current || current > moment().endOf('day')) return true

                  // 确保 rangeValue 的元素是有效的 Moment 对象
                  const [start, end] = (rangeValue2 || []).map((date) =>
                    moment.isMoment(date) ? date : moment(date)
                  )
                  const maxRange = 27
                  // 类型安全校验
                  const isStartValid = moment.isMoment(start)
                  const isEndValid = moment.isMoment(end)
                  // 当 start 或 end 无效时，仅禁用未来日期
                  if (!isStartValid && !isEndValid) return false
                  // 动态范围限制（带类型保护）
                  if (isStartValid && !isEndValid) {
                    return (
                      current.isBefore(start.startOf('day')) ||
                      current.isAfter(
                        start.clone().add(maxRange, 'days').endOf('day')
                      )
                    )
                  }
                  if (isEndValid && !isStartValid) {
                    return (
                      current.isAfter(end.endOf('day')) ||
                      current.isBefore(
                        end.clone().subtract(maxRange, 'days').startOf('day')
                      )
                    )
                  }
                  // 当两者都有效时的交叉校验
                  const tooLate = current.diff(start, 'days') > maxRange
                  const tooEarly = end.diff(current, 'days') > maxRange
                  return tooLate || tooEarly
                }}
              />
            ) : (
              ''
            )}
          </div>
        </Modal>
      </div>
    )
  }
}
export default withRouter(Paymentdetails)
