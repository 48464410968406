import React, { Component } from 'react'
import {
  Layout,
  Typography,
  Button,
  Space,
  Empty,
  Input,
  Select,
  Modal,
  Divider,
  Image,
  Notification,
  Message,
  Upload
} from '@arco-design/web-react'
import { IconDownload, IconPlus } from '@arco-design/web-react/icon'
import Emptydata_img from 'asserts/Emptydata_img.svg'
import Generating_img from 'asserts/Generating_img.gif'
import errorIcon from 'asserts/error_icon.svg'
import { localAxios } from 'api/request'
import copy from 'copy-to-clipboard'
import { CreateLink } from 'common/utils/business'
import {
  getApiInfo,
  getRequest,
  putapp,
  putmodelversion,
  check_resource,
  getAllapiList,
  newGetappList,
  newEditappList
} from 'api/apis'
import history from 'common/utils/history'
import { debounce, b2ValueUnit } from 'common/utils'
import { sourceCancelClass } from 'api/cancel-request'
import UpgradeTips from 'components/UpgradeTips'
import upload from 'api/uploads'
import $ from 'jquery'
import './resumeDocument.scss'
const Content = Layout.Content
const Option = Select.Option
const Sider = Layout.Sider
const TextArea = Input.TextArea
// 封装组件
class ResumeDocument extends Component {
  constructor(props) {
    super(props)
    this.state = {
      optionVlue: '',
      TextAreaValue: '',
      versions: [],
      ParagraphContinuation: [],
      ContinuationLoding: false,
      record: JSON.parse(localStorage.getItem('record')),
      apiInfo: {
        model_id: ''
      },
      package: 'false',
      requestMsg: '',
      requestMsgText: '您还未输入简历关键信息',
      attachment: [],
      fileList: [],
      visible: false,
      visibleimg: null,
      loading: false,
      keyword: '',
      downloadLoading: false,
      cancelToken: null,
      height: 240,
      input_describe: '产品经理,5年,蓝色',
      setChangeAbatchTime: '',
      setRequestTime: '',
      visibleUpgrade: false,
      upgradetips: null,
      identity: JSON.parse(localStorage.getItem('userinfo'))
        ? JSON.parse(localStorage.getItem('userinfo')).group_permission
        : '',
      apiValue: '',
      api_name: '',
      interfaceOption: [],
      api_id: '',
      isPost: true,
      TextAreaValue: '',
      example: '',
      prompt: '',
      interfaceOption2: [],
      apiValue2: '简历润色',
      isCowrite: false,
      isGenerate: false,
      isShow: false
    }
    this.isputapp = debounce(this.isputapp, 1000)
    this.isPhoneLegal = debounce(this.isPhoneLegal, 1000)
  }
  componentDidMount() {
    this.getApiInfo()
    localStorage.setItem('selectApiId', '')
    window.addEventListener('resize', this.handleResize)
    setTimeout(() => {
      this.handleResize()
    }, 300)
  }
  componentWillUnmount() {
    localStorage.removeItem('props')
    window.removeEventListener('resize', this.handleResize)
    clearTimeout(this.state.setRequestTime)
    clearTimeout(this.state.setChangeAbatchTime)
    sourceCancelClass()
  }
  handleResize = () => {
    let winHeight = 0
    if (window.innerHeight) {
      winHeight = window.innerHeight
    } else if (document.body && document.body.clientHeight) {
      winHeight = document.body.clientHeight
    }
    if (document.documentElement && document.documentElement.clientHeight) {
      winHeight = document.documentElement.clientHeight
    }
    let layoutHeaders_h = document.getElementById('layoutHeaders').clientHeight
    if (layoutHeaders_h > winHeight) {
      layoutHeaders_h = 64
    }
    let height =
      winHeight -
      layoutHeaders_h -
      document.getElementById('renderHeaders').clientHeight -
      32 -
      45
    document.getElementById('layout_id').style.height = height + 'px'

    if (document.getElementById('continuation')) {
      this.initSlider()
      let height2 =
        height - document.getElementById('result_title').clientHeight - 34
      document.getElementById('continuation').style.height = height2 - 30 + 'px'
    }
  }

  handleUpload(option) {
    upload(option)
  }
  setFile(fileList, file) {
    this.setState({
      fileList: fileList
    })
    if (file.status === 'done') {
      let key = []
      let fileList = this.state.fileList
      fileList.forEach((item, index) => {
        key.push(item.response.fileKey)
      })
      this.setState({
        attachment: key,
        fileList: fileList
      })
    }
  }

  openInterface(value) {
    const { interfaceOption, identity } = this.state
    // const keyName = ["AI简历生成","简历润色","简历续写","简历扩写","短词成文","简历精炼"];

    //没有体验页面的直接跳转接口调试

    const selectOption = interfaceOption.find((item) => item.value == value)
    if (value !== 1 && value !== 0 && identity !== 'PERSONAL') {
      localStorage.setItem('selectApiId', selectOption.api_id)
      localStorage.setItem('record', JSON.stringify(this.state.record))
      history.push('/myapp/interfacetest')
      window.location.reload()
    }

    if (value == 1) {
      this.setState(
        {
          api_id: '6yuDjq',
          apiValue: '简历辅写',
          api_name: '简历润色',
          ParagraphContinuation: [],
          isShow: true
        },
        () => {
          this.getInterfaceParameter()
        }
      )
    } else {
      this.setState(
        {
          api_id: interfaceOption[value].api_id,
          apiValue: interfaceOption[value].value,
          api_name: interfaceOption[value].label,
          ParagraphContinuation: [],
          isShow: false
        },
        () => {
          this.getInterfaceParameter()
        }
      )
    }
  }

  openInterface2(value) {
    const { interfaceOption2 } = this.state
    const filterInterfaceOption = interfaceOption2.filter(
      (item) => item.value == value
    )
    this.setState(
      {
        api_id: filterInterfaceOption[0].api_id,
        apiValue2: filterInterfaceOption[0].value,
        api_name: filterInterfaceOption[0].label,
        ParagraphContinuation: []
      },
      () => {
        this.getInterfaceParameter()
      }
    )
  }

  getApiInfo(put) {
    const { interfaceOption, interfaceOption2 } = this.state
    let params = {
      id: this.state.record.show_id
    }
    newGetappList(params).then((res) => {
      if (res.code === 200) {
        let list = res.data
        if (put) {
          this.setState({
            apiInfo: list
          })
        } else {
          let isGenerate = false
          let isCowrite = false
          let generateResum = {}

          const keyName = [
            '简历润色',
            '简历续写',
            '简历扩写',
            '短词成文',
            '简历精炼'
          ]
          res.data.api_list.forEach((item, index) => {
            if (item.name === 'AI简历生成') {
              let api_option = {
                value: '',
                label: '',
                api_id: ''
              }
              isGenerate = true
              api_option.value = '0'
              api_option.label = item.name
              api_option.api_id = item.api_id
              generateResum = item
              interfaceOption.push(api_option)
            } else if (keyName.includes(item.name)) {
              let api_option2 = {
                value: '',
                label: '',
                api_id: ''
              }
              isCowrite = true
              api_option2.value = index
              api_option2.label = item.name
              api_option2.api_id = item.api_id
              interfaceOption2.push(api_option2)
            } else {
              let api_option3 = {
                value: '',
                label: '',
                api_id: ''
              }
              // isGenerate = true;
              api_option3.value = index + 5
              api_option3.label = item.name
              api_option3.api_id = item.api_id
              if (Object.keys(generateResum).length == 0) {
                generateResum = item
              }
              interfaceOption.push(api_option3)
            }
          })
          if (isCowrite) {
            interfaceOption.push({
              value: '1',
              label: '简历辅写',
              api_id: 'xxx123'
            })
          }
          //isShow: 是否显示辅写类型页面
          this.setState({
            apiInfo: list,
            apiValue: isGenerate
              ? generateResum.name
              : isCowrite
              ? '简历辅写'
              : interfaceOption[0].label,
            api_name: isGenerate
              ? generateResum.name
              : isCowrite
              ? '简历辅写'
              : interfaceOption[0].label,
            api_id: isGenerate
              ? generateResum.api_id
              : isCowrite
              ? 'xxx123'
              : interfaceOption[0].api_id,
            interfaceOption: interfaceOption,
            interfaceOption2: interfaceOption2,
            isGenerate: isGenerate,
            isCowrite: isCowrite,
            isShow: isGenerate ? false : true
          })
        }

        this.getInterfaceParameter()
        //console.log(res.data, "api服务");
      } else {
      }
    })
  }

  getInterfaceParameter = () => {
    const { api_id, api_name, isShow, apiInfo, apiValue2 } = this.state
    let apiId = api_id
    if (api_id === 'xxx123') {
      apiId = apiInfo.api_list.find((item) => item.name === apiValue2).api_id
    }
    let params = {
      id: apiId
      // id: '95YT1z',
    }
    // console.log(apiInfo.api_list,api_id,apiValue2);
    getAllapiList(params).then((res) => {
      if (res.code === 200) {
        let text = ''
        if (res.data.api_url === '/resumes/write-resume') {
          text =
            res.data.api_params.post.requestBody.content['application/json']
              .example.text
        }
        this.setState(
          {
            TextAreaValue: text,
            prompt: text,
            example: res.data.api_params,
            postValue: res.data.api_url,
            isPost: res.data.api_params?.post !== undefined
          },
          () => {
            // if(api_name === "简历续写" || api_name === "简历润色" || api_name === "简历扩写" || api_name === "简历精炼" || api_name === "短词成文"){
            if (isShow) {
              this.writeResume(this.state.TextAreaValue)
            }
          }
        )
        // this.onPressEnter()
      }
    })
  }

  onResumeEnter() {
    const { postValue, isPost, keyword } = this.state

    let postApi = null
    if (keyword == '') {
      this.state.keyword = this.state.input_describe
    }
    this.setState({
      ContinuationLoding: true
    })
    let params = {
      text: keyword || ''
    }
    if (isPost) {
      postApi = localAxios('post', postValue)
    } else {
      postApi = localAxios('get', postValue)
    }

    if (postValue === '/resumes/build-resume') {
      this.resumesBuild(0)
    }
    // else if(postValue === "/resumes/write-resume") {
    //   this.writeResume()

    // }
  }

  referWriteResume() {
    this.setState({
      ParagraphContinuation: []
    })
    this.writeResume(this.state.TextAreaValue)
  }

  writeResume(value) {
    const {
      postValue,
      isPost,
      ParagraphContinuation,
      TextAreaValue,
      api_name
    } = this.state

    let postApi = localAxios('post', postValue)
    let writeType = 2
    switch (api_name) {
      case '简历润色':
        writeType = 1
        break
      case '简历扩写':
        writeType = 2
        break
      case '简历精炼':
        writeType = 3
        break
      case '简历续写':
        writeType = 4
        break
      case '短词成文':
        writeType = 5
        break
      default:
        break
    }

    // "1-润色 2-扩写 3-精炼 4-续写 5-短词成文"
    let params = {
      text: value || '',
      // industry: "",
      // job: "程序员",
      num: 1,
      // scene: "求职简历",
      task: writeType
    }
    this.setState({
      TextAreaValue: value,
      ContinuationLoding: true
    })
    postApi(params).then((res) => {
      if (res.code === 200) {
        this.setState({
          ParagraphContinuation: res.data.choices,
          ContinuationLoding: false
        })
      } else if (res.code === 3003) {
        this.setState({
          ContinuationLoding: false
        })
        Notification.warning({ content: res.msg })
      } else {
        this.setState({
          ContinuationLoding: false
        })
        Notification.error({ content: res.msg })
      }
    })
  }

  resumesBuild = async (count) => {
    if (count >= 1) return // 达到 1 次后停止

    const { postValue, isPost, keyword, ParagraphContinuation } = this.state

    this.setState({
      loading: true
    })

    let postApi = localAxios('post', postValue)
    let params = {
      text: keyword || ''
    }

    try {
      const res = await postApi(params)
      if (res.code === 200) {
        this.setState((prevState) => ({
          ParagraphContinuation: [...prevState.ParagraphContinuation, res.data],
          ContinuationLoding: false,
          loading: false
        }))
      } else if (res.code === 3003) {
        this.setState({
          ContinuationLoding: false
        })
        Notification.warning({ content: res.msg })
        return
      } else {
        this.setState({
          ContinuationLoding: false,
          loading: false
        })
        Notification.error({ content: res.msg })
        return
      }
    } catch (error) {
      console.error('Error in resumesBuild:', error)
    }

    // 递归调用下一次
    this.resumesBuild(count + 1)
  }

  resumesChangeAbatch(type) {
    const { postValue, isPost, keyword } = this.state

    if (type === 'start') {
      this.setState({
        ParagraphContinuation: [],
        loading: false,
        ContinuationLoding: true,
        requestMsg: ''
      })
    } else {
      this.setState({
        loading: true,
        requestMsg: ''
      })
    }
    if (postValue === '/resumes/build-resume') {
      this.resumesBuild(0)
    }
  }

  oncopy(item) {
    let value = item.sentence
    if (copy(value)) {
      Notification.success({ content: '复制成功!' })
    } else {
      Notification.error({ content: '复制失败!' })
    }
  }
  Onreplace(newstr, finished) {
    let reg = this.state.prompt.toLowerCase()
    let html = `<span class='Preamble'>${reg}</span>`

    if (!finished) {
      let regFinished = /([^0-9a-zA-Z\u4e00-\u9fff])*$/
      newstr = newstr.replace(regFinished, '...')
    }
    newstr = newstr.replace(reg, html)
    let htmls = { __html: newstr }
    return <div dangerouslySetInnerHTML={htmls}></div>
  }

  onPressEnter(e) {
    clearTimeout(this.state.setRequestTime)
    clearTimeout(this.state.setChangeAbatchTime)
    sourceCancelClass()
    this.setState({
      keyword: this.state.keyword,
      ParagraphContinuation: [],
      loading: false,
      ContinuationLoding: true,
      requestMsg: ''
    })
    this.state.ParagraphContinuation = []
    this.isPhoneLegal()
  }
  putmodelversion(optionVlue) {
    let params = {
      id: optionVlue
    }
    putmodelversion(params).then((res) => {
      if (res.code === 200) {
        let list = res.data
        if (this.state.keyword) {
          this.isPhoneLegal()
        }
      } else {
        Notification.warning({ content: '模型版本激活失败！' })
      }
    })
  }
  onsetPosition(value) {
    //console.log(value, "value");
    this.setState({
      optionVlue: value
    })
    this.putmodelversion(value)
  }
  onTextArea(value) {
    this.setState({
      keyword: value
    })
  }
  isPhoneLegal = () => {
    this.setRequest()
    //console.log(this.state.ParagraphContinuation, "isPhoneLegal请求");
  }
  setRequest() {
    clearTimeout(this.state.setRequestTime)
    clearTimeout(this.state.setChangeAbatchTime)
    if (this.state.keyword === '') {
      this.setState({
        ParagraphContinuation: [],
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '您还未输入简历关键信息'
      })
    } else {
      let params = {
        app_id: this.state.record.id,
        job: this.state.keyword,
        package: this.state.package
      }

      let userInfo = localStorage.getItem('userinfo')

      if (userInfo) {
        userInfo = JSON.parse(userInfo)
        let custom = {}

        if (userInfo['nickname']) {
          custom['name'] = userInfo['nickname']
        }
        if (userInfo['company']) {
          custom['company'] = userInfo['company']
        }
        if (userInfo['mobile']) {
          custom['mobile'] = userInfo['mobile']
        }

        params['custom'] = JSON.stringify(custom)
      }

      const { ParagraphContinuation } = this.state

      this.setState({
        loading: true
      })
      let that = this
      this.getCheckResource(function (result) {
        if (result.code === 200) {
          getRequest(params).then((res) => {
            if (res.code === 200) {
              let list = res.data
              that.setState({
                ParagraphContinuation: ParagraphContinuation.concat(list),
                ContinuationLoding: false,
                requestMsg: ''
              })
              if (that.state.ParagraphContinuation.length % 14 !== 0) {
                that.state.setRequestTime = setTimeout(() => {
                  that.isPhoneLegal()
                }, 4000)
              } else {
                that.setState({
                  loading: false
                })
              }
              if (
                that.state.ParagraphContinuation.length === 1 &&
                list.length > 0
              ) {
                setTimeout(() => {
                  that.handleResize()
                }, 100)
              }
            } else if (
              res.code === 400 ||
              res.code === 503 ||
              res.code === 3003
            ) {
              that.setState({
                ContinuationLoding: false,
                requestMsg: '',
                loading: false
              })
              if (ParagraphContinuation.length > 0) {
                Notification.warning({ content: res.msg })
              } else {
                that.setState({
                  ParagraphContinuation: [],
                  requestMsgText: res.msg
                })
              }
            } else {
              that.setState({
                ContinuationLoding: false,
                requestMsg: '',
                loading: false
              })
              if (ParagraphContinuation.length > 0) {
                Notification.warning({ content: res.msg })
              } else {
                that.setState({
                  ParagraphContinuation: [],
                  requestMsgText: res.msg,
                  requestMsg: 'error'
                })
              }
            }
          })
        } else {
          that.setState({
            ContinuationLoding: false,
            requestMsg: '',
            loading: false
          })
          if (ParagraphContinuation.length > 0) {
            Notification.warning({ content: result.msg })
          } else {
            that.setState({
              ParagraphContinuation: [],
              requestMsgText: result.msg,
              requestMsg: 'error'
            })
          }
        }
      })
    }
  }
  getCheckResource(callback) {
    let params = { model_capability: this.state.apiInfo?.model_capability }
    // console.log(this.state.apiInfo,this.state.apiValue,this.state.api_id,'777');
    let PACKAGES = ['PACKAGE_FINISH', 'PACKAGE_EXPIRED']
    check_resource(params).then((res) => {
      if (res.code === 200) {
        if (res.data) {
          let new_upgradetips = res.data
          new_upgradetips.model_capability = params.model_capability
          this.setState({
            upgradetips: new_upgradetips
          })
          if (PACKAGES[new_upgradetips.notice_type]) {
            Notification.warning({ content: new_upgradetips.message })
          } else {
            setTimeout(() => {
              this.setState({
                visibleUpgrade: true
              })
            }, 100)
          }
        }
        callback(res)
      } else {
        callback(res)
      }
    })
  }
  initSlider() {
    let screenWidth = $('.Continuation-item').width() //浏览器页面的宽度
    let height = screenWidth * 1.41 //14.2
    this.setState({
      height: height
    })
    // $(".Continuation-img").height(height + 'px');//设置每一个div的宽度
  }
  // goResources() {
  //   history.push('/myapp/resources')
  //   localStorage.setItem('record', JSON.stringify(this.state.record))
  //   window.location.reload()
  // }
  goInterfacetest() {
    history.push('/myapp/interfacetest')
    localStorage.setItem('record', JSON.stringify(this.state.record))
    window.location.reload()
  }
  ChangeAbatch(type) {
    sourceCancelClass()
    clearTimeout(this.state.setRequestTime)
    clearTimeout(this.state.setChangeAbatchTime)
    if (type === 'start') {
      this.setState({
        ParagraphContinuation: [],
        loading: false,
        ContinuationLoding: true,
        requestMsg: ''
      })
    } else {
      this.setState({
        loading: true,
        requestMsg: ''
      })
    }
    this.state.setChangeAbatchTime = setTimeout(() => {
      this.isPhoneLegal()
    }, 1000)
  }
  createTagsA = (tags, lightHighArr, wordArrAppend) => {
    //标签处理
    let html = ''
    tags.map((item, index) => {
      let className = ''

      for (let i in lightHighArr) {
        if (lightHighArr[i] === item) {
          className += 'hl-k '
          break
        }
      }

      for (let i in wordArrAppend) {
        if (wordArrAppend[i] === item) {
          className += 'hl-m '
          break
        }
      }

      if (className) {
        className = ' class = "' + className + '"'
      }

      html += '<span ' + className + '>' + item + '</span>'
    })
    let htmls = { __html: html }
    return <div className="Tags" dangerouslySetInnerHTML={htmls}></div>
  }
  setdownload(visible) {
    this.setState({
      visible: visible
    })
  }
  opendownload(item) {
    this.setdownload(true)
    this.setState({
      visibleimg: item
    })
  }
  getUrlBase64() {
    this.setState({
      downloadLoading: true
    })
    const url = this.state.visibleimg.document // 获取图片地址
    // var a = document.createElement('a');          // 创建一个a节点插入的document
    // var event = new MouseEvent('click')           // 模拟鼠标click点击事件
    // a.download = '样机图'                  // 设置a节点的download属性值
    // a.target = "_blank"
    // a.href = url;                                 // 将图片的src赋值给a节点的href
    // a.dispatchEvent(event)
    fetch(url)
      .then(async (res) => await res.blob())
      .then((blob) => {
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = URL.createObjectURL(blob)
        a.download = '智能简历_' + url.substr(url.lastIndexOf('/') + 1)
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        this.setState({
          downloadLoading: false
        })
      })
  }
  isputapp = (name, desc) => {
    this.putapp(name, desc)
  }
  putapp(name, desc) {
    let params = {
      id: this.state.record.show_id,
      desc: desc,
      name: name
    }
    newEditappList(params).then((res) => {
      if (res.code === 200) {
      } else {
        this.getApiInfo(true)
        Notification.error({ content: res.msg })
      }
    })
  }
  onChangetitle(type, value) {
    //console.log(value, "value");
    var apiInfo = this.state.apiInfo
    if (type === 'desc') {
      if (value.length > 100) {
        apiInfo.desc = value.substring(0, 100)
        Notification.warning({ content: '修改内容超过100个字！' })
        return
      }
      apiInfo.desc = value
      this.setState({
        apiInfo: apiInfo
      })
      this.putapp(apiInfo.name, value)
    } else if (type === 'name') {
      if (value.length > 10) {
        apiInfo.name = value.substring(0, 20)
        Notification.warning({ content: '修改标题超过20个字！' })
        return
      }
      apiInfo.name = value
      this.setState({
        apiInfo: apiInfo
      })
      this.putapp(value, apiInfo.desc)
    }
  }
  goaddApp() {
    history.replace('/myapp/addmyapp')
    let state = {
      model_capability: this.state.apiInfo.model_capability,
      checkedtype: this.state.apiInfo.model_id,
      model_id: this.state.apiInfo.model_id,
      name: this.state.apiInfo.model_name
    }
    localStorage.setItem('props', JSON.stringify(state))
    localStorage.removeItem('record')
    window.location.reload()
  }
  addRow() {
    localStorage.removeItem('props')
    localStorage.setItem('record', JSON.stringify(this.state.apiInfo))
    history.replace('/enginefactory/model/management/add')
    window.location.reload()
  }
  setvisibleUpgrade(value) {
    this.setState({
      visibleUpgrade: value
    })
  }
  beforeUpload(file) {
    // var AllImgExt = '.csv|'
    // var extName = file.name.substring(file.name.lastIndexOf('.')).toLowerCase() //（把路径中的所有字母全部转换为小写）
    // if (AllImgExt.indexOf(extName + '|') === -1) {
    //   let ErrMsg =
    //     '该文件类型不允许上传。请上传 .csv类型的文件，当前文件类型为' + extName
    //   Message.error(ErrMsg)
    //   return false
    // }
    const isLt10M = !(
      b2ValueUnit(file.size)[1] === 'B' ||
      b2ValueUnit(file.size)[1] === 'KB' ||
      (b2ValueUnit(file.size)[1] === 'MB' && b2ValueUnit(file.size)[0] <= 5)
    )
    return new Promise((resolve, reject) => {
      if (isLt10M) {
        Message.error('上传文件不能大于5MB')
        reject()
      } else {
        resolve()
      }
    })
  }

  render() {
    const {
      visibleUpgrade,
      upgradetips,
      apiInfo,
      versions,
      optionVlue,
      input_describe,
      keyword,
      downloadLoading,
      loading,
      visibleimg,
      ParagraphContinuation,
      ContinuationLoding,
      requestMsg,
      requestMsgText,
      apiValue,
      interfaceOption,
      fileList,
      TextAreaValue,
      api_name,
      interfaceOption2,
      apiValue2,
      isShow
    } = this.state
    return (
      <div className="resumeDocument">
        <div id="renderHeaders">
          <div className="GroupTable-fixe GroupTable-fixe-end">
            <div className="edit-title">
              <div className="Header-init">
                <div className="Header-title">
                  <Typography.Paragraph
                    className="Header-uptitle"
                    editable={
                      apiInfo.type === 0
                        ? null
                        : {
                            onChange: this.onChangetitle.bind(this, 'name')
                          }
                    }
                  >
                    {apiInfo.name}
                  </Typography.Paragraph>
                </div>
              </div>
              <div className="Header-upParagraph">
                <Typography.Paragraph
                  className="Header-uptitle"
                  editable={
                    apiInfo.type === 0
                      ? null
                      : {
                          onChange: this.onChangetitle.bind(this, 'desc')
                        }
                  }
                >
                  {apiInfo.desc}
                </Typography.Paragraph>
              </div>
            </div>
            <Space size={'medium'} align="center">
              {apiInfo.type === 0 ? (
                <Button
                  className="primarybtn custom-White-button"
                  onClick={this.goaddApp.bind(this)}
                >
                  保存应用
                </Button>
              ) : (
                <>
                  {versions.length === 0 ? (
                    <div></div>
                  ) : (
                    <Select
                      value={optionVlue}
                      placeholder="版本选择"
                      style={{ width: 220 }}
                      className="versionsSelect"
                      onChange={this.onsetPosition.bind(this)}
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider
                            style={{
                              margin: 0,
                              marginTop: 6,
                              borderColor: '#f2f2f2'
                            }}
                          />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: '5px 0px'
                            }}
                          >
                            <Button
                              style={{
                                width: '100%',
                                height: 41,
                                fontSize: 14,
                                padding: '0 6px',
                                borderRadius: 10
                              }}
                              type="text"
                              onClick={this.addRow.bind(this)}
                            >
                              <IconPlus />
                              新增模型版本
                            </Button>
                          </div>
                        </div>
                      )}
                    >
                      {versions.map((option, index) => (
                        <Option key={index} value={option.id}>
                          {apiInfo.model_name}V{option.version}
                        </Option>
                      ))}
                    </Select>
                  )}
                  {apiInfo.type === 9 ? (
                    <div className="primary_model_name">
                      <div className="primary_model_name_fix">
                        <span className="primary_model_name_title">
                          模型
                          <Divider type="vertical" />
                        </span>
                        <Typography.Paragraph ellipsis>
                          {apiInfo.model_name}
                        </Typography.Paragraph>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {/* <Button
                    shape="circle"
                    className="custom-White-button"
                    onClick={this.goResources.bind(this)}
                  >
                    应用资源
                  </Button> */}
                  {/* <Button
                    shape="circle"
                    className="custom-White-button share_button_wnk"
                    onClick={CreateLink.bind(this, apiInfo)}
                  >
                    分享应用
                  </Button> */}
                  {interfaceOption.length >= 1 ? (
                    <Button
                      style={{
                        backgroundColor: 'transparent'
                      }}
                    >
                      <Select
                        placeholder="请选择接口"
                        value={apiValue}
                        onChange={(value) => this.openInterface(value)}
                        options={interfaceOption}
                      ></Select>
                    </Button>
                  ) : (
                    <></>
                  )}
                  <Button
                    style={{
                      display:
                        this.state.identity &&
                        this.state.identity !== 'PERSONAL'
                          ? 'inline-block'
                          : 'none'
                    }}
                    shape="circle"
                    className="custom-White-button"
                    onClick={this.goInterfacetest.bind(this)}
                  >
                    接口调用
                  </Button>
                </>
              )}
            </Space>
          </div>
          {isShow ? (
            ''
          ) : (
            <div className="Search-input">
              <Input
                placeholder={input_describe}
                className="Search"
                value={keyword}
                onChange={this.onTextArea.bind(this)}
                onPressEnter={this.onResumeEnter.bind(this)}
              />
              <Button
                type="primary"
                className="Header-init-btn"
                loading={false}
                onClick={this.onResumeEnter.bind(this)}
              >
                生成简历
              </Button>
            </div>
          )}
        </div>
        <Layout className="semanticprototype-Layout" id="layout_id">
          {isShow ? (
            <Sider className="Sider-left Silder-resume">
              <div className="Sider-left-configure columnSpaceBetween">
                <div className="cowrite">
                  <div className="cowrite-title">功能类型：</div>
                  <Button
                    style={{
                      backgroundColor: 'transparent',
                      width: '100%'
                    }}
                  >
                    <Select
                      placeholder="请选择接口"
                      value={apiValue2}
                      onChange={(value) => this.openInterface2(value)}
                      options={interfaceOption2}
                    ></Select>
                  </Button>
                </div>
                <div className="Continuation-input">
                  <TextArea
                    placeholder="请输入内容，开始续写"
                    style={{ width: '100%' }}
                    value={TextAreaValue}
                    onChange={this.writeResume.bind(this)}
                  />
                </div>
              </div>
            </Sider>
          ) : (
            ''
          )}
          <Content>
            <div className="experience-result" id="result">
              <div id="result_title" className="rowSpaceBetween result_title">
                <Typography>生成结果</Typography>
                <Typography.Paragraph className="result-remarks">
                  (内容基于韦尼克AI创作引擎生成，仅供参考)
                </Typography.Paragraph>
              </div>
              {ParagraphContinuation.length > 0 || ContinuationLoding ? (
                <div
                  className={`Paragraph-generate 
                ${isShow ? 'Paragraph-generate-text' : ''}`}
                >
                  {ContinuationLoding ? (
                    <Empty
                      className="Continuation-loding"
                      icon={<img src={Generating_img} />}
                      description={
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 21,
                            fontSize: 16
                          }}
                        >
                          正在生成中...
                        </Typography>
                      }
                    />
                  ) : (
                    <div className="Continuation-result" id="continuation">
                      <div className="Continuation-list">
                        {isShow
                          ? ParagraphContinuation.map((item, index) => {
                              return (
                                <div
                                  className="Continuation-item"
                                  key={index}
                                  onClick={this.oncopy.bind(this, item)}
                                >
                                  {this.Onreplace(item.message.content, true)}
                                </div>
                              )
                            })
                          : ParagraphContinuation.map((item, index) => {
                              return (
                                <div
                                  className="Continuation-item"
                                  key={index}
                                  onClick={this.opendownload.bind(this, item)}
                                >
                                  <div
                                    className={`Continuation-img ${
                                      item.file_type === 'docx'
                                        ? `file-docx`
                                        : item.file_type === 'docx'
                                        ? 'file-pdf'
                                        : ``
                                    }`}
                                    style={{ height: this.state.height }}
                                  >
                                    {/* <Avatar shape='square'>
                                                <img
                                                  alt='avatar'
                                                  key={index}
                                                  src={item.image[0]}
                                                />
                                              </Avatar> */}
                                    <Image
                                      key={index}
                                      src={item.image[0]}
                                      alt={`lamp${index + 1}`}
                                      loaderClassName="loaderName"
                                    />
                                  </div>
                                </div>
                              )
                            })}
                      </div>
                      {loading ? (
                        <Empty
                          className="more-loding"
                          icon={<img src={Generating_img} />}
                          description={
                            <Typography
                              style={{
                                color: '#4e5969',
                                marginTop: 21,
                                fontSize: 16
                              }}
                            >
                              正在生成中...
                            </Typography>
                          }
                        />
                      ) : isShow ? (
                        <Button
                          id="iconSync"
                          className="IconSync-btn custom-White-border-button"
                          onClick={this.referWriteResume.bind(this)}
                        >
                          换一批
                        </Button>
                      ) : (
                        <Button
                          id="iconSync"
                          className="IconSync-btn custom-White-border-button"
                          onClick={this.resumesChangeAbatch.bind(this)}
                        >
                          生成更多
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              ) : requestMsg === 'error' ? (
                <Empty
                  className="Continuation-Empty Empty-error"
                  imgSrc={errorIcon}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16
                        }}
                      >
                        抱歉，服务器生成错误
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        可尝试
                        <span
                          style={{ color: '#406EFF', cursor: 'pointer' }}
                          onClick={this.resumesChangeAbatch.bind(this, 'start')}
                        >
                          {' '}
                          重新生成
                        </span>
                      </Typography>
                    </Space>
                  }
                />
              ) : (
                <Empty
                  className="Continuation-Empty Empty-none"
                  imgSrc={Emptydata_img}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16
                        }}
                      >
                        暂无生成结果
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        {requestMsgText}
                      </Typography>
                    </Space>
                  }
                />
              )}
            </div>
            {api_name === '简历美化' ? (
              <div className="upload-file">
                <div className="upload-file-title">导入简历</div>
                <div>
                  <Upload
                    accept=".pdf,.doc,.docx,.jpg,.png,.jpeg,.PNG"
                    fileList={fileList}
                    renderUploadList={this.renderUploadList}
                    limit={1}
                    onChange={this.setFile.bind(this)}
                    customRequest={(option) => {
                      this.handleUpload(option)
                    }}
                    beforeUpload={this.beforeUpload.bind(this)}
                    onExceedLimit={() => {
                      Message.warning('超过上传数量限制！最多上传1个')
                    }}
                  >
                    <div className="columnCenter trigger">
                      <span className="iconfont icon-icon_chuangjian_24"></span>
                      <div>点击此处上传文件</div>
                    </div>
                  </Upload>
                  {/* <div className="Upload-pictures-tip">
                  （仅支持.pdf格式，最大文件尺寸5MB）
                </div> */}
                </div>
              </div>
            ) : (
              ''
            )}
          </Content>
        </Layout>
        <Modal
          style={{
            width: 479
          }}
          onCancel={() => this.setdownload(false)}
          className="custom-modal-download-document"
          title={null}
          visible={this.state.visible}
          autoFocus={false}
          footer={
            <>
              <Button
                type="primary"
                className="download-btn"
                loading={downloadLoading}
                onClick={this.getUrlBase64.bind(this)}
              >
                {!downloadLoading && <IconDownload />}立即下载
              </Button>
            </>
          }
        >
          <div className="custom-modal-download-content">
            {visibleimg ? <img src={visibleimg.image[0]} alt="" /> : ''}
            {visibleimg
              ? this.createTagsA(
                  visibleimg.Tags.tags,
                  visibleimg.Tags.lightHighArr,
                  visibleimg.Tags.wordArrAppend
                )
              : ''}
          </div>
        </Modal>
        <UpgradeTips
          visible={visibleUpgrade}
          notification_status={upgradetips?.notice_type}
          notification_message={upgradetips?.message}
          model_capability={upgradetips?.model_capability}
          onCancel={() => this.setvisibleUpgrade(false)}
        ></UpgradeTips>
      </div>
    )
  }
}
export default ResumeDocument
