import React, { Component } from 'react'
import {
  Empty,
  Grid,
  DatePicker,
  Radio,
  Space,
  Card,
  Typography,
  Divider,
  Link,
  Input,
  Button,
  Select,
  Message,
  Layout,
  Tabs,
  Form,
  Descriptions,
  Upload,
  Notification,
  InputNumber,
  Spin,
  Trigger
} from '@arco-design/web-react'
import {
  IconCopy,
  IconUpload,
  IconDelete,
  IconSettings
} from '@arco-design/web-react/icon'
import Emptydata_img from 'asserts/Emptydata_img.svg'
import copy from 'copy-to-clipboard'
import history from 'common/utils/history'
import upload from 'api/uploads'
import {
  getParams,
  postDebug,
  check_resource,
  getAllapiList,
  newGetappList,
  newEditappList,
  newRefreshToken
} from 'api/apis'
import ALLURL from 'envconfig/config'
import './interfacetest.scss'
import UpgradeTips from 'components/UpgradeTips'
import { localAxios } from 'api/request'
import { debounce } from 'common/utils'
import Generating_img from 'asserts/Generating_img.gif'

const FormItem = Form.Item
const RadioGroup = Radio.Group
const Row = Grid.Row
const Col = Grid.Col
const RangePicker = DatePicker
const InputSearch = Input.Search
const Sider = Layout.Sider
const Content = Layout.Content
const TextArea = Input.TextArea
const TabPane = Tabs.TabPane
class Interfacetest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingbody: true,
      SearchValue: ALLURL.baseURL + '/v2/api/request',
      Tablist: ['接口测试', '参数说明'],
      type: '接口测试',
      userinfo: JSON.parse(localStorage.getItem('userinfo')),
      fileList: null,
      attachment: [],
      record: JSON.parse(localStorage.getItem('record')),
      business_parameters: [],
      global_params: {
        access_token:
          JSON.parse(localStorage.getItem('userinfo')).access_key || '',
        access_secret:
          JSON.parse(localStorage.getItem('userinfo')).access_secret || ''
      },
      initialValues: {},
      visibleUpgrade: false,
      visible: false,
      upgradetips: null,
      interfaceOption: [],
      postValue: '',
      postData: [],
      api_id: '',
      apiValue: '',
      request_param: '',
      newApi_id: '',
      requestBody: false,
      loadingright: false,
      Specification: [
        // {
        //   key: 'AccessSecret',
        //   required: true,
        //   type: 'text',
        //   desc: '授权令牌',
        // },
        // {
        //   key: 'AccessToken',
        //   required: true,
        //   type: 'text',
        //   desc: '授权密钥',
        // },
        {
          key: 'Token',
          required: true,
          type: 'text',
          desc: 'token'
        }
        // {
        //   key: 'app_id',
        //   required: true,
        //   type: 'text',
        //   desc: '应用id',
        // },
      ],
      SpecificationList: [],
      RequestResponse: null,
      RequestResponseValue: null,
      paramsLength: 0,
      model_capability_name: '',
      apiToken: '',
      apiInfo: {
        model_id: '',
        app_name: '',
        type: 9,
        app_desc: ''
      },
      isPost: true
    }
    this.formRef = React.createRef()
  }
  handleResize = () => {
    var winHeight = 0
    if (window.innerHeight) {
      winHeight = window.innerHeight
    } else if (document.body && document.body.clientHeight) {
      winHeight = document.body.clientHeight
    }
    if (document.documentElement && document.documentElement.clientHeight) {
      winHeight = document.documentElement.clientHeight
    }
    let layoutHeaders_h = document.getElementById('layoutHeaders').clientHeight
    if (layoutHeaders_h > winHeight) {
      layoutHeaders_h = 64
    }
    const HeaderInitHeight = document.getElementById('Header_init')
      ? document.getElementById('Header_init').clientHeight
      : 0
    const hedSearchHeight = document.getElementById('hedSearch')
      ? document.getElementById('hedSearch').clientHeight
      : 0
    let height =
      winHeight - layoutHeaders_h - HeaderInitHeight - hedSearchHeight - 80
    if (document.getElementById('order_id')) {
      document.getElementById('order_id').style.height = height + 'px'
    }
  }
  componentDidMount() {
    if (this.state.userinfo.group_permission === 'PERSONAL') {
      Notification.warning({ content: '接口调用仅为企业账户提供！' })
      history.push('/')
      window.location.reload()
      return
    }
    this.getApiInfo()
    window.addEventListener('resize', this.handleResize)
    setTimeout(() => {
      this.handleResize()
    }, 300)
    this.putapp = debounce(this.putapp, 1000)
  }
  componentWillUnmount() {
    localStorage.removeItem('props')
    window.removeEventListener('resize', this.handleResize)
  }
  booWho(bool) {
    return typeof bool === 'boolean'
  }
  ArrayConversion() {
    const { Specification, SpecificationList } = this.state
    Specification.map((item, i) => {
      const Preset_Data = [
        {
          label: '必填',
          value: item.required ? '是' : '否'
        },
        {
          label: '类型',
          value: item.type
        },
        {
          label: '描述',
          value: item.desc
        }
      ]
      let Specification_obj = {
        data: Preset_Data,
        title: item.key
      }
      SpecificationList.push(Specification_obj)
    })
    this.setState({
      SpecificationList: SpecificationList
    })
  }
  setInitialValues(arr) {
    let newArr = {}
    if (arr) {
      arr.forEach((item, i) => {
        if (item.key) {
          let defaultValue = item.default
          if (item.type === 'array') {
            defaultValue = JSON.stringify(defaultValue, null, 2) // 将数组转换为字符串  第三个参数为缩进空格数
          }
          newArr[item.key] = String(defaultValue)
        }
      })
      return newArr
    }
  }
  getApiInfo() {
    const { record, interfaceOption } = this.state
    const selectId = localStorage.getItem('selectApiId')
    newGetappList({ id: record.show_id }).then((res) => {
      if (res.code === 200) {
        const cowrite = []
        const cowriteKey = [
          '简历润色',
          '简历续写',
          '简历扩写',
          '短词成文',
          '简历精炼'
        ]
        res.data.api_list.forEach((item, index) => {
          let api_option = {
            value: '',
            label: '',
            api_id: ''
          }
          api_option.value = index
          api_option.label = item.name
          api_option.api_id = item.api_id
          if (cowriteKey.includes(item.name)) {
            cowrite.push(api_option)
          } else {
            interfaceOption.push(api_option)
          }
        })
        if (cowrite.length > 0) {
          interfaceOption.push({
            value: '0',
            label: '简历辅写',
            api_id: cowrite[0].api_id || 'MpQUT7'
          })
        }
        const selectOption = interfaceOption.find(
          (item) => item.api_id == selectId
        )
        this.setState({
          interfaceOption: interfaceOption,
          api_id: selectId
            ? selectOption.api_id
            : cowrite.length > 0
            ? cowrite[0].api_id
            : res.data.api_list[0].api_id,
          apiValue: selectId
            ? selectOption.label
            : cowrite.length > 0
            ? '简历辅写'
            : res.data.api_list[0].name,
          apiToken: res.data.token
        })
        this.state.apiInfo.app_name = res.data.name
        this.state.apiInfo.app_desc = res.data.desc
        this.getInterfaceParameter()
      } else {
        Notification.warning({ content: res.msg })
        this.setState({
          loadingbody: false
        })
      }
    })
  }
  openInterface(value) {
    const { interfaceOption } = this.state
    // console.log(value,interfaceOption);

    this.setState({
      api_id: interfaceOption[value].api_id,
      apiValue: interfaceOption[value].value,
      newApi_id: interfaceOption[value].api_id
    })
    setTimeout(() => {
      this.getInterfaceParameter()
    }, 400)
  }
  setRequestHeader(params) {
    const {
      postValue,
      paramsLength,
      global_params,
      isPost,
      apiToken,
      request_param
    } = this.state
    let URL = ALLURL.baseURL + postValue
    // 解析 request_param
    const requestParamObj = JSON.parse(request_param)
    let formData = this.getFormRefFieldsValue()
    const updatedRequestParam = this.updateRequestParam(
      formData,
      requestParamObj
    )
    // if(!isPost){
    //   URL += '?'
    //   let isFirstParam = true;
    //   for(let k in params) {
    //     if (!isFirstParam) {
    //       URL += '&';
    //     }
    //     URL += k + '=' + params[k];
    //     isFirstParam = false;
    //   }
    // }
    let headers = {
      method: isPost ? 'POST' : 'GET',
      url: postValue,
      header: {
        // "host": [
        //   ALLURL.baseURL
        // ],
        // "x-scheme": [
        //   "https"
        // ],
        // "connection": [
        //   "close"
        // ],
        // "content-length": [
        //   paramsLength
        // ],
        // "pragma": [
        //   "no-cache"
        // ],
        // "cache-control": [
        //   "no-cache"
        // ],
        // "sec-ch-ua": [
        //   "\"Microsoft Edge\";v=\"113\", \"Chromium\";v=\"113\", \"Not-A.Brand\";v=\"24\""
        // ],
        // "accesstoken": [
        //   global_params.access_token
        // ],
        // "sec-ch-ua-mobile": [
        //   "?0"
        // ],
        Authorization: [apiToken ? ' Bearer ' + apiToken : ''],
        // "user-agent": [
        //   "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/113.0.0.0 Safari/537.36 Edg/113.0.1774.57"
        // ],
        'content-type': [isPost ? ' application/json;charset=UTF-8' : '']
        // "timestamp": [
        //   parseInt(new Date().getTime() / 1000)
        // ],
        // "signature": [
        //   localStorage.getItem('Signature') ? localStorage.getItem('Signature') : ''
        // ],
        // "accesssecret": [
        //   global_params.access_secret
        // ],
        // "appid": [
        //   "RzMKtFCAH5"
        // ],
        // "sec-ch-ua-platform": [
        //   "\"Windows\""
        // ],
        // "accept": [
        //   "*/*"
        // ],
        // "origin": [
        //   ALLURL.baseURL
        // ],
        // "sec-fetch-site": [
        //   "same-site"
        // ],
        // "sec-fetch-mode": [
        //   "cors"
        // ],
        // "sec-fetch-dest": [
        //   "empty"
        // ],
        // "referer": [
        //   ALLURL.baseURL
        // ],
        // "accept-encoding": [
        //   "gzip, deflate, br"
        // ],
        // "accept-language": [
        //   "zh-CN,zh;q=0.9,en;q=0.8,en-GB;q=0.7,en-US;q=0.6"
        // ]
      },
      param: JSON.stringify(updatedRequestParam)
    }
    if (!isPost) {
      delete headers.header['content-type']
    }
    this.setState({
      RequestResponse: headers
    })
  }
  getInterfaceParameter() {
    const { api_id, newApi_id } = this.state

    let params = {
      id: newApi_id ? newApi_id : api_id
      // id: '95YT1z',
    }
    this.setState({
      loadingright: true
    })
    let paramsArray = []
    let SpecificationArray = []
    getAllapiList(params).then((res) => {
      if (res.code === 200) {
        const isPost = res.data.api_params?.post !== undefined
        let getParams = {}
        let requestData = ''
        // 添加一个字段来表示请求类型
        // const requestType = isPost ? 'POST' : 'GET';
        const postDatas = res.data.api_params?.post || res.data.api_params?.get
        if (isPost) {
          requestData =
            postDatas.requestBody.content['application/json'] ||
            postDatas.requestBody.content['multipart/form-data']
        } else {
          requestData = postDatas.parameters
        }

        this.processParameters(postDatas.parameters, SpecificationArray)
        if (isPost) {
          this.processSchemaProperties(
            requestData.schema.properties,
            SpecificationArray,
            requestData.schema.required
          )
          if (!requestData.example) {
            requestData.example = {}
            const newArray = SpecificationArray.filter((item) => {
              return (
                item.title !== 'AccessToken' && item.title !== 'AccessSecret'
              )
            })
            requestData.example[newArray[0].title] = 'xxx'
          }
          this.postProcessExample(
            requestData.example,
            SpecificationArray,
            paramsArray,
            res.data.name
          )
        } else {
          this.getProcessExample(postDatas.parameters, paramsArray)
          paramsArray.forEach((item) => {
            getParams[item.key] = item.default
          })
        }

        this.setState({
          business_parameters: paramsArray,
          initialValues: this.setInitialValues(paramsArray),
          postValue: res.data.api_url,
          request_param: isPost
            ? JSON.stringify(requestData.example)
            : JSON.stringify(getParams),
          // RequestResponseValue: isPost ? postDatas.responses['200'].content['application/json'].examples['1'].value : '',
          RequestResponseValue: '',
          paramsLength: isPost
            ? JSON.stringify(requestData.example).length
            : JSON.stringify(getParams).length,
          SpecificationList: SpecificationArray,
          loadingright: false,
          loadingbody: false,
          isPost: isPost
        })
        this.handleResize()
        this.setRequestHeader(getParams)
      } else {
        Notification.warning({ content: res.msg })
        this.setState({
          loadingright: false
        })
      }
    })
  }

  processParameters(parameters, specificationList) {
    parameters.forEach((item) => {
      const Preset_Data = [
        {
          label: '必填',
          value: item.required ? '是' : '否'
        },
        {
          label: '类型',
          value: item.schema.type
        },
        {
          label: '描述',
          value: item.description
        }
      ]

      const Specification_obj = {
        data: Preset_Data,
        title: item.name
      }

      specificationList.push(Specification_obj)
    })
  }

  processSchemaProperties(properties, specificationList, requestList) {
    Object.keys(properties).forEach((key) => {
      const Preset_Data = [
        {
          label: '必填',
          value: requestList.includes(key) ? '是' : '否'
        },
        {
          label: '类型',
          value: properties[key].type
        },
        {
          label: '描述',
          value: properties[key].description
        }
      ]

      const Specification_obj = {
        data: Preset_Data,
        title: key
      }

      specificationList.push(Specification_obj)

      if (properties[key].type === 'object') {
        this.processNestedProperties(
          properties[key].properties,
          specificationList,
          key,
          properties,
          requestList
        )
      }
    })
  }

  processNestedProperties(
    nestedProperties,
    specificationList,
    parentKey,
    properties,
    requestList
  ) {
    Object.keys(nestedProperties).forEach((key2) => {
      const Preset_Data2 = [
        {
          label: '必填',
          // value: properties[parentKey].required.includes(key2) ? '是' : '否',
          value: requestList.includes(key2) ? '是' : '否'
        },
        {
          label: '类型',
          value: nestedProperties[key2].type
        },
        {
          label: '描述',
          value: nestedProperties[key2].description || `归属上级${parentKey}`
        }
      ]

      const Specification_obj = {
        data: Preset_Data2,
        title: key2
      }

      specificationList.push(Specification_obj)
    })
  }

  getProcessExample(example, businessParameters) {
    example.forEach((item, key3) => {
      // console.log(item);
      const params = {
        default: item.example || '',
        desc: item.description || '',
        key: item.name,
        'type-backend':
          item.schema.type == 'string'
            ? 'text'
            : item.schema.type == 'number'
            ? 'number'
            : 'text',
        type: item.schema.type,
        required: item.required
      }
      businessParameters.push(params)
    })
  }
  postProcessExample(example, SpecificationArray, businessParameters, name) {
    Object.keys(example).forEach((key3) => {
      console.log(example[key3])
      const params = {
        default: example[key3],
        desc: this.getdescript(key3, SpecificationArray),
        key: key3,
        'type-backend': 'text',
        type: this.getSpecificationListDetail(key3, SpecificationArray)
          ?.data?.[1]?.value,
        required:
          this.getSpecificationListDetail(key3, SpecificationArray)?.data?.[0]
            ?.value === '是'
      }

      if (
        this.getSpecificationListDetail(key3, SpecificationArray)?.data?.[1]
          ?.value !== 'object'
      ) {
        businessParameters.push(params)
      } else {
        Object.keys(example[key3]).forEach((key4) => {
          const params2 = {
            default:
              name === '简历美化' && example[key3][key4].data
                ? example[key3][key4].data
                : example[key3][key4],
            desc: '',
            key: key4,
            'type-backend': 'text',
            type:
              name === '简历美化' &&
              this.getSpecificationListDetail(key4, SpecificationArray)
                ?.data?.[1]?.value == 'object'
                ? 'array'
                : this.getSpecificationListDetail(key4, SpecificationArray)
                    ?.data?.[1]?.value,
            required:
              this.getSpecificationListDetail(key4, SpecificationArray)
                ?.data?.[0]?.value === '是'
          }

          businessParameters.push(params2)
        })
      }
    })
  }

  getdescript(key3, SpecificationArray) {
    return (
      SpecificationArray.find((item) => item.title === key3)?.data?.[2]
        ?.value || ''
    )
  }
  getSpecificationListDetail(name, SpecificationArray) {
    // const { SpecificationList } = this.state;
    const detail = SpecificationArray.find((item) => item.title === name)
    return detail
  }
  onChangetitle(type, value) {
    var apiInfo = this.state.apiInfo
    if (type === 'desc') {
      if (value.length > 32) {
        apiInfo.app_desc = value.substring(0, 32)
        Notification.warning({ content: '修改内容超过32个字！' })
        return
      }
      apiInfo.app_desc = value
      this.setState({
        apiInfo: apiInfo
      })
      this.putapp(apiInfo.app_name, value)
    } else if (type === 'name') {
      if (value.length > 10) {
        apiInfo.app_name = value.substring(0, 10)
        Notification.warning({ content: '修改标题超过10个字！' })
        return
      }
      apiInfo.app_name = value
      this.setState({
        apiInfo: apiInfo
      })
      this.putapp(value, apiInfo.app_desc)
    }
  }
  putapp(name, desc) {
    let params = {
      id: this.state.record.show_id
    }
    params.desc = desc
    params.name = name

    newEditappList(params).then((res) => {
      if (res.code === 200) {
      } else {
        Notification.error({ content: res.msg })
      }
    })
  }
  postDebug_Params(params) {
    postDebug(params).then((res) => {
      if (res.code === 200) {
        this.setState({
          RequestResponse: res.data
        })
      } else {
        Notification.warning({ content: res.msg })
      }
    })
  }
  getFormRefFieldsValue() {
    let data = this.formRef.getFieldsValue()
    // ES6-使用js删除对象中带有null和undefined值的数据
    // 遍历 SpecificationList，将布尔类型的值从字符串转换为布尔值
    this.state.SpecificationList.forEach((item) => {
      const { title } = item
      const type = item.data.find((d) => d.label === '类型').value

      if (type === 'boolean' && data[title] !== undefined) {
        data[title] = data[title].toLowerCase() === 'true'
      }
    })

    let obj = Object.fromEntries(
      Object.entries(data).filter(
        ([key, value]) =>
          value !== null &&
          value !== undefined &&
          value !== '' &&
          (typeof value !== 'object' || value.length > 0)
      )
    )
    return obj
  }
  formRefDate() {
    const { record } = this.state
    // ES6-使用js删除对象中带有null和undefined值的数据
    let obj = this.getFormRefFieldsValue()
    obj.app_id = record.id
    let params = obj
    // console.log(obj)
    this.postDebug_Params(params)
  }
  onsetPosition(value) {
    this.setState({
      type: value
    })
    this.state.type = value
  }
  oncopy(value) {
    if (copy(value)) {
      Notification.success({ content: '复制成功!' })
    } else {
      Notification.error({ content: '复制失败!' })
    }
  }
  handleUpload(option) {
    upload(option)
  }
  setFile(Item, fileList, file) {
    this.setState({
      fileList: fileList
    })
    if (file.status === 'done') {
      const newValues = this.getFormRefFieldsValue()
      let key = []
      key.push(file.response.fileKey)
      newValues[Item.key] = file.response.url
      this.setState({
        attachment: key,
        initialValues: newValues
      })
      this.formRef.setFieldsValue(newValues)
    }
  }
  onIconDelete(Item) {
    const newValues = this.getFormRefFieldsValue()
    newValues[Item.key] = ''
    this.setState({
      fileList: null,
      attachment: [],
      initialValues: newValues
    })
    this.formRef.setFieldsValue(newValues)
  }
  gomanagement() {
    history.push('/enginefactory/model/management')
    window.location.reload()
  }
  setTablist = () => {
    const {
      SpecificationList,
      type,
      RequestResponse,
      RequestResponseValue,
      requestBody
    } = this.state
    return type === '接口测试' ? (
      <div className="Tabsbody-ul">
        <div className="tabli">
          <Typography.Text>请求信息</Typography.Text>
          {RequestResponse ? (
            <div className="tabText">
              {requestBody ? (
                <Empty
                  className="Continuation-loding"
                  icon={<img src={Generating_img} />}
                  description={
                    <Typography
                      style={{
                        color: '#4e5969',
                        marginTop: 21,
                        fontSize: 16
                      }}
                    >
                      正在生成中...
                    </Typography>
                  }
                />
              ) : (
                <div className="tabText-overflow">
                  <div>请求方法：{RequestResponse?.method}</div>
                  <div>请求url：{RequestResponse?.url}</div>
                  {/* <div>请求header：</div> */}
                  {Object.keys(RequestResponse.header).map((item, i) => {
                    return (
                      <div key={i}>
                        <span>{item}:</span>
                        <span>{RequestResponse.header[item]}</span>
                      </div>
                    )
                  })}
                  <div>请求参数：{RequestResponse?.param}</div>
                </div>
              )}
            </div>
          ) : (
            <Empty
              className="tabli-none"
              imgSrc={Emptydata_img}
              description={
                <Space direction="vertical">
                  <Typography
                    style={{ color: '#4e5969', marginTop: 30, fontSize: 16 }}
                  >
                    输入参数，获取请求信息
                  </Typography>
                </Space>
              }
            />
          )}
        </div>
        <div className="tabli">
          <Typography.Text>响应数据</Typography.Text>
          {RequestResponseValue ? (
            <div className="tabText">
              {requestBody ? (
                <Empty
                  className="Continuation-loding"
                  icon={<img src={Generating_img} />}
                  description={
                    <Typography
                      style={{
                        color: '#4e5969',
                        marginTop: 21,
                        fontSize: 16
                      }}
                    >
                      正在生成中...
                    </Typography>
                  }
                />
              ) : (
                <div
                  className="tabText-overflow"
                  dangerouslySetInnerHTML={{
                    __html: JSON.stringify(RequestResponseValue)
                  }}
                ></div>
              )}
            </div>
          ) : requestBody ? (
            <Empty
              className="Continuation-loding"
              icon={<img src={Generating_img} />}
              description={
                <Typography
                  style={{
                    color: '#4e5969',
                    marginTop: 21,
                    fontSize: 16
                  }}
                >
                  正在生成中...
                </Typography>
              }
            />
          ) : (
            <Empty
              className="tabli-none"
              imgSrc={Emptydata_img}
              description={
                <Space direction="vertical">
                  <Typography
                    style={{ color: '#4e5969', marginTop: 30, fontSize: 16 }}
                  >
                    输入参数，获取响应数据
                  </Typography>
                </Space>
              }
            />
          )}
        </div>
      </div>
    ) : type === '签名规范' ? (
      <div>
        <div className="tabli">
          <Typography.Text>js实现版</Typography.Text>
          <Empty
            className="tabli-none"
            imgSrc={Emptydata_img}
            description={
              <Space direction="vertical">
                <Typography
                  style={{ color: '#4e5969', marginTop: 30, fontSize: 16 }}
                >
                  输入参数，获取请求信息
                </Typography>
              </Space>
            }
          />
        </div>
        <div className="tabli">
          <Typography.Text>php实现版</Typography.Text>
          <Empty
            className="tabli-none"
            imgSrc={Emptydata_img}
            description={
              <Space direction="vertical">
                <Typography
                  style={{ color: '#4e5969', marginTop: 30, fontSize: 16 }}
                >
                  输入参数，获取请求信息
                </Typography>
              </Space>
            }
          />
        </div>
      </div>
    ) : type === '参数说明' ? (
      <div className="description">
        {SpecificationList.map((item, i) => {
          return (
            <Descriptions
              key={i}
              column={1}
              colon=" :"
              layout="inline-horizontal"
              title={item.title}
              data={item.data}
            />
          )
        })}
      </div>
    ) : null
  }
  getCheckResource() {
    this.formRef
      .validate()
      .then(() => {
        const {
          postValue,
          request_param,
          paramsLength,
          isPost,
          RequestResponse
        } = this.state
        // 解析 request_param
        const requestParamObj = JSON.parse(request_param)
        let postApi = null
        this.setState({
          requestBody: true
        })
        if (isPost) {
          postApi = localAxios('post', postValue)
        } else {
          postApi = localAxios('get', postValue)
        }
        let formData = this.getFormRefFieldsValue()
        const updatedRequestParam = this.updateRequestParam(
          formData,
          requestParamObj
        )
        const paramslength = JSON.stringify(updatedRequestParam).length
        let isForm = false
        if (postValue === '/resumes/cv-recognize') {
          formData = new FormData()
          formData.append('file', this.state.fileList[0].originFile)
          isForm = true
        }
        if (postValue === '/apps/ppt-note') {
          formData.notes = JSON.parse(formData.notes)
        }
        // postApi(isForm ? formData : updatedRequestParam).then(res=>{
        postApi(formData).then((res) => {
          this.setState({
            RequestResponseValue: res,
            requestBody: false
          })
          if (!isForm) {
            this.setState({
              RequestResponse: {
                ...RequestResponse,
                param: JSON.stringify(formData)
              }
            })
          }
        })
        this.setRequestHeader(request_param)
        this.setState({
          paramsLength: paramslength
        })
      })
      .catch((e) => {
        Message.error('必填项不能为空')
        // console.log(e.errors)
      })

    return
    // 更新 requestParamObj
  }
  deepMerge(target, source) {
    for (const key in source) {
      if (source.hasOwnProperty(key)) {
        if (
          typeof source[key] === 'object' &&
          source[key] !== null &&
          !Array.isArray(source[key])
        ) {
          if (
            !target[key] ||
            typeof target[key] !== 'object' ||
            Array.isArray(target[key])
          ) {
            target[key] = {}
          }
          this.deepMerge(target[key], source[key])
        } else {
          target[key] = source[key]
        }
      }
    }
    return target
  }
  // 更新 requestParamObj 的值
  updateRequestParam(initialValues, requestParamObj) {
    // 更新基本字段
    // console.log(initialValues, requestParamObj);
    // 遍历 initialValues 的每个键值对
    Object.keys(initialValues).forEach((key) => {
      const value = initialValues[key]

      if (typeof value === 'object' && value !== null) {
        // 特殊处理对象或数组
        if (!requestParamObj[key]) {
          requestParamObj[key] = {}
        }
        this.deepMerge(requestParamObj[key], value)
      } else {
        // 直接赋值
        requestParamObj[key] = value
      }
    })

    return requestParamObj
  }

  setvisibleUpgrade(value) {
    this.setState({
      visibleUpgrade: value
    })
  }
  openWiki() {
    window.open(
      'https://wiki.yoo-ai.com/#/wernicke/api/?id=' +
        this.state.model_capability_name
    )
    console.log('this.state.model_capability_name', this.state)
  }
  openResources() {
    history.push('/myapp/calldetails')
    window.location.reload()
  }
  refreshToken() {
    newRefreshToken({ id: this.state.record.show_id }).then((res) => {
      if (res.code === 200) {
        this.setState({
          apiToken: res.data.token
        })
        Notification.success({ content: '刷新成功' })
        // window.location.reload();
      } else {
        Notification.error({ content: res.msg })
      }
    })
  }
  render() {
    const {
      loadingbody,
      Tablist,
      SearchValue,
      type,
      userinfo,
      fileList,
      url,
      business_parameters,
      global_params,
      initialValues,
      visibleUpgrade,
      upgradetips,
      record,
      interfaceOption,
      postData,
      postValue,
      apiValue,
      apiInfo,
      apiToken,
      isPost,
      loadingright,
      visible
    } = this.state
    return (
      <div className="interfacetest">
        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <>
            <div className="Header-init" id="Header_init">
              <div className="Header-title">接口调用</div>
            </div>
            <div className="edit-title">
              <div className="Header-init">
                <div className="Header-title">
                  <Typography.Paragraph
                    className="Header-uptitle"
                    editable={
                      apiInfo.type === 0
                        ? null
                        : {
                            onChange: this.onChangetitle.bind(this, 'name')
                          }
                    }
                  >
                    {apiInfo.app_name}
                  </Typography.Paragraph>
                </div>
              </div>
              <div className="Header-upParagraph">
                <Typography.Paragraph
                  className="Header-uptitle"
                  editable={
                    apiInfo.type === 0
                      ? null
                      : {
                          onChange: this.onChangetitle.bind(this, 'desc')
                        }
                  }
                >
                  {apiInfo.app_desc}
                </Typography.Paragraph>
              </div>
            </div>
            <div className="GroupTable-fixe" id="hedSearch">
              <Radio.Group
                type="button"
                name="direction"
                value={type}
                onChange={this.onsetPosition.bind(this)}
                options={Tablist}
                className="GroupTable"
              ></Radio.Group>
              <div>
                <Space size={'large'}>
                  {/* <Space align={'center'}>
                    <Typography.Text className="interfacetest-fixetitle">
                      请求方法：POST
                    </Typography.Text>
                    <div className="interfacetest-fixetitle interfacetest-api">
                      接口地址：
                      <Typography.Paragraph copyable>
                        {SearchValue}
                      </Typography.Paragraph>
                    </div>
                  </Space> */}
                  <Space align={'center'} size={'medium'}>
                    <Button
                      shape="round"
                      className="primary-white custom-White-button"
                      onClick={this.openWiki.bind(this)}
                    >
                      API文档
                    </Button>
                  </Space>
                  <Space align={'center'} size={'medium'}>
                    <Button
                      shape="round"
                      className="primary-white custom-White-button"
                      onClick={this.openResources.bind(this)}
                    >
                      调用明细
                    </Button>
                  </Space>
                  <Space align={'center'} size={'medium'}>
                    {/* <Button
                      shape="round"
                      className="primary-white custom-White-button"
                      onClick={this.refreshToken.bind(this)}
                    >
                      刷新Token
                    </Button> */}
                    <Trigger
                      popupVisible={visible}
                      position="br"
                      popupAlign={{
                        bottom: 15
                      }}
                      popup={() => (
                        <div className="aigcSetting">
                          <div
                            className="setting-item"
                            style={{ marginBottom: '0' }}
                          >
                            Token
                            <div className="primary_model_name primary_model_name_aigc primary_model_token_aigc">
                              <div className="primary_model_name_fix">
                                <span className="primary_model_name_title">
                                  <Divider type="vertical" />
                                </span>
                                <Typography.Paragraph ellipsis copyable>
                                  {apiToken}
                                </Typography.Paragraph>
                              </div>
                            </div>
                            <div style={{ width: '100%', textAlign: 'right' }}>
                              <Button
                                shape="round"
                                className="primary-white refesh_token_btn"
                                onClick={this.refreshToken.bind(this)}
                              >
                                刷新
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                      trigger="click"
                      classNames="zoomInTop"
                      onVisibleChange={(visible) => {
                        this.setState({ visible: visible })
                      }}
                    >
                      <div className="setting_primary_model_name">
                        <IconSettings /> Token管理
                      </div>
                    </Trigger>
                  </Space>
                </Space>
              </div>
            </div>
            <div id="order_id">
              <Layout style={{ height: '100%' }}>
                <Content
                  className={`Work-Content ${
                    type === `接口测试` ? `` : `set-margins`
                  }`}
                >
                  <div className="Tabsbody">{this.setTablist()}</div>
                </Content>
                <Sider className="Sider-left">
                  <div className="debugg-sider debugg-slider-setting">
                    <div className="debugg-sider-input tabText-overflow">
                      {loadingright ? (
                        <Spin />
                      ) : (
                        <Form
                          style={{ width: '100%' }}
                          ref={(ref) => (this.formRef = ref)}
                          layout="vertical"
                          initialValues={initialValues}
                        >
                          <FormItem className="FormItem-debugg-title">
                            <div className="debugg-title">接口信息</div>
                          </FormItem>
                          <FormItem label="接口选项" field="access_token">
                            {interfaceOption.length >= 1 ? (
                              <>
                                <Select
                                  placeholder="请选择接口"
                                  value={apiValue}
                                  onChange={(value) =>
                                    this.openInterface(value)
                                  }
                                  options={interfaceOption}
                                ></Select>
                              </>
                            ) : (
                              <>
                                <div className="debugg-title">暂无接口</div>
                              </>
                            )}
                          </FormItem>
                          <FormItem
                            label={isPost ? 'Post接口' : 'Get接口'}
                            field="access_token"
                          >
                            <Space>
                              <Input
                                placeholder=""
                                value={postValue}
                                readOnly
                                className="readOnly"
                              />
                              <Button
                                className="Form-btnicon"
                                shape="circle"
                                icon={<IconCopy />}
                                onClick={this.oncopy.bind(this, postValue)}
                              />
                            </Space>
                          </FormItem>
                        </Form>
                      )}
                    </div>
                  </div>

                  <div className="debugg-sider">
                    <div className="debugg-sider-input tabText-overflow">
                      {loadingright ? (
                        <Spin />
                      ) : (
                        <Form
                          style={{ width: '100%' }}
                          ref={(ref) => (this.formRef = ref)}
                          layout="vertical"
                          initialValues={initialValues}
                        >
                          {/* <FormItem className="FormItem-debugg-title">
                          <div className="debugg-title">全局参数</div>
                        </FormItem>
                        <FormItem label="Token" field="token">
                          <Space>
                            <Input
                              placeholder=""
                              value={apiToken}
                              readOnly
                              className="readOnly"
                            />
                            <Button
                              className="Form-btnicon"
                              icon={<IconCopy />}
                              onClick={this.oncopy.bind(
                                this,
                                apiToken
                              )}
                            />
                          </Space>
                        </FormItem> */}
                          {/* <FormItem label="AccessSecret" field="access_secret">
                          <Space>
                            <Input
                              placeholder=""
                              value={global_params.access_secret}
                              readOnly
                              className="readOnly"
                            />
                            <Button
                              className="Form-btnicon"
                              icon={<IconCopy />}
                              onClick={this.oncopy.bind(
                                this,
                                global_params.access_secret
                              )}
                            />
                          </Space>
                        </FormItem> */}
                          {/* <FormItem label="AccessToken" field="access_token">
                          <Space>
                            <Input
                              placeholder=""
                              value={global_params.access_token}
                              readOnly
                              className="readOnly"
                            />
                            <Button
                              className="Form-btnicon"
                              shape="circle"
                              icon={<IconCopy />}
                              onClick={this.oncopy.bind(
                                this,
                                global_params.access_token
                              )}
                            />
                          </Space>
                        </FormItem> */}

                          {/* <FormItem className="FormItem-debugg-title">
                          <div className="debugg-title">接口信息</div>
                        </FormItem>
                        <FormItem label="接口选项" field="access_token">
                        {
                          interfaceOption.length >= 1 ?
                          <>
                            <Select
                            placeholder="请选择接口"
                            value={apiValue}
                            onChange={(value) =>
                              this.openInterface(value)
                              }
                              options={interfaceOption}
                            ></Select>
                          </>
                          : <><div className="debugg-title">暂无接口</div></>
                        }
                     
                        </FormItem>
                        <FormItem label={isPost ? "Post接口" : "Get接口"} field="access_token">
                          <Space>
                            <Input
                              placeholder=""
                              value={postValue}
                              readOnly
                              className="readOnly"
                            />
                            <Button
                              className="Form-btnicon"
                              shape="circle"
                              icon={<IconCopy />}
                              onClick={this.oncopy.bind(
                                this,
                                global_params.access_token
                              )}
                            />
                          </Space>
                        </FormItem> */}

                          <FormItem className="FormItem-debugg-title">
                            <div className="debugg-title">业务参数</div>
                          </FormItem>
                          {business_parameters.length >= 1 ? (
                            business_parameters?.map((Item, Item_index) => {
                              return (
                                <div key={Item_index}>
                                  {Item['type-backend'] === 'text' &&
                                  Item.key !== 'fileKey' &&
                                  Item.type !== 'array' &&
                                  Item.key !== 'file' ? (
                                    <>
                                      <FormItem
                                        label={Item.key}
                                        field={Item.key}
                                        rules={[
                                          {
                                            required: Item.required
                                              ? true
                                              : false,
                                            message: '此项为必填项'
                                          }
                                        ]}
                                      >
                                        <Input placeholder={Item.desc} />
                                      </FormItem>
                                    </>
                                  ) : Item['type-backend'] === 'text' &&
                                    Item.type == 'array' ? (
                                    <>
                                      <FormItem
                                        label={Item.key}
                                        field={Item.key}
                                        rules={[
                                          {
                                            required: Item.required
                                              ? true
                                              : false,
                                            message: '此项为必填项'
                                          }
                                        ]}
                                      >
                                        <TextArea
                                          autoSize
                                          placeholder={Item.desc}
                                        />
                                      </FormItem>
                                    </>
                                  ) : Item['type-backend'] === 'number' ? (
                                    <>
                                      <FormItem
                                        label={Item.key}
                                        field={Item.key}
                                        rules={[
                                          {
                                            required: Item.required
                                              ? true
                                              : false,
                                            type: 'number',
                                            message: '此项为必填项'
                                          }
                                        ]}
                                      >
                                        <InputNumber
                                          min={0}
                                          placeholder={Item.desc}
                                          hideControl
                                        />
                                      </FormItem>
                                    </>
                                  ) : Item['type-backend'] === 'select' ? (
                                    <>
                                      <FormItem
                                        label={Item.key}
                                        field={Item.key}
                                        rules={[
                                          {
                                            required: Item.required
                                              ? true
                                              : false,
                                            message: '此项为必填项'
                                          }
                                        ]}
                                      >
                                        <Select
                                          allowClear
                                          placeholder={Item.desc}
                                          options={Item.select_backend}
                                          getPopupContainer={(triggerNode) =>
                                            triggerNode.parentNode
                                          }
                                        ></Select>
                                      </FormItem>
                                    </>
                                  ) : (Item['type-backend'] === 'text' &&
                                      Item.key === 'fileKey') ||
                                    Item.key === 'file' ? (
                                    <Space>
                                      <Form.Item
                                        label={Item.key}
                                        field={Item.key}
                                        rules={[
                                          {
                                            required: Item.required
                                              ? true
                                              : false,
                                            message: '此项为必填项'
                                          }
                                        ]}
                                      >
                                        <Input
                                          placeholder={Item.desc}
                                          readOnly
                                          className="readOnly"
                                        />
                                      </Form.Item>
                                      <div className="field_upload">
                                        {initialValues[Item.key] ? (
                                          <Button
                                            icon={<IconDelete />}
                                            className="Form-btnicon"
                                            onClick={this.onIconDelete.bind(
                                              this,
                                              Item
                                            )}
                                          />
                                        ) : (
                                          <Upload
                                            multiple
                                            limit="1"
                                            showUploadList={false}
                                            fileList={fileList}
                                            accept=""
                                            onChange={this.setFile.bind(
                                              this,
                                              Item
                                            )}
                                            customRequest={(option) => {
                                              this.handleUpload(option)
                                            }}
                                            onExceedLimit={() => {
                                              Message.warning(
                                                '超过上传数量限制！最多上传1个'
                                              )
                                            }}
                                          >
                                            <Button
                                              icon={<IconUpload />}
                                              className="Form-btnicon"
                                            />
                                          </Upload>
                                        )}
                                      </div>
                                    </Space>
                                  ) : Item['type-backend'] === 'textarea' ? (
                                    <>
                                      <FormItem
                                        label={Item.key}
                                        required={Item.required ? true : false}
                                        field={Item.key}
                                        rules={[
                                          {
                                            required: Item.required
                                              ? true
                                              : false,
                                            message: '此项为必填项'
                                          }
                                        ]}
                                      >
                                        <TextArea placeholder={Item.desc} />
                                      </FormItem>
                                    </>
                                  ) : null}
                                </div>
                              )
                            })
                          ) : (
                            <Empty
                              className="tabli-none"
                              imgSrc={Emptydata_img}
                              description={
                                <Space direction="vertical">
                                  <Typography
                                    style={{
                                      color: '#4e5969',
                                      marginTop: 30,
                                      fontSize: 16
                                    }}
                                  >
                                    参数空空如也~
                                  </Typography>
                                </Space>
                              }
                            />
                          )}
                        </Form>
                      )}
                    </div>
                  </div>

                  <div className="request_button">
                    <Divider />
                    <Button
                      type="primary"
                      className="Initiate-request"
                      onClick={this.getCheckResource.bind(this)}
                    >
                      发起请求
                    </Button>
                  </div>
                </Sider>
              </Layout>
            </div>
          </>
        )}
        <UpgradeTips
          visible={visibleUpgrade}
          notification_status={upgradetips?.notice_type}
          notification_message={upgradetips?.message}
          model_capability={upgradetips?.model_capability}
          onCancel={() => this.setvisibleUpgrade(false)}
        ></UpgradeTips>
      </div>
    )
  }
}

export default Interfacetest
