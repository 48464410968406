import React, { Component } from "react";
import {
  Table,
  Empty,
  Card,
  Button,
  Tag,
  Modal,
  Form,
  Radio,
  Checkbox,
  Input,
  Notification,
  Link,
  Steps,
  Typography,
  Spin,
  Select,
} from "@arco-design/web-react";
import { formatMoney, hidePhone } from "common/utils";
import history from "common/utils/history";
import {
  getApilog,
  getbalance,
} from "api/apis";
import Emptydata_img from "asserts/Emptydata_img.svg";
import "./index.scss";
const Option = Select.Option;

class InvoiceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingbody: true,
      data: null,
      userinfo:JSON.parse(localStorage.getItem("userinfo")),
      yearOptions:["2021","2022","2023","2024"],
      monthOptions:["01","02","03","04","05","06","07","08","09","10","11","12"],
      currentYear:null,
      currentMonth:null,
      timeId:null,
      disableIndex:null,
      joinYear:null,
      joinMonth:null,
      selectYears:null,
      selectMonths:null,
      DateText:'',
      currentDate: '',
      timeHorizon: "",
      balance:'0.00',
      credit_limit:'0.00',
      give_limit:'.00',
      available_limit:"0",
      loading: false,
      no_data: false,
      page_no_data:false,
      page_data:[],
      InvoiceTypeOptions: {
        1: "增值税普通发票",
        2: "增值税专用发票",
      },
      columns: [
        {
          title: "产品名称",
          dataIndex: "model_capability",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
            height: 68,
          },
        },

        // {
        //   title: "接口ID",
        //   dataIndex: "measure",
        //   align: "center",
        //   headerCellStyle: {
        //     textAlign: "center",
        //   },
        // },
        {
          title: "数量",
          dataIndex: "num",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => <span>{formatMoney(col)}</span>,
        },
        {
          title: "单价",
          dataIndex: "price",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => <span>￥{formatMoney(col)}</span>,
        },
        {
          title: "总金额",
          dataIndex: "total_price",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => <span>￥{formatMoney(col)}</span>,
        },
      ], 
      columns2: [
        {
          title: "产品名称",
          dataIndex: "title",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
            height: 68,
          },
        },
        {
          title: "数量",
          dataIndex: "num",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => <span>{formatMoney(col)}</span>,
        },
        {
          title: "单价",
          dataIndex: "price",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => <span>￥{formatMoney(col)}</span>,
        },
        {
          title: "总金额",
          dataIndex: "total_price",
          align: "center",
          headerCellStyle: {
            textAlign: "center",
          },
          render: (col, record, index) => <span>￥{formatMoney(col)}</span>,
        },
      ], 
      record:
        localStorage.getItem("record") &&
        JSON.parse(localStorage.getItem("record")),
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.setDate();
    this.updateTime();
  }
  componentWillUnmount(){
    clearInterval(this.state.timeId)
  }
  UNSAFE_componentDidUpdate() {}
  getinit() {
    this.getinvoice_list();
  }
  getinvoice_list() {
    const { selectYears, selectMonths} = this.state;
    // let date = this.getPreviousMonth();
    let date = selectYears + '-' + selectMonths;
    this.setState({
      currentDate:this.getCurrentTime(),
      timeHorizon:this.getMonthRange(),
    })
    getApilog({ date }).then((res) => {
      if (res.code === 200) {
        let list = res.data;
        let allList = [];
        let allPrice = 0; //总费用
        for (let i in list.record) {
          if(!Array.isArray(list.record[i])){
            list.record[i].list.forEach((item) => {
              allList.push(item);
            })
            allPrice += list.record[i].all_price
          }
        }
        // console.log(allList);
        this.setState({
          // data: list?.record ? list.record['REAL-TIME'].list : [],
          data: allList,
          page_data: list.package,
          balance: list.amount.used_amount, //充值余额
          give_limit: list.amount.gift_amount, //赠送额度
          credit_limit: list.amount.credit_amount, //信用额度
          available_limit: list.amount.amount, //总费用
          loading: false,
          loadingbody: false,
          no_data: allList.length > 0 ? false : true,
          page_no_data: list.package.list.length > 0 ? false : true,
        });
      } else {
        this.setState({
          loading: false,
          loadingbody: false,
          no_data: true,
        });
      }
    });
  }
  updateTime(){
   this.state.timeId = setInterval(() => {
    this.setState({
      currentDate:this.getCurrentTime(),
    })
   },1000)
  }
   getPreviousMonth() {
    const now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth();
  
    // 如果当前月份是1月，则回到上一年的12月
    if (month === 0) {
      year -= 1;
      month = 11; // 11代表12月
    } else {
      month -= 1; // 否则直接减1
    }
  
    const formattedMonth = String(month + 1).padStart(2, '0'); // 补零
    return `${year}-${formattedMonth}`;
  }
  
   getCurrentTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${year}年${month}月${day}日${hours}:${minutes}:${seconds}`;
  }
  
   getMonthRange() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() - 1; // 获取当前月份，注意月份是从0开始计数的
    const firstDay = new Date(year, month, 1); // 当前月份的第一天
    const lastDay = new Date(year, month + 1, 0); // 当前月份的最后一天
    const formattedFirstDay = `${year}年${String(month + 1).padStart(2, '0')}月01日`;
    const formattedLastDay = `${year}年${String(month + 1).padStart(2, '0')}月${String(lastDay.getDate()).padStart(2, '0')}日`;
    return `${formattedFirstDay}-${formattedLastDay}`;
  }
  checkTime(year, month){
     // 确保月份是从0开始计数的
     month -= 1;
     const firstDay = new Date(year, month, 1); // 指定月份的第一天
     const lastDay = new Date(year, month + 1, 0); // 指定月份的最后一天
     const formattedFirstDay = `${year}年${String(month + 1).padStart(2, '0')}月01日`;
     const formattedLastDay = `${year}年${String(month + 1).padStart(2, '0')}月${String(lastDay.getDate()).padStart(2, '0')}日`;
     return `${formattedFirstDay}-${formattedLastDay}`;
  }
  setDate(){
    const { userinfo,yearOptions,monthOptions,selectYears,selectMonths } = this.state;
    const join_year = userinfo.join_time.split('-')[0] - 2;
    const join_month = userinfo.join_time.split('-')[1];
    
     // 获取当前年份
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // 获取当前月份


  // 生成从 join_year 到当前年的所有年份
      const newYearOptions = [];
      for (let year = parseInt(join_year); year <= currentYear; year++) {
        newYearOptions.push(year);
      }
      this.setState({
        yearOptions:newYearOptions,
        disableIndex:(selectYears || currentYear) == join_year ? join_month - 1 : -1,
        joinYear:join_year,
        joinMonth:join_month,
        selectYears:currentYear,
        currentYear:currentYear,
        selectMonths:currentMonth,
        currentMonth:currentMonth,
        DateText: this.checkTime(currentYear,currentMonth)
      },()=>{
        this.getinit();
      })
  }
  //年份筛选
  selectYear(value){   
    const { selectMonths, currentYear, currentMonth,  joinYear, joinMonth } = this.state; 
    this.setState({
      selectYears: value,
      // selectMonths: value == currentYear ? currentMonth : value == joinYear ? joinMonth - 1 : '01',
      disableIndex: value == joinYear ? joinMonth - 1 : -1,
      DateText: this.checkTime(value,selectMonths)
    },()=>{
      this.getinvoice_list();
    })
  }
  //月份筛选
  selectMonth(value){   
    const { selectYears} = this.state; 
    this.setState({
      selectMonths:value,
      DateText: this.checkTime(selectYears,value)
    },()=>{
      this.getinvoice_list();
    })
  }
  formRefDate() {
    this.formRef
      .validate()
      .then((res) => {
        this.setState({
          confirmLoading: true,
          isedit: false,
        });
        let obj = res;
      })
      .catch((error) => {
        let errorslist = error.errors;
        let message = "";
        Object.keys(errorslist ? errorslist : []).map((item, i) => {
          if (errorslist[item].requiredError && i === 0) {
            message = errorslist[item].message;
          }
        });
        Notification.error({ content: message });
      });
  }
  formRefcancel() {
    this.formRef.resetFields();
  }
  formRefedit() {
    this.setState({
      isedit: true,
    });
  }

  onViewDetails() {}
  render() {
    const {
      loadingbody,
      Modalvisible,
      loading,
      columns,
      columns2,
      no_data,
      data,
      balance,
      credit_limit,
      give_limit,
      available_limit,
      currentDate,
      timeHorizon,
      page_no_data,
      page_data,
      yearOptions,
      monthOptions,
      disableIndex,
      DateText,
      selectYears,
      selectMonths,
      currentYear,
      currentMonth,
    } = this.state;
    return (
      <div className="payment-detail">
        <div className="Header-init">
          <div className="Header-title">费用账单</div>
        </div>
        {loadingbody ? (
          <Spin loading={loadingbody} dot className="loadingbody"></Spin>
        ) : (
          <>
            {/* {no_data ? (
              ""
            ) : ( */}
              
              <div style={{ marginTop: 30 }}>
                <div className="invoicedetail-card-head">
                  <div className="card-head-left">{currentDate}</div>
                  <div className="card-head-right">
                    <div>
                      <Select className="yearSelect" placeholder='请选择年份' style={{ width: 85 }} defaultValue={currentYear} onChange={this.selectYear.bind(this)}>
                        {yearOptions.map((option, index) => (
                          <Option key={option} value={option} style={{textAlign: 'center'}}>
                            {option}
                          </Option>
                        ))}
                      </Select>
                      <Select className="monthSelect" placeholder='请选择月份' style={{ width: 65 }} defaultValue={currentMonth} onChange={this.selectMonth.bind(this)}>
                        {monthOptions.map((option, index) => (
                          <Option key={option} disabled={ currentYear == selectYears ? index >= currentMonth : index <= disableIndex} value={option}>
                            {option}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className="">{DateText}</div>
                    <div className="card-right-descript">接口调用月结</div>
                  </div>
                </div>
                <div className="Table-card">
                  <Table
                    virtualized
                    noDataElement={
                      <Empty
                        imgSrc={Emptydata_img}
                        description={currentYear == selectYears  && currentMonth == selectMonths ? "本月消费记录已生成，账单将于次月1日出账，请您耐心等候" : '暂无相关数据'}
                      />
                    }
                    no_data={no_data}
                    border={{ wrapper: false, cell: false }}
                    loading={loading}
                    columns={columns}
                    data={data}
                    pagination={false}
                  />
                  <div className="total-money-detail">
                    <div className="total-money-detail-balance">
                      <div>充值金额</div><div className="digitization">￥{formatMoney(balance)}</div>
                      </div>
                    <div className="total-money-detail-credit_limit">
                      <div>赠送金额</div><div className="digitization">￥{formatMoney(give_limit)}</div>
                      </div>
                    <div className="total-money-detail-give_limit">
                      <div>信用额度</div><div className="digitization">￥{formatMoney(credit_limit)}</div>
                      </div>
                  </div>
                  <div className="total-money-content">
                    <div className="total-money-detail-available_limit">
                      <div>总费用</div><div className="digitization">￥{formatMoney(available_limit)}</div>
                      </div>
                  </div>
                </div>
              </div>
              
            {/* )} */}

            {/* {no_data ? (
              ""
            ) : ( */}

              {/* <div style={{ marginTop: 30 }}>
                <div className="invoicedetail-card-head">
                  <div className="card-head-left">{currentDate}</div>
                  <div className="card-head-right">
                    <div className="card-right-descript">资源包购买</div>
                  </div>
                </div>
                <div className="Table-card">
                  <Table
                    virtualized
                    noDataElement={
                      <Empty
                        imgSrc={Emptydata_img}
                        description={"本月消费记录已生成，账单将于次月1日出账，请您耐心等候"}
                      />
                    }
                    no_data={page_no_data}
                    border={{ wrapper: false, cell: false }}
                    loading={loading}
                    columns={columns2}
                    data={page_data.list}
                    pagination={false}
                  />
                  <div className="total-money-content">
                    <div className="total-money-detail-available_limit">
                      <div>总费用</div><div className="digitization">￥{formatMoney(page_data.total_price)}</div>
                      </div>
                  </div>
                </div>
              </div> */}
              
            {/* )} */}


          </>
        )}

      </div>
    );
  }
}
export default InvoiceDetail;
