import React, { Component } from 'react'
import {
  Avatar,
  Layout,
  Typography,
  Button,
  Space,
  Notification,
  Message,
  Empty,
  Select,
  Upload,
  Card,
  Modal,
  Divider,
} from '@arco-design/web-react'
import { IconDownload, IconDelete, IconPlus } from '@arco-design/web-react/icon'
import Emptydata_img from 'asserts/Emptydata_img.svg'
import Generating_img from 'asserts/Generating_img.gif'
import errorIcon from 'asserts/error_icon.svg'
import docx_icon from 'asserts/docx_icon.png'
import pdf_icon from 'asserts/pdf_icon.png'
import {
  getApiInfo,
  getRequest,
  putapp,
  putmodelversion,
  check_resource,
  resumesAnalyze,
  resumesInfo,
  newGetappList,
} from 'api/apis'
import upload from 'api/uploads'
import { CreateLink } from 'common/utils/business'
import history from 'common/utils/history'
import { debounce, b2ValueUnit } from 'common/utils'
import { sourceCancelClass } from 'api/cancel-request'
import UpgradeTips from 'components/UpgradeTips'
import $ from 'jquery'
import './semanticprototype.scss'
const Sider = Layout.Sider
const Content = Layout.Content
const Option = Select.Option

// 封装组件
class SemanticPrototype extends Component {
  constructor(props) {
    super(props)
    this.state = {
      optionVlue: '',
      TextAreaValue: '',
      versions: [],
      resumeTimeId:null,
      iframeUrl: process.env.REACT_APP_ENV === 'development' ? "https://test.www.yoojober.com/" : "https://www.yoojober.com/",
      chatpptSrc: '',
      ParagraphContinuation: [ ],
      ContinuationLoding: false,
      record: JSON.parse(localStorage.getItem('record')),
      apiInfo: {
        model_id: '',
      },
      number: 6,
      requestMsg: '',
      requestMsgText: '您还未上传简历',
      attachment: [],
      fileList: [],
      visible: false,
      visibleimg: null,
      loading: false,
      setChangeAbatchTime: '',
      setRequestTime: '',
      visibleUpgrade: false,
      upgradetips: null,
      identity: JSON.parse(localStorage.getItem('userinfo'))
        ? JSON.parse(localStorage.getItem('userinfo')).group_permission
        : '',
    }
    this.isputapp = debounce(this.isputapp, 1000)
  }
  componentDidMount() {
    this.getApiInfo()
    window.addEventListener('resize', this.handleResize)
    setTimeout(() => {
      this.handleResize()
    }, 300)
  }
  componentWillUnmount() {
    localStorage.removeItem('props')
    window.removeEventListener('resize', this.handleResize)
    clearTimeout(this.state.setRequestTime)
    clearTimeout(this.state.setChangeAbatchTime)
    sourceCancelClass()
  }
  handleResize = () => {
    var winHeight = 0
    if (window.innerHeight) {
      winHeight = window.innerHeight
    } else if (document.body && document.body.clientHeight) {
      winHeight = document.body.clientHeight
    }
    if (document.documentElement && document.documentElement.clientHeight) {
      winHeight = document.documentElement.clientHeight
    }
    let layoutHeaders_h = document.getElementById('layoutHeaders').clientHeight
    if (layoutHeaders_h > winHeight) {
      layoutHeaders_h = 64
    }
    let height =
      winHeight -
      layoutHeaders_h -
      document.getElementById('renderHeaders').clientHeight -
      32 -
      45
    document.getElementById('layout_id').style.height = height + 'px'

    if (document.getElementById('continuation')) {
      let height2 =
        document.getElementById('result').clientHeight -
        document.getElementById('result_title').clientHeight -
        34
      document.getElementById('continuation').style.height = height2 - 30 + 'px'
      this.initSlider()
    }
  }
  initSlider() {
    var screenWidth = $('.Continuation-item').width() //浏览器页面的宽度
    var height = screenWidth * 0.72 //14.2
    this.setState({
      height: height,
    })
  }
  getApiInfo(iseditor) {
    let params = {
      id: this.state.record.show_id,
    }
    newGetappList(params).then((res) => {
      if (res.code === 200) {
        let list = res.data
        if (res.data.model_params) {
          history.push('/myapp/enteringGinseng')
          history.go()
        } else {
          if (iseditor) {
            this.setState({
              apiInfo: list,
            })
          } else {
            this.setState({
              apiInfo: list,
            })
          }
        }
        //console.log(res.data, "api服务");
      } else {
      }
    })
  }
  putmodelversion(optionVlue) {
    let params = {
      id: optionVlue,
    }
    putmodelversion(params).then((res) => {
      if (res.code === 200) {
        let list = res.data
      } else {
      }
    })
  }
  onsetPosition(value) {
    this.setState({
      optionVlue: value,
    })
    this.putmodelversion(value)
  }
  handleUpload(option) {
    upload(option)
  }
  setFile(fileList, file) {
    this.setState({
      fileList: fileList,
    })
    if (file.status === 'done') {
      let key = []
      let fileList = this.state.fileList
      fileList.map((item, index) => {
        key.push(item.response.url)
      })
      this.setState({
        attachment: key,
        fileList: fileList,
      })
    }
  }
  beforeUpload(file) {
    var AllImgExt = '.jpg|.pdf|.png|.docx|.doc|.jpeg'
    var extName = file.name.substring(file.name.lastIndexOf('.')).toLowerCase() //（把路径中的所有字母全部转换为小写）
    if (AllImgExt.indexOf(extName + '|') === -1) {
      let ErrMsg =
        '该文件类型不允许上传。请上传 .jpg、.pdf、.png 、.jpeg、.docx、.doc类型的文件，当前文件类型为' +
        extName
      Message.error(ErrMsg)
      return false
    }
    const isLt10M = !(
      b2ValueUnit(file.size)[1] === 'B' ||
      b2ValueUnit(file.size)[1] === 'KB' ||
      (b2ValueUnit(file.size)[1] === 'MB' && b2ValueUnit(file.size)[0] <= 5)
    )
    return new Promise((resolve, reject) => {
      if (isLt10M) {
        Message.error('上传文件不能大于5M')
        reject()
      } else {
        resolve()
      }
    })
  }
  setRequest() {
    clearTimeout(this.state.setRequestTime)
    clearTimeout(this.state.setChangeAbatchTime)
    if (this.state.attachment.length === 0) {
      this.setState({
        ParagraphContinuation: [],
        ContinuationLoding: false,
        requestMsg: '',
        requestMsgText: '您还未上传简历',
      })
    } else {
      let params = {
        // app_id: this.state.record.id,
        file_key: this.state.attachment[0],
      }
      const { ParagraphContinuation } = this.state
      this.setState({
        loading: true,
      })

      let that = this

      resumesAnalyze(params).then((res) => {
            if (res.code === 2301) {
              let list = res.data
             this.state.resumeTimeId = setInterval(()=>{

                resumesInfo({requestId:list.requestId}).then((res) => {
                  if(res.code == 200){
                    clearInterval(this.state.resumeTimeId)
                    that.setState({
                      ParagraphContinuation: [1,2],
                      chatpptSrc: res.data.preview_url,
                      ContinuationLoding: false,
                      requestMsg: '',
                      loading: false,
                    })
                    
                    if (
                      that.state.ParagraphContinuation.length === 1 &&
                      list.jobs.length > 0
                    ) {
                      setTimeout(() => {
                        that.handleResize()
                      }, 100)
                    }
                  }
                })
              },1000)

                // setTimeout(() => {
                //     this.handleResize();
                // }, 100)

            } else if (
              res.code === 400 ||
              res.code === 3003 ||
              res.code === 204
            ) {
              that.setState({
                ContinuationLoding: false,
                requestMsg: '',
                loading: false,
              })
              if (ParagraphContinuation.length > 0) {
                Notification.warning({ content: res.msg })
              } else {
                that.setState({
                  ParagraphContinuation: [],
                  requestMsgText: res.msg,
                })
              }
            } else {
              that.setState({
                ContinuationLoding: false,
                requestMsg: '',
                loading: false,
              })
              if (ParagraphContinuation.length > 0) {
                Notification.warning({ content: res.msg })
              } else {
                that.setState({
                  ParagraphContinuation: [],
                  requestMsg: 'error',
                  requestMsgText: res.msg,
                })
              }
            }
          })

    }
  }
  getCheckResource(callback) {
    let params = { model_capability: this.state.apiInfo?.model_capability }
    let PACKAGES = ['PACKAGE_FINISH', 'PACKAGE_EXPIRED']
    check_resource(params).then((res) => {
      if (res.code === 200) {
        if (res.data) {
          let new_upgradetips = res.data
          new_upgradetips.model_capability = params.model_capability
          this.setState({
            upgradetips: new_upgradetips,
          })
          if (PACKAGES[new_upgradetips.notice_type]) {
            Notification.warning({ content: new_upgradetips.message })
          } else {
            setTimeout(() => {
              this.setState({
                visibleUpgrade: true,
              })
            }, 100)
          }
        }
        callback(res)
      } else {
        callback(res)
      }
    })
  }
  goResources() {
    history.push('/myapp/resources')
    localStorage.setItem('record', JSON.stringify(this.state.record))
    window.location.reload()
  }
  goInterfacetest() {
    history.push('/myapp/interfacetest')
    localStorage.setItem('record', JSON.stringify(this.state.record))
    window.location.reload()
  }
  ChangeAbatch(type) {
    sourceCancelClass()
    clearTimeout(this.state.setRequestTime)
    clearTimeout(this.state.setChangeAbatchTime)
    if (type === 'start') {
      this.setState({
        ParagraphContinuation: [],
        loading: false,
        ContinuationLoding: true,
        requestMsg: '',
      })
    } else {
      this.setState({
        loading: true,
        requestMsg: '',
      })
    }
    this.state.setChangeAbatchTime = setTimeout(() => {
      this.setRequest()
    }, 1000)
  }
  setdownload(visible) {
    this.setState({
      visible: visible,
    })
  }
  opendownload(item) {
    this.setdownload(true)
    this.setState({
      visibleimg: item,
    })
  }
  getUrlBase64() {
    var url = this.state.visibleimg // 获取图片地址
    // var a = document.createElement('a');          // 创建一个a节点插入的document
    // var event = new MouseEvent('click')           // 模拟鼠标click点击事件
    // a.download = '样机图'                  // 设置a节点的download属性值
    // a.target = "_blank"
    // a.href = url;                                 // 将图片的src赋值给a节点的href
    // a.dispatchEvent(event)
    fetch(url)
      .then(async (res) => await res.blob())
      .then((blob) => {
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = URL.createObjectURL(blob)
        a.download = '样机图'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      })
  }
  //判断文件是pdf
  pdfType(result2, file) {
    if (result2) {
      let type = file.originFile.type
      // var index = url.lastIndexOf("\/");
      // url = url.substring(index + 1, url.length);
      //获取最后一个.的位置
      // var index = result2.lastIndexOf(".");
      // //获取后缀
      // var ext = result2.substr(index + 1);
      if (
        [
          'application/pdf',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/msword',
        ].indexOf(type.toLowerCase()) === -1
      ) {
        return false
      } else {
        if (
          type ===
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          type === 'application/msword'
        ) {
          return 'docx'
        } else {
          return 'pdf'
        }
      }
    }
  }
  renderUploadList = (filesList, props) => {
    return (
      <div className="rowStart" style={{ width: '100%' }}>
        {filesList.map((file) => {
          const url =
            file.url ||
            (file.originFile ? URL.createObjectURL(file.originFile) : '')
          return (
            <Card
              className="upload-demo-list"
              key={file.uid}
              hoverable
              bodyStyle={{ padding: '4px 8px' }}
              cover={
                <img
                  className={`${this.pdfType(url, file) ? 'fileType' : ''}`}
                  src={
                    this.pdfType(url, file) === 'pdf'
                      ? pdf_icon
                      : this.pdfType(url, file) === 'docx'
                      ? docx_icon
                      : url
                  }
                  style={{ height: '100%' }}
                  alt=""
                />
              }
              actions={[
                <div className="file-Delete">
                  <IconDelete
                    onClick={() => {
                      props.onRemove(file)
                    }}
                  />
                </div>,
              ]}
            ></Card>
          )
        })}
      </div>
    )
  }
  isputapp = (name, desc) => {
    this.putapp(name, desc)
  }
  putapp(name, desc) {
    let params = {
      id: this.state.record.show_id,
    }
    if (desc !== false) {
      params.desc = desc
    } else if (name !== false) {
      params.name = name
    }
    putapp(params).then((res) => {
      if (res.code === 200) {
      } else {
        this.getApiInfo(true)
        Notification.error({ content: res.msg })
      }
    })
  }
  onChangetitle(type, value) {
    //console.log(value, "value");
    var apiInfo = this.state.apiInfo
    if (type === 'desc') {
      if (value.length > 100) {
        apiInfo.desc = value.substring(0, 100)
        Notification.warning({ content: '修改内容超过100个字！' })
        return
      }
      apiInfo.desc = value
      this.setState({
        apiInfo: apiInfo,
      })
      this.isputapp(false, value)
    } else if (type === 'name') {
      if (value.length > 20) {
        apiInfo.name = value.substring(0, 20)
        Notification.warning({ content: '修改标题超过20个字！' })
        return
      }
      apiInfo.name = value
      this.setState({
        apiInfo: apiInfo,
      })
      this.isputapp(value, false)
    }
  }
  goaddApp() {
    history.replace('/myapp/addmyapp')
    let state = {
      model_capability: this.state.apiInfo.model_capability,
      checkedtype: this.state.apiInfo.model_id,
      model_id: this.state.apiInfo.model_id,
      name: this.state.apiInfo.model_name,
    }
    localStorage.setItem('props', JSON.stringify(state))
    localStorage.removeItem('record')
    window.location.reload()
  }
  addRow() {
    localStorage.removeItem('props')
    localStorage.setItem('record', JSON.stringify(this.state.apiInfo))
    history.replace('/enginefactory/model/management/add')
    window.location.reload()
  }
  setvisibleUpgrade(value) {
    this.setState({
      visibleUpgrade: value,
    })
  }
  render() {
    const {
      visibleUpgrade,
      upgradetips,
      apiInfo,
      versions,
      optionVlue,
      loading,
      visibleimg,
      fileList,
      ParagraphContinuation,
      ContinuationLoding,
      requestMsg,
      requestMsgText,
      chatpptSrc,
    } = this.state
    return (
      <div className="semanticprototype post-matching">
        <div id="renderHeaders">
          <div className="GroupTable-fixe GroupTable-fixe-end">
            <div className="edit-title">
              <div className="Header-init">
                <div className="Header-title">
                  <Typography.Paragraph
                    className="Header-uptitle"
                    editable={
                      apiInfo.type === 0
                        ? null
                        : {
                            onChange: this.onChangetitle.bind(this, 'name'),
                          }
                    }
                  >
                    {apiInfo.name}
                  </Typography.Paragraph>
                </div>
              </div>
              <div className="Header-upParagraph">
                <Typography.Paragraph
                  className="Header-uptitle"
                  editable={
                    apiInfo.type === 0
                      ? null
                      : {
                          onChange: this.onChangetitle.bind(this, 'desc'),
                        }
                  }
                >
                  {apiInfo.desc}
                </Typography.Paragraph>
              </div>
            </div>
            <Space size={'medium'} align="center">
              {apiInfo.type === 0 ? (
                <Button
                  className="primarybtn custom-White-button"
                  onClick={this.goaddApp.bind(this)}
                >
                  保存应用
                </Button>
              ) : (
                <>
                  <Button
                    style={{
                      display:
                        this.state.identity &&
                        this.state.identity !== 'PERSONAL'
                          ? 'inline-block'
                          : 'none',
                    }}
                    shape="circle"
                    className="custom-White-button"
                    onClick={this.goInterfacetest.bind(this)}
                  >
                    接口调用
                  </Button>
                </>
              )}
            </Space>
          </div>
        </div>
        <Layout className="semanticprototype-Layout" id="layout_id">
          <Sider className="Sider-left">
            <div className="Sider-left-configure columnSpaceBetween">
              <div className="Sider-left-w">
                <div className="semanticprototype-title">导入简历</div>
                <div className="Upload-pictures-title">上传您的简历</div>
                <div className="upload-demo-trigger">
                  <Upload
                    drag
                    multiple
                    accept=".PDF,.JPG,.PNG,.docx,.doc,.jpeg"
                    fileList={fileList}
                    renderUploadList={this.renderUploadList}
                    limit={1}
                    onChange={this.setFile.bind(this)}
                    customRequest={(option) => {
                      this.handleUpload(option)
                    }}
                    beforeUpload={this.beforeUpload.bind(this)}
                    onExceedLimit={() => {
                      Message.warning('超过上传数量限制！最多上传1个')
                    }}
                  >
                    <div className="rowCenter trigger">
                      <Space direction="vertical">
                        <span className="iconfont icon-icon_chuangjian_24"></span>
                        <div>上传简历</div>
                      </Space>
                    </div>
                  </Upload>
                </div>
                <div className="Upload-pictures-tip">
                  （仅支持 PDF、docx、doc、JPG 和 PNG 格式，最大文件尺寸5MB）
                </div>
              </div>
              <div className="Upload-pictures-bottom">
                <Divider />
                <Button
                  type="primary"
                  className="Start-generation"
                  loading={false}
                  onClick={this.ChangeAbatch.bind(this, 'start')}
                >
                  开始生成
                </Button>
              </div>
            </div>
          </Sider>
          <Content>
            <div className="experience-result" id="result">
              <div id="result_title" className="rowSpaceBetween result_title">
                <Typography>生成结果</Typography>
                <Typography.Paragraph className="result-remarks">
                  (内容基于韦尼克AI创作引擎生成，仅供参考)
                </Typography.Paragraph>
              </div>
              {ParagraphContinuation.length > 0 || ContinuationLoding ? (
                <div className="Paragraph-generate">
                  {ContinuationLoding ? (
                    <Empty
                      className="Continuation-loding"
                      icon={<img src={Generating_img} alt="" />}
                      description={
                        <Typography
                          style={{
                            color: '#4e5969',
                            marginTop: 21,
                            fontSize: 16,
                          }}
                        >
                          正在生成中...
                        </Typography>
                      }
                    />
                  ) : (
                    <div className="Continuation-result" id="continuation">

                     <iframe src={chatpptSrc}  className={`semanticprototype-Layout sass-semanticprototype-Layout`} id="layout_id" style={{height:'100%'}}></iframe>

                      {loading ? (
                        <Empty
                          className="list-loding"
                          icon={<img src={Generating_img} alt="" />}
                          description={
                            <Typography
                              style={{
                                color: '#4e5969',
                                marginTop: 21,
                                fontSize: 16,
                              }}
                            >
                              正在生成中...
                            </Typography>
                          }
                        />
                      ) : (
                        <></>
                        // <Button
                        //   icon={<IconPlus />}
                        //   id="iconSync"
                        //   className="IconSync-btn custom-White-border-button"
                        //   onClick={this.ChangeAbatch.bind(this)}
                        // >
                        //   生成更多
                        // </Button>
                      )}
                    </div>
                  )}
                </div>
              ) : requestMsg === 'error' ? (
                <Empty
                  className="Continuation-Empty Empty-error"
                  imgSrc={errorIcon}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        抱歉，服务器生成错误
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        可尝试
                        <span
                          style={{ color: '#406EFF', cursor: 'pointer' }}
                          onClick={this.ChangeAbatch.bind(this, 'start')}
                        >
                          {' '}
                          重新生成
                        </span>
                      </Typography>
                    </Space>
                  }
                />
              ) : (
                <Empty
                  className="Continuation-Empty Empty-none"
                  imgSrc={Emptydata_img}
                  description={
                    <Space direction="vertical">
                      <Typography
                        style={{
                          color: '#4e5969',
                          marginTop: 30,
                          fontSize: 16,
                        }}
                      >
                        暂无生成结果
                      </Typography>
                      <Typography style={{ color: '#4e5969', fontSize: 16 }}>
                        {requestMsgText}
                      </Typography>
                    </Space>
                  }
                />
              )}
            </div>
          </Content>

        </Layout>
        <Modal
          style={{
            width: 667,
          }}
          onCancel={() => this.setdownload(false)}
          className="custom-modal-download-sem"
          title={null}
          visible={this.state.visible}
          autoFocus={false}
          footer={
            <>
              <Button
                type="primary"
                className="download-btn"
                onClick={this.getUrlBase64.bind(this)}
              >
                <IconDownload />
                立即下载
              </Button>
            </>
          }
        >
          <div className="custom-modal-download-content">
            {visibleimg ? <img src={visibleimg} alt="" /> : ''}
          </div>
        </Modal>
        <UpgradeTips
          visible={visibleUpgrade}
          notification_status={upgradetips?.notice_type}
          notification_message={upgradetips?.message}
          model_capability={upgradetips?.model_capability}
          onCancel={() => this.setvisibleUpgrade(false)}
        ></UpgradeTips>
      </div>
    )
  }
}
export default SemanticPrototype
