import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import {
  Grid,
  Card,
  Typography,
  Button,
  Steps,
  Divider,
  Space,
  Input,
  Select,
  Radio,
  Checkbox,
  Spin,
  Upload,
  Result,
  Empty,
  Link,
  Carousel,
  Notification,
  Popover,
  Modal,
  Collapse
} from '@arco-design/web-react'
import {
  IconCheck,
  IconArrowLeft,
  IconArrowRight,
  IconUpload,
  IconStarFill,
  IconExclamationCircleFill,
  IconCheckCircleFill,
  IconLoading,
  IconDelete,
  IconLeft,
  IconRight,
  IconQuestionCircleFill
} from '@arco-design/web-react/icon'
// import icon1 from "asserts/Intelligentwriting_icon_big.svg";
// import icon2 from "asserts/Intelligentgraphic_icon_big.svg";
// import icon3 from "asserts/Smartdocument_icon_big.svg";
// import icon4 from "asserts/Intelligentcreation_icon_big.svg";
// import icon5 from "asserts/knowledge_graph_big.svg";
// import Icon6 from "asserts/NLP_basic_engine_icon_big.svg";
import ai_ppt from 'asserts/ai_ppt.png'
import ai_resume from 'asserts/ai_resume.png'
import ai_marketing from 'asserts/ai_marketing.png'
import ai_pact from 'asserts/ai_pact.png'
import ai_ppt_buttom from 'asserts/ai_ppt_buttom.png'
import ai_resume_buttom from 'asserts/ai_resume_buttom.png'
import ai_marketing_buttom from 'asserts/ai_marketing_buttom.png'
import ai_pact_buttom from 'asserts/ai_pact_buttom.png'
import {
  getmodelList,
  postapp,
  putapp,
  postmodel,
  getdatumList,
  postdatum,
  getmodelTrainProcess,
  postmodelVersionTrain,
  postcheckName,
  getmodelListVersion,
  deleteModelVersion,
  newCreateappList,
  getAllapiList,
  getcreateappList,
  apiBind,
  getApiClass
} from 'api/apis'
import ComboModal from 'pages/myapp/comboModal'
import { b2ValueUnit, pageCount } from 'common/utils/index'
import upload from 'api/uploads'
import TrainingStatus from 'components/TrainingStatus'
import history from 'common/utils/history'
import { sourceCancelClass } from 'api/cancel-request'
import JQ from 'jquery'
import './myapp.scss'
const Row = Grid.Row
const Col = Grid.Col
const Step = Steps.Step
const TextArea = Input.TextArea
const Option = Select.Option
const CollapseItem = Collapse.Item
// 封装组件
class Addmyapp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      listTimer: '',
      isRetraining: false,
      mockData: [],
      paginationVersion: {
        total: 0,
        pageSize: 10,
        current: 1
      },
      scrollLoading: <Spin loading={true} />,
      isToBeTrained: false,
      model_capability: '',
      current: 1,
      name: '',
      desc: '',
      Modeldesc: '',
      Modelname: '',
      cardstepitem: null,
      choseType: null,
      ModelSelsectList: [],
      checkedtype: '',
      app_id: '',
      model_id: '',
      data_id: '', //数据集id（语料配置）
      namedata: '',
      pagination: {
        total: 0,
        pageSize: 10,
        current: 1
      },
      paginationdata: {
        total: 0,
        pageSize: 10,
        current: 1
      },
      paginationCustom: { total: 0, pageSize: 10, current: 1 },
      CustomModelList: [],
      setingOk: false, //新建模型成功
      checkedtypeData: '',
      DataSelsectList: [],
      fileList: null,
      attachment: [],
      Code: '',
      progress: 0,
      title: '',
      setIntervalTimer: '',
      subTitle: '',
      ModelAddList: ['创建新模型'],
      SaaSList: ['免费版', '基础生成版', '智能编辑版'],
      SaaSType: '免费版',
      combo: false,
      DataAddList: ['创建数据集'],
      record: JSON.parse(localStorage.getItem('record')),
      version_id: '',
      btnOktitle: '开启应用',
      texSetList: [
        '正在进行数据分包......',
        '正在计算模型偏差......',
        '正在优化模型......',
        '已完成0次模型迭代......'
      ],
      frequency: 0,
      reciprocal: 3,
      reciprocalTime: '',
      loading: false,
      PatternList: localStorage.getItem('PatternList')
        ? JSON.parse(localStorage.getItem('PatternList'))
        : '',
      checkedPattern: '',
      newappList: {},
      appList: [],
      searchParams: null,
      userinfo: JSON.parse(localStorage.getItem('userinfo')),
      selectName: ''
    }
    this.myRef = React.createRef()
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    setTimeout(() => {
      this.handleResize()
    }, 300)

    let props = JSON.parse(localStorage.getItem('props'))
    let record = JSON.parse(localStorage.getItem('record'))
    this.setState({
      progress: props.progress ? props.progress : 0,
      model_capability: props.model_capability,
      current: props.current ? props.current : 1,
      name: props.name ? props.name : '',
      desc: props.desc ? props.desc : '',
      Modeldesc: props.Modeldesc ? props.Modeldesc : '',
      Modelname: props.Modelname ? props.Modelname : '',
      cardstepitem: JSON.parse(localStorage.getItem('Selectoptions'))[
        props.model_capability
      ],
      choseType:
        record?.type === '智能PPT(API版)'
          ? '智能PPT'
          : record?.type
          ? record.type
          : '',
      checkedtype: props.checkedtype ? props.checkedtype : '',
      checkedtypeData: props.checkedtypeData ? props.checkedtypeData : '',
      fileList: props.fileList ? props.fileList : '',
      attachment: props.attachment ? props.attachment : '',
      namedata: props.namedata ? props.namedata : '',
      app_id: props.app_id ? props.app_id : '',
      model_id: props.model_id ? props.model_id : '',
      data_id: props.data_id ? props.data_id : '',
      setingOk: props.setingOk ? props.setingOk : false,
      DataSelsectList: props.DataSelsectList ? props.DataSelsectList : [],
      ModelSelsectList: props.ModelSelsectList ? props.ModelSelsectList : [],
      CustomModelList: props.CustomModelList ? props.CustomModelList : [],
      Code: props.Code ? props.Code : '',
      subTitle: props.subTitle ? props.subTitle : '',
      title: props.title ? props.title : '',
      version_id: props.version_id ? props.version_id : '',
      isRetraining: props.isRetraining ? props.isRetraining : false,
      checkedPattern: props.checkedPattern ? props.checkedPattern : ''
      // isToBeTrained: props.isToBeTrained ? props.isToBeTrained : false
    })
    if (record && record.train_details === '详情') {
      this.setState({
        model_capability: record.model_capability,
        checkedtype:
          record.train_status === '待训练' || record.train_status === ''
            ? '创建新模型'
            : '',
        cardstepitem: JSON.parse(localStorage.getItem('Selectoptions'))[
          record.model_capability
        ],
        current:
          record.train_status === '待训练' || record.train_status === ''
            ? 3
            : record.current
            ? record.current
            : 1,
        name: record.name,
        desc: record.desc,
        Modelname: record.model_name,
        Modeldesc: record.model_desc,
        app_id: record.app_id ? record.app_id : record.id ? record.id : '',
        model_id: record.model_id ? record.model_id : '',
        setingOk:
          record.train_status === '训练中' ||
          record.train_status === '待训练' ||
          record.train_status === '训练失败'
            ? true
            : false,
        Code:
          record.train_status === '训练中'
            ? 'init'
            : record.train_status === '训练失败'
            ? 'error'
            : '',
        subTitle:
          record.train_status === '训练失败'
            ? '服务训练出现异常，建议您联系专属客服解决问题'
            : '',
        title: record.name
      })
      // if (record.train_status === "") {
      //   this.getmodelList(record.model_capability)
      // }
      if (
        record.train_status === '训练中' ||
        record.train_status === '待训练' ||
        record.train_status === '训练失败'
      ) {
        this.state.version_id = record.version_id
          ? record.version_id
          : record.id
        this.getmodelTrainProcess(
          record.version_id ? record.version_id : record.id
        )
        if (record.train_status !== '训练失败') {
          this.setintervalData()
        }
      }
    }
    if (record && record.train_details === '模型创作') {
      this.setState({
        model_capability: record.model_capability,
        checkedtype: record.id,
        cardstepitem: JSON.parse(localStorage.getItem('Selectoptions'))[
          record.model_capability
        ],
        current: record.current ? record.current : 1,
        // name: record.name,
        // desc: record.desc,
        Modelname: record.name,
        Modeldesc: record.desc,
        app_id: record.app_id ? record.app_id : '',
        model_id: record.id ? record.id : '',
        title: record.name
      })
    }

    if (
      props.version_id &&
      (props.Code === 'init' || props.Code === 'trained')
    ) {
      this.getmodelTrainProcess(props.version_id)
      this.state.version_id = props.version_id
      this.setintervalData()
    }
    setTimeout(() => {
      this.getmodelList()
      this.getCustomModelList()
      this.getdatumList()
      this.getappList()
    }, 100)
  }
  handleResize = () => {
    var winHeight = window.innerHeight
    if (document.getElementById('layoutbody')) {
      if (window.innerHeight) {
        winHeight = window.innerHeight
      } else if (document.body && document.body.clientHeight) {
        winHeight = document.body.clientHeight
      }
      if (document.documentElement && document.documentElement.clientHeight) {
        winHeight = document.documentElement.clientHeight
      }
      let height =
        winHeight -
        document.getElementById('layoutHeaders').clientHeight -
        document.getElementById('Headertitle').clientHeight -
        20 -
        40
      document.getElementById('layoutbody').style.height = height + 'px'
      setTimeout(() => {
        if (document.getElementById('layoutbody')) {
          let height2 =
            document.getElementById('layoutbody').clientHeight -
            document.getElementById('startParagraph').clientHeight -
            document.getElementById('selectFoot').clientHeight -
            32 -
            15
          document.getElementById('forminput').style.height = height2 + 'px'
        }
      }, 100)
    }
    document.getElementsByClassName('addmyapp')[0].style.opacity = '1'
  }

  componentWillUnmount() {
    clearInterval(this.state.listTimer)
    clearInterval(this.state.setIntervalTimer)
    window.removeEventListener('resize', this.handleResize)
  }
  // 监听页面滚动
  handleOnScroll = () => {
    if (this.myRef) {
      const contentScrollTop = this.myRef.scrollTop //滚动条距离顶部
      const clientHeight = this.myRef.clientHeight //可视区域
      const scrollHeight = this.myRef.scrollHeight //滚动条内容的总高度
      if (contentScrollTop + clientHeight >= scrollHeight) {
        if (
          this.state.pagination.total !== 0 &&
          this.state.pagination.total !== this.state.ModelSelsectList.length &&
          this.state.current === 2
        ) {
          let pagination = this.state.pagination
          pagination.current = this.state.pagination.current + 1
          this.setState({
            pagination: pagination
          })
          this.getmodelList() // 获取数据的方法
        } else if (
          this.state.paginationCustom.total !== 0 &&
          this.state.paginationCustom.total !==
            this.state.CustomModelList.length &&
          this.state.current === 2
        ) {
          let pagination = this.state.paginationCustom
          pagination.current = this.state.paginationCustom.current + 1
          this.setState({
            paginationCustom: pagination
          })
          this.getCustomModelList() // 获取数据的方法
        } else if (
          this.state.pagination.total !== 0 &&
          this.state.paginationdata.total !==
            this.state.DataSelsectList.length &&
          this.state.current === 4
        ) {
          let pagination = this.state.paginationdata
          pagination.current = this.state.paginationdata.current + 1
          this.setState({
            paginationdata: pagination
          })
          this.getdatumList()
        }
      }
    }
  }
  getappList = () => {
    let Selectoptions = JSON.parse(localStorage.getItem('Selectoptions'))
    // console.log(Selectoptions.model_capability,this.state.choseType);
    let model = {
      // 'ai_ppt':'智能PPT(API版)',
      // 'saas_ppt':'智能PPT(SaaS版)',
      ai_ppt: '智能PPT',
      saas_ppt: '智能PPT',
      saas_resume: 'SAAS简历',
      ai_resume: '智能简历',
      ai_marketing: '智能营销',
      ai_pact: '智能合同'
    }
    let param = {
      class: this.state.choseType || model[Selectoptions.model_capability]
    }
    if (this.state.choseType === '智能PPT(SaaS版)') return
    getApiClass(param).then((res) => {
      // console.log(res.data,Selectoptions.model_capability,this.state.choseType,'数据');
      if (res.code === 200) {
        console.log(this.state.choseType, model[Selectoptions.model_capability])
        let list = res.data.length > 0 ? res.data[0].children : ''
        // if(Selectoptions.model_capability == 'saas_resume' || this.state.choseType == '智能简历(SaaS版)'){
        //   list = this.setSaasResume()
        // }
        if (
          this.state.userinfo.group_permission === 'PERSONAL' &&
          (Selectoptions.model_capability === 'ai_resume' ||
            this.state.choseType === '智能简历')
        ) {
          list[0].children.forEach((item) => {
            if (item.name === '简历生成' || item.name === '简历辅写') {
              item.isDiable = false
            } else {
              item.isDiable = true
            }
            if (item.children) {
              item.children.forEach((ite) => {
                if (ite.name === '简历生成' || item.name === '简历辅写') {
                  ite.isDiable = false
                } else {
                  ite.isDiable = true
                }
              })
            }
          })
          //排序，将可选的放在前面
          list[0].children.sort((a, b) => (a.isDiable > b.isDiable ? 1 : -1))
        }
        this.setState({
          newappList: list
        })
      } else {
        Notification.error({ content: res.msg })
      }
    })
  }

  setSaasResume() {
    const children = {
      id: 900,
      link_id: 20,
      name: '官方方案',
      link_api: '',
      status: 1,
      created_at: '2024-10-28 14:32:18',
      updated_at: '2024-10-28 14:32:20',
      children: [
        {
          id: 901,
          link_id: 45,
          name: '简历分析',
          link_api: '14,15,34,31',
          status: 1,
          created_at: '2024-10-25 17:47:48',
          updated_at: '2024-10-25 17:47:49'
        },
        {
          id: 902,
          link_id: 45,
          name: '人岗匹配',
          link_api: '16,34',
          status: 1,
          created_at: '2024-10-25 17:53:36',
          updated_at: '2024-10-25 17:53:36'
        },
        {
          id: 903,
          link_id: 45,
          name: '岗位探测',
          link_api: '17',
          status: 1,
          created_at: '2024-10-25 17:54:26',
          updated_at: '2024-10-25 17:54:27'
        }
      ]
    }
    return [children]
  }
  setmoreSelect(type, direction) {
    if (type === 'model') {
      let pagination = this.state.pagination
      if (direction === 'left') {
        pagination.current = this.state.pagination.current - 1
      } else {
        pagination.current = this.state.pagination.current + 1
      }
      JQ('.pageGroup').addClass('Group-fadeInUp')
      this.setState({
        pagination: pagination
      })
      this.getmodelList() // 获取数据的方法
    } else if (type === 'modelCustom') {
      let pagination = this.state.paginationCustom
      if (direction === 'left') {
        pagination.current = this.state.paginationCustom.current - 1
      } else {
        pagination.current = this.state.paginationCustom.current + 1
      }
      JQ('.pageGroup2').addClass('Group-fadeInUp')
      this.setState({
        paginationCustom: pagination
      })
      this.getCustomModelList() // 获取数据的方法
    } else if (type === 'data') {
      let pagination = this.state.paginationdata
      if (direction === 'left') {
        pagination.current = this.state.paginationdata.current - 1
      } else {
        pagination.current = this.state.paginationdata.current + 1
      }
      JQ('.pageGroup').addClass('Group-fadeInUp')
      this.setState({
        paginationdata: pagination
      })
      this.getdatumList() // 获取数据的方法
    }
  }
  getmodelList(model_capability) {
    let page = {
      per_page: this.state.pagination.pageSize,
      page: this.state.pagination.current,
      model_capability: model_capability
        ? model_capability
        : this.state.model_capability,
      train_status: '训练成功',
      type: 9,
      order_by: 'type,asc'
    }
    getmodelList(page).then((res) => {
      let list = res.data

      let pagination = this.state.pagination
      pagination.total = list.count
      this.setState({
        pagination: pagination,
        ModelSelsectList: list.data
      })
      localStorage.setItem('props', JSON.stringify(this.state))
      let totalPage = pageCount(list.count, page.per_page)
      setTimeout(() => {
        JQ('.pageGroup').removeClass('Group-fadeInUp')
      }, 1000)
    })
  }
  getCustomModelList(model_capability) {
    let page = {
      per_page: this.state.paginationCustom.pageSize,
      page: this.state.paginationCustom.current,
      model_capability: model_capability
        ? model_capability
        : this.state.model_capability,
      train_status: '训练成功',
      type: 1,
      order_by: 'type,asc'
    }
    getmodelList(page).then((res) => {
      let list = res.data

      let pagination = this.state.paginationCustom
      pagination.total = list.count
      this.setState({
        paginationCustom: pagination,
        CustomModelList: list.data
      })
      localStorage.setItem('props', JSON.stringify(this.state))
      let totalPage = pageCount(list.count, page.per_page)
      setTimeout(() => {
        JQ('.pageGroup2').removeClass('Group-fadeInUp')
      }, 1000)
    })
  }
  getmodelTrainProcess(version_id) {
    clearInterval(this.state.setIntervalTimer)
    let page = {
      version_id: version_id
    }
    sourceCancelClass()
    getmodelTrainProcess(page).then((res) => {
      let Code = ''
      let subTitle = ''
      if (res.code === 200) {
        if (res.data.train_status === '待训练') {
          Code = 'trained'
          this.setState({
            btnOktitle: '更多应用',
            train_status: res.data.train_status
          })
          this.state.setIntervalTimer = setInterval(() => {
            this.getmodelTrainProcess(version_id)
          }, 3000)
        } else if (
          res.data.train_status === '训练中' ||
          res.data.train_status === '训练成功'
        ) {
          if (
            res.data.train_percent >= 100 &&
            res.data.train_status === '训练成功'
          ) {
            clearInterval(this.state.setIntervalTimer)
            Code = 'success'
          } else {
            Code = 'init'
            this.state.setIntervalTimer = setInterval(() => {
              this.getmodelTrainProcess(version_id)
            }, 3000)
          }
        } else if (res.data.train_status === '训练失败') {
          Code = 'error'
          subTitle = '模型训练异常，请重新尝试或联系专属客服解决'
          // res.data.train_msg ? res.data.train_msg : res.msg
          clearInterval(this.state.setIntervalTimer)
        }
        let frequency = this.state.frequency + 1
        let texSetList = this.state.texSetList
        let count = res.data.iteration_count ? res.data.iteration_count : 0
        // texSetList[0] = '正在进行第' + frequency + '次数据分包......'
        texSetList[3] = '已完成' + count + '次模型迭代......'
        this.setState({
          progress: res.data.train_percent,
          title: res.data.model_name ? res.data.model_name : res.data.app_name,
          version_id: res.data.version_id,
          data_id: res.data.data_id,
          texSetList: texSetList,
          frequency: frequency,
          Code: Code,
          subTitle: subTitle,
          isRetraining: res.data.enable_edit
        })
        localStorage.setItem('props', JSON.stringify(this.state))
      } else {
        Code = 'error'
        subTitle = '服务器异常，请通过客服联系开发人员'
        // res.data.train_msg ? res.data.train_msg : res.msg
        clearInterval(this.state.setIntervalTimer)
      }
      this.setState({
        Code: Code,
        subTitle: subTitle
      })
    })
  }
  postapp() {
    //添加app
    let page = {
      model_capability: this.state.model_capability,
      name: this.state.name,
      desc: this.state.desc
    }
    if (this.state.model_id && this.state.checkedtype !== '创建新模型') {
      page.model_id = this.state.model_id
    }
    const { checkedtype, current } = this.state
    if (this.state.app_id && checkedtype && checkedtype !== '创建新模型') {
      let page = {
        id: this.state.app_id,
        name: this.state.name,
        desc: this.state.desc,
        model_id: this.state.model_id
      }
      putapp(page).then((res) => {
        let Code = ''
        let subTitle = ''
        if (res.code === 200) {
          this.state.current = current + 1
          this.setState({
            current: current + 1,
            loading: false,
            title: this.state.name
          })
          this.state.setingOk = true
          this.setState({
            setingOk: true
          })
          Code = 'success'
          this.state.reciprocalTime = setInterval(() => {
            let reciprocal = this.state.reciprocal - 1
            if (reciprocal === 0) {
              clearInterval(this.state.reciprocalTime)
              this.OpenApp()
            }
            this.setState({
              reciprocal: reciprocal
            })
          }, 1000)
        } else if (res.code === 3004 || res.code === 409) {
          Code = ''
          Notification.warning({ content: res.msg })
          this.setState({
            loading: false
          })
        } else {
          this.state.setingOk = true
          this.setState({
            setingOk: true,
            loading: false
          })
          Code = 'error'
          subTitle = res.msg
          this.setState({
            title: this.state.model_name
              ? this.state.model_name
              : this.state.name,
            setingOk: true
          })
        }
        this.setState({
          Code: Code,
          subTitle: subTitle
        })
        localStorage.setItem('props', JSON.stringify(this.state))
      })
    } else {
      postapp(page).then((res) => {
        let Code = ''
        let subTitle = ''
        if (res.code === 200) {
          this.state.current = current + 1
          this.state.app_id = res.data.id
          this.setState({
            app_id: res.data.id,
            current: current + 1,
            loading: false
          })
          if (checkedtype && checkedtype !== '创建新模型') {
            this.state.setingOk = true
            this.setState({
              setingOk: true
            })
            Code = 'success'
            this.setState({
              title: res.data.name
            })
            this.state.reciprocalTime = setInterval(() => {
              let reciprocal = this.state.reciprocal - 1
              if (reciprocal === 0) {
                clearInterval(this.state.reciprocalTime)
                this.OpenApp()
              }
              this.setState({
                reciprocal: reciprocal
              })
            }, 1000)
          }
        } else if (res.code === 3004 || res.code === 409) {
          Code = ''
          Notification.warning({ content: res.msg })
          this.setState({
            loading: false
          })
        } else {
          this.state.setingOk = true
          this.setState({
            setingOk: true,
            loading: false
          })
          Code = 'error'
          subTitle = res.msg
          this.setState({
            title: this.state.model_name
              ? this.state.model_name
              : this.state.name,
            setingOk: true
          })
        }
        this.setState({
          Code: Code,
          subTitle: subTitle
        })
        localStorage.setItem('props', JSON.stringify(this.state))
      })
    }
  }
  // 添加模型（新建）
  postmodel() {
    let page = {
      model_capability: this.state.model_capability,
      name: this.state.Modelname,
      desc: this.state.Modeldesc,
      data_id: this.state.data_id,
      app_id: this.state.app_id
    }
    if (this.state.checkedPattern) {
      page.train_mode = this.state.checkedPattern
    }
    postmodel(page).then((res) => {
      // this.state.setingOk = true
      let Code = ''
      let subTitle = ''
      if (res.code === 200) {
        this.state.setingOk = true
        Code = 'init'
        this.state.model_id = res.data.id
        this.state.version_id = res.data.version_id
        this.setState({
          model_id: res.data.id,
          version_id: res.data.version_id,
          loading: false,
          current: this.state.current + 1
        })
        localStorage.setItem('props', JSON.stringify(this.state))
        this.getmodelTrainProcess(res.data.version_id)
        this.state.version_id = res.data.version_id
        this.setintervalData()
        subTitle = res.msg
        this.setState({
          setingOk: true,
          Code: Code,
          subTitle: subTitle
        })
      } else {
        // Code = 'error'
        // subTitle = res.msg
        // this.setState({
        //   setingOk: true,
        //   Code: Code,
        //   subTitle: subTitle
        // })
        Code = ''
        Notification.warning({ content: res.msg })
        this.setState({
          loading: false
        })
      }
      localStorage.setItem('props', JSON.stringify(this.state))
    })
  }
  // 选择数据集
  getdatumList() {
    let page = {
      per_page: this.state.paginationdata.pageSize,
      page: this.state.paginationdata.current,
      model_capability: this.state.model_capability
      // load_status: '导入成功'
    }
    // if (this.state.paginationdata.current === 1) {
    //   this.setState({
    //     DataSelsectList: []
    //   })
    // }
    getdatumList(page).then((res) => {
      let list = res.data
      let pagination = this.state.paginationdata
      pagination.total = list.count
      this.setState({
        paginationdata: pagination,
        DataSelsectList: list.data
      })
      localStorage.setItem('props', JSON.stringify(this.state))
      setTimeout(() => {
        JQ('.pageGroup').removeClass('Group-fadeInUp')
      }, 1000)
      //console.log(list, "list");
    })
  }
  // 添加数据集
  postdatum() {
    let page = {
      model_capability: this.state.model_capability,
      name: this.state.namedata,
      attachment: JSON.stringify(this.state.attachment)
    }
    postdatum(page).then((res) => {
      if (res.code === 200) {
        this.setState({
          data_id: res.data.id
        })
        this.postmodel()
      } else {
        Notification.error({ content: res.msg })
        this.setState({
          loading: false
        })
      }
    })
  }
  onChangeIput(value) {
    const { current, checkedtype } = this.state
    if (current === 3 && checkedtype === '创建新模型') {
      this.setState({
        Modelname: value
      })
    } else if (current === 4 && checkedtype === '创建新模型') {
      this.setState({
        namedata: value
      })
    } else {
      this.setState({
        name: value
      })
    }
  }
  onChangeTextArea(value) {
    const { current, checkedtype } = this.state
    if (current === 3 && checkedtype === '创建新模型') {
      this.setState({
        Modeldesc: value
      })
    } else {
      this.setState({
        desc: value
      })
    }
  }
  // 选择模型
  onSelsect(value) {
    const { cardstepitem } = this.state
    let checkValue = value
    const key = ['智能PPT(API版)', '智能PPT']
    if (!key.includes(cardstepitem.label) && value.length > 1) {
      checkValue.shift()
    }
    if (cardstepitem.label == '智能简历(SaaS版)') {
      const typeName = this.state.newappList[0].children.find(
        (ite) => ite.id == checkValue[0]
      )
      this.setState({
        selectName: typeName.name
      })
    }
    this.setState({
      checkedtype: checkValue
    })
  }
  // 选择数据集
  onSelsectData(value) {
    this.setState({
      checkedtypeData: value
    })
  }
  // 选择模型训练模式
  onSelsectPattern(value) {
    this.setState({
      checkedPattern: value
    })
  }
  handleUpload(option) {
    upload(option, this.state.model_capability)
  }
  setFile(fileList, file) {
    this.setState({
      fileList: fileList
    })

    if (file.status === 'done') {
      let key = []
      key.push(file.response.fileKey)
      this.setState({
        attachment: key
      })
      if (this.state.namedata === '') {
        this.setState({
          namedata: file.name.substring(0, file.name.lastIndexOf('.'))
        })
      }
    }
    //console.log(fileList, "setFile");
  }
  onIconDelete() {
    this.setState({
      fileList: null,
      attachment: []
    })
    localStorage.setItem('props', JSON.stringify(this.state))
  }
  beforeUpload(file) {
    //console.log(b2ValueUnit(file.size));
    var AllImgExt = '.csv|'
    var extName = file.name.substring(file.name.lastIndexOf('.')).toLowerCase() //（把路径中的所有字母全部转换为小写）
    if (AllImgExt.indexOf(extName + '|') === -1) {
      let ErrMsg =
        '该文件类型不允许上传。请上传 .csv 类型的文件，当前文件类型为' + extName
      Notification.warning({ content: ErrMsg })
      return false
    }
    const isLt10M = !(
      b2ValueUnit(file.size)[1] === 'B' ||
      b2ValueUnit(file.size)[1] === 'KB' ||
      (b2ValueUnit(file.size)[1] === 'MB' && b2ValueUnit(file.size)[0] <= 5)
    )
    return new Promise((resolve, reject) => {
      if (isLt10M) {
        Notification.warning({ content: '上传文件不能大于5MB' })
        reject()
      } else {
        resolve()
      }
    })
  }
  postcheckName(type, name) {
    const { checkedtype, current, Modeldesc, desc } = this.state
    let page = {
      type: type,
      name: name
    }
    if (this.state.app_id) {
      //更新时需要，若存在则更新
      page.id = this.state.app_id
      page.desc = type === 'app' ? desc : Modeldesc
    }
    postcheckName(page).then((res) => {
      if (res.code === 200) {
        if (res.data.id) {
          this.setState({
            app_id: res.data.id
          })
        }
        if (checkedtype && checkedtype !== '创建新模型' && current === 2) {
          this.state.model_id = checkedtype
          this.setState({
            model_id: checkedtype
          })
          this.postapp()
        } else if (
          checkedtype &&
          checkedtype === '创建新模型' &&
          current === 2 &&
          !this.state.app_id
        ) {
          this.postapp()
        } else {
          this.setState({
            current: current + 1,
            loading: false
          })
          this.state.current = current + 1
          localStorage.setItem('props', JSON.stringify(this.state))
        }
      } else {
        Notification.warning({ content: res.msg })
        this.setState({
          loading: false
        })
      }
    })
  }
  bindPage(id) {
    const { current, appList } = this.state
    this.getcreateappList()
    const creatData = appList.find((item) => item.show_id === id)
    localStorage.setItem('record', JSON.stringify(creatData))

    setTimeout(() => {
      if (creatData?.type === '智能PPT(SaaS版)') {
        history.push('/myapp/aigcExperience')
        window.location.reload()
      } else {
        this.setState({
          searchParams: creatData,
          choseType:
            creatData?.type === '智能PPT(API版)'
              ? '智能PPT'
              : creatData?.type
              ? creatData.type
              : '',
          current: current + 1
        })
      }
    }, 10)
    // history.push("/");
    // window.location.reload();
  }
  getcreateappList = () => {
    getcreateappList().then((res) => {
      this.setState({
        appList: res.data.data
      })
    })
  }
  //检查智能简历应用中是否有选中体验页面的api功能
  checkResum() {
    const { newappList, checkedtype } = this.state
    let keyID = []
    let selectType = ''
    const key = ['简历生成', '简历辅写', '简历分析', '人岗匹配', '岗位探测']
    newappList.forEach((item) => {
      item.children.forEach((ite) => {
        if (key.includes(ite?.name)) {
          keyID.push(ite.id)
        }
        if (checkedtype[0] == ite.id) {
          selectType = ite.name
        }
      })
    })
    // 检查传入的 array 是否包含 keyID 中的任何一个 id
    return selectType
  }
  onChangeNext() {
    const {
      name,
      desc,
      current,
      Modelname,
      PatternList,
      checkedPattern,
      checkedtype,
      checkedtypeData,
      namedata,
      attachment,
      newappList,
      selectName
    } = this.state

    if (current == 1) {
      if (name === '') {
        Notification.warning({ content: '应用名称不能为空!' })
        return
      } else if (desc === '') {
        Notification.warning({ content: '应用简介内容不能为空!' })
        return
      }

      let page = {
        name: this.state.name,
        desc: this.state.desc,
        type:
          this.state.cardstepitem.label == '智能PPT'
            ? '智能PPT(API版)'
            : this.state.cardstepitem.label
      }
      newCreateappList(page).then((res) => {
        if (res.code === 200) {
          this.getcreateappList()
          this.state.reciprocalTime = setTimeout(() => {
            // if(this.state.record && this.state.record.isflow){
            //   this.setState({
            //       current: current + 1,
            //     })
            // }
            this.bindPage(res.data.id)
          }, 1000)
        } else {
          // Code = "";
          Notification.warning({ content: res.msg })
          // history.push("/myapp/addmyapp");
          // window.location.reload();
        }
      })
    } else {
      if (checkedtype.length === 0) {
        Notification.warning({ content: '至少选择一个接口!' })
        return
      }
      let params2 = {
        id: this.state.searchParams
          ? this.state.searchParams.show_id
          : this.state.record.show_id,
        api_ids: checkedtype
      }

      apiBind(params2).then((res) => {
        if (res.code === 200) {
          let identity = JSON.parse(
            localStorage.getItem('userinfo')
          ).group_permission
          // if(identity === "PERSONAL"){
          switch (this.state.cardstepitem.label) {
            case '智能PPT(API版)':
              history.push('/myapp/interfacetest')
              break
            case '智能PPT':
              history.push('/myapp/interfacetest')
              break
            case '智能简历(SaaS版)':
              if (selectName == '岗位探测') {
                history.push('/myapp/saasResume?type=detect')
              } else if (selectName == '人岗匹配') {
                history.push('/myapp/saasResume?type=match')
              } else {
                history.push('/myapp/saasResume?type=analyse')
              }
              break
            case '智能简历':
              //检查智能简历应用中是否有选中体验页面的api功能
              if (identity === 'PERSONAL') {
                history.push('/myapp/resumeDocument')
              } else {
                const resumeType = this.checkResum()
                if (resumeType) {
                  switch (resumeType) {
                    case '简历生成':
                      history.push('/myapp/resumeDocument')
                      break
                    case '简历辅写':
                      history.push('/myapp/resumeDocument')
                      break
                    case '简历分析':
                      history.push('/myapp/resumeAnalyze')
                      break
                    case '人岗匹配':
                      history.push('/myapp/positionDetection')
                      break
                    case '岗位探测':
                      history.push('/myapp/postMatching')
                      break
                    default:
                      history.push('/myapp/interfacetest')
                  }
                } else {
                  history.push('/myapp/interfacetest')
                }
              }
              break
            case '智能营销':
              history.push('/myapp/enteringGinseng')
              break
            case '智能合同':
              history.push('/myapp/smartContract')
              break
            default:
              history.push('/')
          }

          window.location.reload()
        }
      })
    }
    return

    if (this.state.model_capability === 'AIGC') {
      if (name === '') {
        Notification.warning({ content: '应用名称不能为空!' })
        return
      } else if (desc === '') {
        Notification.warning({ content: '应用简介内容不能为空!' })
        return
      }
      let Code = ''
      this.setState({
        setingOk: true,
        Code: Code,
        progress: 20
      })
      let record = {
        id: this.state.app_id,
        model_id: this.state.model_id,
        model_capability: this.state.model_capability
      }
      let page = {
        // id: this.state.app_id,
        name: this.state.name,
        desc: this.state.desc,
        // model_id: this.state.model_capability,
        model_capability: this.state.model_capability
      }
      postapp(page).then((res) => {
        if (res.code === 200) {
          this.state.reciprocalTime = setInterval(() => {
            let reciprocal = this.state.reciprocal - 1
            if (reciprocal === 0) {
              clearInterval(this.state.reciprocalTime)
              history.push('/myapp/aigcExperience')
              localStorage.setItem('record', JSON.stringify(record))
              window.location.reload()
            }
            this.setState({
              progress:
                this.state.progress + 30 >= 100 ? 100 : this.state.progress + 30
            })
            this.setState({
              reciprocal: reciprocal
            })
          }, 1000)
        } else {
          Code = ''
          Notification.warning({ content: res.msg })
          history.push('/myapp/addmyapp')
          window.location.reload()
        }
      })

      return
    }
    if (name === '') {
      Notification.warning({ content: '应用名称不能为空!' })
    } else if (desc === '') {
      Notification.warning({ content: '应用简介内容不能为空!' })
    } else if (
      current === 2 &&
      checkedtype !== '创建新模型' &&
      checkedtype === ''
    ) {
      Notification.warning({ content: '未选择模型!' })
    } else if (
      current === 3 &&
      checkedtype === '创建新模型' &&
      Modelname === ''
    ) {
      Notification.warning({ content: '模型名称不能为空!' })
    }
    // else if (current === 3 && checkedtype === '新建模型' && Modeldesc === '') {
    //   Notification.warning({ content: '模型简描述不能为空!' })
    // }
    else if (
      current === 4 &&
      checkedtypeData === '创建数据集' &&
      namedata === ''
    ) {
      Notification.warning({ content: '数据集名称不能为空!' })
    } else if (
      current === 4 &&
      checkedtypeData === '创建数据集' &&
      (attachment === '' || attachment.length === 0)
    ) {
      Notification.warning({ content: '数据集不能为空!' })
    } else if (
      current === 4 &&
      checkedtypeData !== '创建数据集' &&
      checkedtypeData === ''
    ) {
      Notification.warning({ content: '未选择数据集!' })
    } else if (PatternList && current === 4 && checkedPattern === '') {
      Notification.warning({ content: '未选择模型训练模式 !' })
    } else {
      // if (current > 1) {
      //   this.setState({
      //     current: current + 1
      //   })
      //   this.state.current = current + 1
      // }
      this.setState({
        loading: true
      })
      if (this.state.current === 1) {
        this.postcheckName('app', name)
      } else if (this.state.current === 2) {
        this.postcheckName('app', name)
      } else if (this.state.current === 3) {
        this.postcheckName('model', Modelname)
      } else if (this.state.current === 4) {
        if (checkedtypeData === '创建数据集') {
          this.postdatum()
        } else {
          this.state.data_id = checkedtypeData
          this.setState({
            data_id: checkedtypeData
          })
          this.postmodel()
        }
      }
      localStorage.setItem('props', JSON.stringify(this.state))
    }
    //console.log(this.state, "Next");
  }
  onChangePrev() {
    const { name, desc, current } = this.state
    if (current === 1) {
      document.getElementById('addapp').click()
    } else {
      this.setState({
        current: current - 1
      })
      this.state.current = current - 1
    }

    localStorage.setItem('props', JSON.stringify(this.state))
  }
  onColseadd() {
    clearInterval(this.state.reciprocalTime)
    history.replace('/myapp')
    localStorage.removeItem('props')
    // history.go()
    window.location.reload()
  }
  OpenApp() {
    // history.push('/myapp')
    // localStorage.removeItem('props')
    // window.location.reload();
    clearInterval(this.state.reciprocalTime)
    const { cardstepitem, model_capability, btnOktitle } = this.state
    let record = {
      id: this.state.app_id,
      model_id: this.state.model_id,
      model_capability: model_capability
    }
    if (btnOktitle === '更多应用') {
      history.replace('/myapp')
    } else {
      localStorage.setItem('record', JSON.stringify(record))
      localStorage.setItem('props', JSON.stringify(this.state))
      if (cardstepitem.type === 3 && model_capability === 'ResumeEditor') {
        history.replace('/myapp/smarteditor')
      } else {
        if (model_capability === 'Interaction') {
          history.replace('/myapp/question')
        } else if (model_capability === 'Continuation') {
          history.replace('/myapp/productExperience')
        } else if (model_capability === 'SemanticPrototype') {
          history.replace('/myapp/semanticprototype')
        } else if (model_capability === 'Ner') {
          history.replace('/myapp/entityExtraction')
        } else if (model_capability === 'SmartResume') {
          history.replace('/myapp/resumeDocument')
        } else if (model_capability === 'textPolish') {
          history.replace('/myapp/embroidery')
        } else if (
          model_capability === 'writing' ||
          model_capability === 'textsummarization'
        ) {
          let moduleIdType = JSON.parse(localStorage.getItem('moduleIdType'))
          let arr = moduleIdType['writing']
          if (arr.indexOf(record.model_id) !== -1) {
            history.push('/myapp/writingcurrency')
          } else {
            history.replace('/myapp/writing')
          }
        } else if (model_capability === 'SemanticWordCloud') {
          history.replace('/myapp/wordCloud')
        } else if (model_capability === 'TextWithPictures') {
          history.replace('/myapp/image2text')
        } else if (model_capability === 'KnowledgeJd') {
          history.replace('/myapp/knowledge')
        } else if (model_capability === 'Classification') {
          history.replace('/myapp/wordClass')
        } else if (model_capability === 'Correction') {
          history.replace('/myapp/wordErrorReset')
        } else if (model_capability === 'SmartContract') {
          history.replace('/myapp/smartContract')
        } else if (model_capability === 'SentimentAnalysis') {
          history.replace('/myapp/emotionClass')
        } else if (model_capability === 'SensitiveWords') {
          history.replace('/myapp/sensitiveWords')
        } else if (model_capability === 'Similarity') {
          history.replace('/myapp/similarity')
        } else if (model_capability === 'KnowledgeReasoning') {
          history.replace('/myapp/knowledgereasoning')
        } else if (model_capability === 'KnowledgeReasoning') {
          history.replace('/myapp/knowledgereasoning')
        } else if (model_capability === 'CopydoneArticle') {
          history.replace('/myapp/CopydoneArticle')
        }
      }
    }
    window.location.reload()
  }
  // 重新训练
  onRetraining() {
    const { checkedtype, checkedtypeData } = this.state
    let params = {
      id: this.state.version_id
    }
    if (this.state.data_id) {
      params.data_id = this.state.data_id
    }
    postmodelVersionTrain(params).then((res) => {
      if (res.code === 409 || res.code === 200) {
        if (this.state.version_id) {
          this.getmodelTrainProcess(this.state.version_id)
          this.setintervalData()
        }
      } else {
      }
    })
  }
  goLink() {
    let record = {
      model_capability: this.state.model_capability,
      model_id: this.state.model_id,
      model_name: this.state.model_name
    }
    history.push('/enginefactory/model/management')
    localStorage.setItem('record', JSON.stringify(record))
    window.location.reload()
  }
  setintervalData() {
    clearInterval(this.state.listTimer)
    this.gofetchData(1)
    this.state.listTimer = setInterval(() => {
      this.gofetchData(1)
    }, 1000)
  }
  gofetchData(currentPage) {
    if (
      this.state.mockData.length === this.state.paginationVersion.total &&
      currentPage !== 1
    ) {
      this.setState({
        scrollLoading: '没有更多'
      })
    } else {
      this.getmodelListVersion(currentPage)
    }
  }
  getmodelListVersion(currentPage) {
    const { mockData, paginationVersion } = this.state
    let page = {
      per_page: this.state.paginationVersion.pageSize,
      page: currentPage,
      train_status: '["训练中","待训练"]',
      sort: 'desc'
      // filter_id: this.state.version_id
    }
    if (this.state.version_id) {
      sourceCancelClass()
      getmodelListVersion(page).then((res) => {
        if (res.code === 200) {
          let list = res.data
          let pagination = this.state.paginationVersion
          pagination.total = list.count
          let newdata = list.data
          // var b = [this.state.version_id];
          // var newdata = list.data.filter(item => { return !b.includes(item.id); });
          if (newdata.length > 0) {
            this.setState({
              paginationVersion: pagination,
              mockData: currentPage === 1 ? newdata : mockData.concat(newdata),
              isToBeTrained: true,
              scrollLoading: false
            })
          }
          if (newdata.length === 0 && currentPage === 1) {
            this.setState({
              isToBeTrained: false
            })
            clearInterval(this.state.listTimer)
          }
          //console.log(list, "list");
        } else {
          this.setState({
            isToBeTrained: false
          })
          clearInterval(this.state.listTimer)
        }
      })
    }
  }
  openTraining(record) {
    clearInterval(this.state.setIntervalTimer)
    clearInterval(this.state.listTimer)
    record.train_details = '详情'
    this.setState({
      version_id: ''
    })
    this.state.version_id = ''
    localStorage.setItem('record', JSON.stringify(record))
    localStorage.setItem('props', JSON.stringify(this.state))
    history.push('/myapp/addmyapp')
    window.location.reload()
    //console.log(record, "record");
  }
  deleteModelVersion(e, id) {
    e.stopPropagation()
    deleteModelVersion({ ids: JSON.stringify([id]) }).then((res) => {
      if (res.code === 200) {
        clearInterval(this.state.listTimer)
        this.setintervalData()
        Notification.success({ content: '删除成功!' })
      } else {
        Notification.error({ content: '删除失败!' })
      }
    })
  }
  render() {
    const {
      loading,
      mockData,
      scrollLoading,
      isToBeTrained,
      reciprocal,
      texSetList,
      subTitle,
      btnOktitle,
      record,
      ModelAddList,
      SaaSList,
      SaaSType,
      DataAddList,
      title,
      current,
      name,
      desc,
      Modeldesc,
      Modelname,
      cardstepitem,
      ModelSelsectList,
      DataSelsectList,
      checkedtype,
      namedata,
      checkedtypeData,
      setingOk,
      fileList,
      Code,
      progress,
      isRetraining,
      CustomModelList,
      model_capability,
      combo,
      newappList,
      choseType
    } = this.state

    return (
      <div className="addmyapp">
        <div className="Header-init" id="Headertitle">
          <div className="Header-title">
            {setingOk ? (name ? name : title) : '创建应用'}
          </div>
        </div>
        {setingOk ? (
          <TrainingStatus
            mockDataloding={this.state.version_id}
            isRetraining={isRetraining}
            mockData={mockData}
            scrollLoading={scrollLoading}
            isToBeTrained={isToBeTrained}
            reciprocal={reciprocal}
            btnOktitle={btnOktitle}
            subTitle={subTitle}
            Code={Code}
            title={title}
            progress={progress}
            TextSet={texSetList}
            deleteModelVersion={(e, id) => this.deleteModelVersion(e, id)}
            openTraining={(item) => this.openTraining(item)}
            fetchData={(currentPage) => this.gofetchData(currentPage)}
            goLink={this.goLink.bind(this)}
            onRetraining={this.onRetraining.bind(this)}
            onOpenApp={this.OpenApp.bind(this)}
            onColseadd={this.onColseadd.bind(this)}
          ></TrainingStatus>
        ) : (
          <div className="addmyapp-box" id="layoutbody">
            <div className="cardform">
              <Typography.Paragraph
                className="startParagraph"
                id="startParagraph"
              >
                {current === 1
                  ? '创建场景应用'
                  : current === 2
                  ? '绑定功能'
                  : current === 3 && checkedtype === '创建新模型'
                  ? '模型配置'
                  : current === 4 && checkedtype === '创建新模型'
                  ? '语料配置'
                  : ''}
              </Typography.Paragraph>
              <div
                className="forminput"
                id="forminput"
                ref={(ref) => (this.myRef = ref)}
                // onScrollCapture={() => this.handleOnScroll()}
              >
                {current === 1 ? (
                  <Space direction="vertical">
                    <Space
                      direction="vertical"
                      className="custom-Required"
                      style={{ marginBottom: 34 }}
                    >
                      <div className="formtitle">应用名称</div>
                      <Input
                        className="forminput-item inputtext"
                        style={{ width: 498, height: 48 }}
                        allowClear
                        value={name}
                        placeholder="请输入应用名称（限20字符）"
                        onChange={this.onChangeIput.bind(this)}
                        maxLength={20}
                      />
                    </Space>
                    <Space
                      direction="vertical"
                      className="custom-Required"
                      style={{ marginBottom: 34 }}
                    >
                      <div className="formtitle">应用简介</div>
                      <TextArea
                        className="forminput-item"
                        placeholder="请简单的介绍一下你的应用（限32字符）"
                        style={{ width: 498, height: 138, resize: 'none' }}
                        value={desc}
                        onChange={this.onChangeTextArea.bind(this)}
                        maxLength={32}
                      />
                    </Space>
                    {/* Sass选择框 */}
                    {/* { model_capability === 'saas_ppt' ? (
                      <Space direction="vertical" className="custom-Required">
                        <div className="formtitle">
                          套餐选择 
                        <span style={{
                        fontSize: '22px',
                        paddingLeft: '15px',
                        }}
                        onClick={()=>
                          this.setState({
                            combo: true
                          })
                        }
                      >
                          <IconQuestionCircleFill style={{verticalAlign:-4,cursor:'pointer'}}/>
                      </span>
                      </div>
                        <div className="Model-selsect-fix">
                          <Radio.Group
                            onChange={this.onSelsect.bind(this)}
                            value={SaaSType}
                            className="custom-Group"
                          >
                            {SaaSList.map((item, i) => {
                              return (
                                <Radio key={item} value={item}  onClick={()=>{
                                  this.setState({
                                  SaaSType: item
                                })
                                }}>
                                  <Space
                                    align="start"
                                    className={`${
                                      SaaSType === item
                                        ? "custom-radio-card-checked"
                                        : ""
                                    }`}
                                    style={{
                                      backgroundColor:"#ffffff"
                                    }}
                                  >
                                    <div className="custom-radio-card-mask">
                                      <div className="custom-radio-card-mask-dot"></div>
                                    </div>
                                    <div>
                                      <Typography.Title
                                        heading={6}
                                        className="custom-radio-card-title"
                                        style={{width:'auto'}}
                                      >
                                        {item}
                                      </Typography.Title>
                                    </div>
                                  </Space>
                                </Radio>
                              );
                            })}
                          </Radio.Group>
                        </div>
                    </Space>
                    ) : ''} */}
                  </Space>
                ) : current === 2 ? (
                  <Space
                    direction="vertical"
                    style={{ width: '100%', textAlign: 'left' }}
                  >
                    {/* {cardstepitem.createType === 1 ? (
                      <>
                        <div className="selsect-fix-label">新模型</div>
                        <div className="Model-selsect-fix">
                          <Radio.Group
                            onChange={this.onSelsect.bind(this)}
                            value={checkedtype}
                            className="custom-Group"
                          >
                            {ModelAddList.map((item, i) => {
                              return (
                                <Radio key={item} value={item}>
                                  <Space
                                    align="start"
                                    className={`custom-radio-card ${
                                      checkedtype === item
                                        ? "custom-radio-card-checked"
                                        : ""
                                    }`}
                                  >
                                    <div className="custom-radio-card-mask">
                                      <div className="custom-radio-card-mask-dot"></div>
                                    </div>
                                    <div>
                                      <Typography.Title
                                        heading={6}
                                        className="custom-radio-card-title"
                                      >
                                        {item}
                                      </Typography.Title>
                                    </div>
                                  </Space>
                                </Radio>
                              );
                            })}
                          </Radio.Group>
                        </div>
                      </>
                    ) : null} */}
                    {/* {CustomModelList && CustomModelList.length > 0 ? (
                      <>
                        <div className="selsect-fix-label selsect-fix-label-marginTop">
                          定制模型
                        </div>
                        <div className="Model-selsect-fix listloadMore">
                          {
                            <Radio.Group
                              onChange={this.onSelsect.bind(this)}
                              className="custom-Group pageGroup2"
                              value={checkedtype}
                            >
                              {CustomModelList.length > 0 &&
                                CustomModelList.map((item, i) => {
                                  return (
                                    <Radio key={item.id} value={item.id}>
                                      <Popover
                                        position="bl"
                                        content={item.name}
                                        disabled={
                                          !item.name || item.name.length < 6
                                        }
                                        className="select-Popover"
                                      >
                                        <Space
                                          align="start"
                                          className={`custom-radio-card ${
                                            checkedtype === item.id
                                              ? "custom-radio-card-checked"
                                              : ""
                                          }`}
                                        >
                                          <div className="custom-radio-card-mask">
                                            <div className="custom-radio-card-mask-dot"></div>
                                          </div>
                                          <div>
                                            <div
                                              heading={6}
                                              className="custom-radio-card-title"
                                            >
                                              {item.name}
                                            </div>
                                          </div>
                                        </Space>
                                      </Popover>
                                    </Radio>
                                  );
                                })}
                            </Radio.Group>
                          }
                          {pageCount(
                            this.state.paginationCustom.total,
                            this.state.paginationCustom.pageSize
                          ) > 1 && this.state.paginationCustom.current !== 1 ? (
                            <div
                              className="add-IconLeft"
                              onClick={this.setmoreSelect.bind(
                                this,
                                "modelCustom",
                                "left"
                              )}
                            >
                              <IconLeft />
                            </div>
                          ) : (
                            ""
                          )}
                          {pageCount(
                            this.state.paginationCustom.total,
                            this.state.paginationCustom.pageSize
                          ) === this.state.paginationCustom.current ? (
                            ""
                          ) : (
                            <div
                              className="add-IconRight"
                              onClick={this.setmoreSelect.bind(
                                this,
                                "modelCustom",
                                "right"
                              )}
                            >
                              <IconRight />
                            </div>
                          )}
                        </div>
                      </>
                    ) : null} */}
                    {/* {ModelSelsectList && ModelSelsectList.length > 0 ? ( */}
                    <>
                      {/* <div className="selsect-fix-label selsect-fix-label-marginTop" style={{fontSize:"20px",fontWeight:"600",color:"#1D2129"}}>
                          官方方案
                          <span className="selsect-fix-tag AlibabaPuHuiTi-Medium">
                            官方
                          </span>
                        </div> */}
                      <div
                        className="Model-selsect-fix listloadMore chose-model-list"
                        style={{ marginTop: '20px' }}
                      >
                        {
                          <Checkbox.Group
                            onChange={this.onSelsect.bind(this)}
                            className="custom-Group pageGroup"
                            value={checkedtype}
                          >
                            {choseType &&
                              newappList &&
                              newappList.length > 0 &&
                              newappList.map((item, i) => {
                                return (
                                  <div className="custom-radio-card-box">
                                    <div className="custom-radio-card-titles">
                                      {item.name}
                                    </div>
                                    {item.children &&
                                      item.children.map((ite, index) => {
                                        return (
                                          <Popover
                                            position="bl"
                                            content={
                                              ite.isDiable
                                                ? '开通企业账户，即可体验'
                                                : ite.name
                                            }
                                            disabled={
                                              ite.isDiable
                                                ? false
                                                : !ite.name ||
                                                  ite.name.length < 6
                                                ? true
                                                : false
                                            }
                                            className="select-Popover"
                                          >
                                            <Checkbox
                                              key={ite.id}
                                              value={ite.id}
                                              disabled={ite.isDiable}
                                            >
                                              <Space
                                                align="start"
                                                className={`custom-radio-card ${
                                                  checkedtype.includes(ite.id)
                                                    ? 'custom-radio-card-checked'
                                                    : ''
                                                } ${
                                                  ite.isDiable
                                                    ? 'custom-radio-card-firm'
                                                    : ''
                                                }`}
                                              >
                                                <div className="custom-radio-card-mask">
                                                  <div className="custom-radio-card-mask-dot"></div>
                                                </div>
                                                <div>
                                                  <Typography.Title
                                                    heading={6}
                                                    className="custom-radio-card-title"
                                                  >
                                                    {ite.name}
                                                  </Typography.Title>
                                                </div>
                                              </Space>
                                            </Checkbox>
                                          </Popover>
                                        )
                                      })}
                                  </div>
                                )
                              })}
                          </Checkbox.Group>
                        }
                        {/* {pageCount(
                            this.state.pagination.total,
                            this.state.pagination.pageSize
                          ) > 1 && this.state.pagination.current !== 1 ? (
                            <div
                              className="add-IconLeft"
                              onClick={this.setmoreSelect.bind(
                                this,
                                "model",
                                "left"
                              )}
                            >
                              <IconLeft />
                            </div>
                          ) : (
                            ""
                          )}
                          {pageCount(
                            this.state.pagination.total,
                            this.state.pagination.pageSize
                          ) === this.state.pagination.current ? (
                            ""
                          ) : (
                            <div
                              className="add-IconRight"
                              onClick={this.setmoreSelect.bind(
                                this,
                                "model",
                                "right"
                              )}
                            >
                              <IconRight />
                            </div>
                          )} */}
                      </div>
                    </>
                    {/* // ) : null} */}
                  </Space>
                ) : current === 3 ? (
                  <Space direction="vertical" style={{ margin: 'auto' }}>
                    <Space
                      direction="vertical"
                      className="custom-Required"
                      style={{ marginBottom: 34 }}
                    >
                      <div className="formtitle">模型名称</div>
                      <Input
                        className="forminput-item inputtext"
                        style={{ width: 498, height: 48 }}
                        allowClear
                        value={Modelname}
                        placeholder="请输入模型名称（限20字符）"
                        onChange={this.onChangeIput.bind(this)}
                        maxLength={20}
                      />
                    </Space>
                    <Space direction="vertical">
                      <div className="formtitle">模型简介</div>
                      <TextArea
                        className="forminput-item"
                        placeholder="请简单的介绍一下模型（限100字符）"
                        style={{ width: 498, height: 138, resize: 'none' }}
                        value={Modeldesc}
                        onChange={this.onChangeTextArea.bind(this)}
                        maxLength={100}
                      />
                    </Space>
                  </Space>
                ) : current === 4 ? (
                  <Space
                    direction="vertical"
                    style={{ width: '100%', textAlign: 'left' }}
                  >
                    <div className="selsect-fix-label">新数据集</div>
                    <div className="Model-selsect-fix">
                      <Radio.Group
                        onChange={this.onSelsectData.bind(this)}
                        value={checkedtypeData}
                        className="custom-Group"
                      >
                        {DataAddList.map((item, i) => {
                          return (
                            <Radio key={item} value={item}>
                              <Space
                                align="start"
                                className={`custom-radio-card ${
                                  checkedtypeData === item
                                    ? 'custom-radio-card-checked'
                                    : ''
                                }`}
                              >
                                <div className="custom-radio-card-mask">
                                  <div className="custom-radio-card-mask-dot"></div>
                                </div>
                                <div>
                                  <Typography.Title
                                    heading={6}
                                    className="custom-radio-card-title"
                                  >
                                    {item}
                                  </Typography.Title>
                                </div>
                              </Space>
                            </Radio>
                          )
                        })}
                      </Radio.Group>
                    </div>
                    {checkedtypeData === '创建数据集' ? (
                      <div className="addData">
                        <div>
                          <Space
                            direction="vertical"
                            style={{ marginBottom: 16 }}
                          >
                            {/* <div className="formtitle">导入数据</div> */}
                            <Upload
                              multiple
                              showUploadList={false}
                              limit={1}
                              fileList={fileList}
                              accept=".csv"
                              onChange={this.setFile.bind(this)}
                              customRequest={(option) => {
                                this.handleUpload(option)
                              }}
                              beforeUpload={this.beforeUpload.bind(this)}
                              onExceedLimit={() => {
                                Notification.warning({
                                  content: '超过上传数量限制！最多上传1个'
                                })
                              }}
                            >
                              <Space
                                size="medium"
                                style={{ color: '#406EFF', fontSize: 16 }}
                                className="custom-upload-trigger-link"
                              >
                                点击上传数据集文件
                                <IconUpload
                                  style={{ color: '#4E5969', fontSize: 16 }}
                                />
                              </Space>
                            </Upload>
                            {fileList ? (
                              <Space
                                align="center"
                                style={{ fontSize: 16 }}
                                className="upload-Link"
                              >
                                {fileList.map((item, index) => {
                                  return (
                                    <Link
                                      href={item.url}
                                      icon={
                                        item.status === 'error' ? (
                                          <IconExclamationCircleFill
                                            style={{
                                              color: '#ff7d00',
                                              fontSize: 18
                                            }}
                                          />
                                        ) : item.status === 'done' ? (
                                          <IconCheckCircleFill
                                            style={{
                                              color: '#00B42A',
                                              fontSize: 18
                                            }}
                                          />
                                        ) : (
                                          <IconLoading
                                            style={{
                                              color: '#406EFF',
                                              fontSize: 18
                                            }}
                                          />
                                        )
                                      }
                                    >
                                      {item.name}
                                    </Link>
                                  )
                                })}
                                <IconDelete
                                  style={{
                                    color: '#4E5969',
                                    cursor: 'pointer'
                                  }}
                                  onClick={this.onIconDelete.bind(this)}
                                />
                              </Space>
                            ) : null}
                            <Input
                              className="forminput-item inputtext"
                              style={{ width: 464, height: 48 }}
                              allowClear
                              value={namedata}
                              placeholder="请输入数据集名称（限20字符）"
                              onChange={this.onChangeIput.bind(this)}
                              maxLength={20}
                            />
                          </Space>
                        </div>

                        <div className="TipsData">
                          <Space align="start">
                            <IconStarFill style={{ color: '#FFD726' }} />
                            <Typography>
                              为了确保引擎的最终效果，请按以下模板提供标注数据
                            </Typography>
                          </Space>
                          <div className="TipsData-link">
                            {cardstepitem.file.map((item, index) => {
                              return (
                                <>
                                  {index > 0 ? (
                                    <Link
                                      key={index}
                                      href={item.url}
                                      target="_blank"
                                    >
                                      {' '}
                                      或 {item.name}.{item.type}
                                    </Link>
                                  ) : (
                                    <Link
                                      key={index}
                                      href={item.url}
                                      target="_blank"
                                    >
                                      {' '}
                                      附：{item.name}.{item.type}
                                    </Link>
                                  )}
                                </>
                              )
                            })}
                            {cardstepitem.fie_img ? (
                              <div className="fie_img">
                                <img src={cardstepitem.fie_img} />
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {DataSelsectList && DataSelsectList.length > 0 ? (
                      <>
                        <div style={{ marginTop: 32 }}>可用数据集</div>
                        <div className="Model-selsect-fix listloadMore">
                          <Radio.Group
                            onChange={this.onSelsectData.bind(this)}
                            className="custom-Group pageGroup"
                            value={checkedtypeData}
                          >
                            {DataSelsectList.length > 0 &&
                              DataSelsectList.map((item, i) => {
                                return (
                                  <Radio key={item.id} value={item.id}>
                                    <Popover
                                      position="bl"
                                      content={item.name}
                                      disabled={
                                        !item.name || item.name.length < 6
                                      }
                                      className="select-Popover"
                                    >
                                      <Space
                                        align="start"
                                        className={`custom-radio-card ${
                                          checkedtypeData === item.id
                                            ? 'custom-radio-card-checked'
                                            : ''
                                        }`}
                                      >
                                        <div className="custom-radio-card-mask">
                                          <div className="custom-radio-card-mask-dot"></div>
                                        </div>
                                        <div>
                                          <Typography.Title
                                            heading={6}
                                            className="custom-radio-card-title"
                                          >
                                            {item.name}
                                          </Typography.Title>
                                        </div>
                                      </Space>
                                    </Popover>
                                  </Radio>
                                )
                              })}
                          </Radio.Group>
                          {pageCount(
                            this.state.paginationdata.total,
                            this.state.paginationdata.pageSize
                          ) > 1 && this.state.paginationdata.current !== 1 ? (
                            <div
                              className="add-IconLeft"
                              onClick={this.setmoreSelect.bind(
                                this,
                                'data',
                                'left'
                              )}
                            >
                              <IconLeft />
                            </div>
                          ) : (
                            ''
                          )}
                          {pageCount(
                            this.state.paginationdata.total,
                            this.state.paginationdata.pageSize
                          ) === this.state.paginationdata.current ? (
                            ''
                          ) : (
                            <div
                              className="add-IconRight"
                              onClick={this.setmoreSelect.bind(
                                this,
                                'data',
                                'right'
                              )}
                            >
                              <IconRight />
                            </div>
                          )}
                        </div>
                      </>
                    ) : null}
                  </Space>
                ) : (
                  ''
                )}
              </div>
              <div className="select-foot" id="selectFoot">
                <Divider />
                <div className="select-foot-btns">
                  <div>
                    {/* {current > 1 ? (
                      <Button
                        icon={loading ? "" : <IconArrowLeft />}
                        loading={loading}
                        className="Prev custom-step-button"
                        onClick={this.onChangePrev.bind(this)}
                      >
                        上一步
                      </Button>
                    ) : (
                      ""
                    )} */}
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <Space size={'medium'}>
                      {this.state.PatternList &&
                      this.state.PatternList.length > 0 &&
                      current === 4 ? (
                        <Select
                          placeholder="选择训练模式"
                          onChange={this.onSelsectPattern.bind(this)}
                        >
                          {this.state.PatternList.map((option, index) => (
                            <Option key={option.key} value={option.key}>
                              {option.lable}
                            </Option>
                          ))}
                        </Select>
                      ) : null}
                      {
                        <Button
                          type="primary"
                          className="Next"
                          loading={loading}
                          onClick={this.onChangeNext.bind(this)}
                        >
                          {current == 1 && model_capability !== 'saas_ppt'
                            ? '下一步'
                            : '确认'}
                          {/* {loading ? "" : <IconArrowRight />} */}
                        </Button>
                      }
                    </Space>
                  </div>
                </div>
              </div>
            </div>
            <Card
              bordered={false}
              style={{ width: 370, borderRadius: 22, background: '#001a61' }}
              className="cardstep"
            >
              <Space size={'medium'} className="Space-large">
                {cardstepitem ? (
                  <img
                    src={cardstepitem.model_icon ? cardstepitem.model_icon : ''}
                    style={{
                      width: 76,
                      backgroundColor: '#fff',
                      borderRadius: 14
                    }}
                  />
                ) : (
                  ''
                )}

                <div className="Typography-Titles">
                  <Typography.Title>
                    {cardstepitem ? cardstepitem.label : ''}
                  </Typography.Title>
                  <Typography.Title heading={5}>
                    {cardstepitem ? cardstepitem.title : ''}
                  </Typography.Title>
                </div>
              </Space>
              <Typography.Paragraph
                style={{
                  marginBottom: 30,
                  marginTop: 30,
                  textAlign: 'justify'
                }}
              >
                {cardstepitem ? cardstepitem.desc : ''}
              </Typography.Paragraph>
              {/* <div style={{ overflow: "hidden", textAlign: "center" }}>
                <Steps
                  direction="vertical"
                  current={current}
                  style={{ marginTop: 20 }}
                >
                  <Step icon={<IconCheck />} title="创建应用" />
                  <Step
                    title="选择模型"
                    className={checkedtype === "创建新模型" ? "Step-model" : ""}
                  />
                  {checkedtype === "创建新模型" ? (
                    <Step
                      title="模型配置"
                      className="Step-dispose"
                      style={{ marginLeft: 37 }}
                    />
                  ) : null}
                  {checkedtype === "创建新模型" ? (
                    <Step
                      title="语料配置"
                      className="Step-corpus"
                      style={{ marginLeft: 37 }}
                    />
                  ) : null}
                  {checkedtype === "创建新模型" ? (
                    <Step
                      title="模型训练"
                      className="Step-training"
                      style={{ marginLeft: 37 }}
                    />
                  ) : null}
                  <Step title="开启应用" />
                </Steps>
              </div> */}
              {cardstepitem ? (
                <div
                  className={`Health_coverages ${
                    cardstepitem.type === 'ai_ppt' ||
                    cardstepitem.type === 'saas_ppt'
                      ? 'ai_ppt_buttom'
                      : cardstepitem.type === 'ai_resume'
                      ? 'ai_resume_buttom'
                      : cardstepitem.type === 'ai_marketing'
                      ? 'ai_marketing_buttom'
                      : cardstepitem.type === 'ai_pact'
                      ? 'ai_pact_buttom'
                      : ''
                  }`}
                ></div>
              ) : null}
            </Card>
          </div>
        )}
        <Modal
          visible={combo}
          title={<div style={{ textAlign: 'left' }}>套餐说明</div>}
          footer={null}
          className="Contactmodal payment-modal combo-modal"
          maskStyle={{ background: 'rgba(33,85,163,0.16)' }}
          onCancel={() => {
            this.setState({
              combo: false
            })
          }}
        >
          <ComboModal />
        </Modal>
      </div>
    )
  }
}
export default Addmyapp
